import { CreateSpaceButton } from './components/CreateSpaceButton'
import { EditSpaceButton } from './components/EditSpaceButton'
import { SharingPermissions } from './SharingPermissions'
import MembersManagement from './MembersManagement'
import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { ModalInputText } from '../../inputComponents/ModalInputText'
import { useValidation } from '../../../../hooks/useValidation'
import {
  ValidationRules,
  isNotEmpty,
  isRequired,
  isValidMaximumNumberOfChars,
  isValidRequiredNumberOfChars
} from '../../../../helpers/validationHelpers'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import {
  DEFAULT_SHARING_CONTROL_PERMISSION,
  SharingControl,
  Space
} from '../../../../store/features/apis/slices/space/interfaces'
import { useAttributes } from '../../../../hooks/newWorld'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { Box, Stack } from '@mui/material'
import { ManageOrganizationPermissions } from '@/components/modal/templates/teamSpace/organizationPermission/MangeOrganizationPermissions'
import { PermissionType } from '@/components/modal/templates/share/interfaces'

interface TeamSpaceModalProps extends ModalTemplateProps {
  title: string
  selectedTeamSpace?: Space
}

export const TeamSpaceModal = ({
  title,
  onClose,
  showCloseButton,
  selectedTeamSpace
}: TeamSpaceModalProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const { validateField } = useValidation()
  const { data: attributes } = useAttributes()
  const isProfessionalOrganization = attributes?.isProfessional ?? false
  const initialSharingPermission = isProfessionalOrganization
    ? DEFAULT_SHARING_CONTROL_PERMISSION
    : SharingControl.WITH_ANYONE

  const [sharingPermission, setSharingPermission] = React.useState<SharingControl>(
    selectedTeamSpace?.spaceSettings?.sharingControl ?? initialSharingPermission
  )
  const [organizationSharingPermission, setOrganizationSharingPermission] = React.useState<PermissionType>(
    PermissionType.REMOVE
  )
  const [spaceName, setSpaceName] = React.useState<string>(selectedTeamSpace?.name ?? '')

  const [hasValidationErrors, setHasValidationErrors] = React.useState(false)
  const [membersSectionHasValidationErrors, setMembersSectionHasValidationErrors] = React.useState(false)

  const validationRules: ValidationRules = {
    spaceName: [isRequired, isNotEmpty, isValidRequiredNumberOfChars(1), isValidMaximumNumberOfChars(255)]
  }

  const { validationErrors, setValidationErrors } = useErrorHandler({
    spaceName: { valid: true, message: '' }
  })

  React.useEffect(() => {
    if (isEmpty(spaceName)) {
      setHasValidationErrors(true)
    } else {
      setHasValidationErrors(Object.values(validationErrors).some(({ valid }) => !valid))
    }
  }, [validationErrors, spaceName])

  const handleValidation = React.useCallback(
    (value: string) => {
      const validation = validateField(value, validationRules.spaceName)
      if (!isNil(validation)) {
        setValidationErrors({ ...validationErrors, spaceName: validation })
      }
    },
    [validationRules]
  )

  const handleSharingPermissionChange = React.useCallback((value: SharingControl) => {
    setSharingPermission(value)
  }, [])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent>
        <Stack direction="column" gap={4}>
          <Box>
            <ModalInputText
              helperText={validationErrors['spaceName'].message}
              error={!validationErrors['spaceName'].valid}
              onChange={(event): void => setSpaceName(event.target.value)}
              placeholder={t('teamSpace.enterTeamName')}
              label={t('teamSpace.spaceName')}
              value={spaceName}
              autoFocus={true}
              required={true}
              onBlur={(): void => handleValidation(spaceName)}
            />
            <SharingPermissions
              sharingPermission={sharingPermission}
              handleSharingPermissionChange={handleSharingPermissionChange}
              isProfessionalOrganization={isProfessionalOrganization}
            />
          </Box>
          <MembersManagement
            selectedTeamSpace={selectedTeamSpace}
            setMembersSectionHasValidationErrors={setMembersSectionHasValidationErrors}
          />
          <ManageOrganizationPermissions
            selectedTeamSpaceId={selectedTeamSpace?.spaceId}
            organizationSharingPermission={organizationSharingPermission}
            setOrganizationSharingPermission={setOrganizationSharingPermission}
            isProfessionalOrganization={isProfessionalOrganization}
          />
        </Stack>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('cancel')}
        </Button>
        {!isNil(selectedTeamSpace) ? (
          <EditSpaceButton
            spaceName={spaceName}
            sharingPermission={sharingPermission}
            organizationSharingPermission={organizationSharingPermission}
            spaceId={selectedTeamSpace.spaceId}
            hasValidationErrors={hasValidationErrors || membersSectionHasValidationErrors}
            onClose={onClose}
          />
        ) : (
          <CreateSpaceButton
            spaceName={spaceName}
            sharingPermission={sharingPermission}
            organizationSharingPermission={organizationSharingPermission}
            hasValidationErrors={hasValidationErrors || membersSectionHasValidationErrors}
            onClose={onClose}
          />
        )}
      </ModalActions>
    </React.Fragment>
  )
}
