import { AnalysisTypePick } from './AnalysisTypePick'
import { ProjectsDisplay } from './ProjectsDisplay'
import { ModalActions, ModalContent, ModalTitle } from '../../../Modal'
import { Button } from '../../../../button/Button'
import { ModalTemplateProps } from '../../../interfaces'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { isEmpty } from 'lodash'
import { AiSectionPaths } from '@/constants'
import { useGetAnalysisTemplateMapQuery, usePatchModelMutation } from '@/store/features/apis/slices/ai/aiSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useAppSelector } from '@/store/hooks'
import { AnalysisType } from '@/constants/propForAnalysisType'

type SelectAiTrainingAnalysisTypeProps = ModalTemplateProps & {
  title: string
}

export type AnalysisTypeDisplayType = {
  type: AnalysisType
  numberOfSuccessfulRuns: number
}

export const SelectAiTrainingAnalysisType = ({
  title,
  onClose,
  showCloseButton
}: SelectAiTrainingAnalysisTypeProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const [_, setSearchParams] = useSearchParams()
  const { modelUuid } = useParams<{ modelUuid: string }>()
  const [allAvailableAnalysisTypes, setAllAvailableAnalysisTypes] = React.useState<AnalysisTypeDisplayType[]>([])
  const { handleApiHookErrors } = useErrorHandler()
  const [patchModel, { isLoading: isLoadingPatchModel }] = usePatchModelMutation()
  const pickedAnalysisType = useAppSelector((store) => store.ui.aiSelectedAnalysisType)

  const {
    data: analysisTemplateMap,
    isLoading: isLoadingAnalysisTemplateMap,
    isError: isErrorAnalysisTemplateMap,
    error: errorAnalysisTemplateMap
  } = useGetAnalysisTemplateMapQuery()

  React.useEffect(() => {
    if (isErrorAnalysisTemplateMap) {
      handleApiHookErrors(errorAnalysisTemplateMap)
    }
  }, [isErrorAnalysisTemplateMap, errorAnalysisTemplateMap])

  const handleOnClick = React.useCallback((): void => {
    patchModel({
      modelUuid,
      payload: { analysis_type: pickedAnalysisType, template_name: analysisTemplateMap[pickedAnalysisType] }
    })
      .unwrap()
      .then(() => {
        setSearchParams({ page: AiSectionPaths.dataSettings.value }, { replace: true })
        onClose()
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [modelUuid, pickedAnalysisType, analysisTemplateMap])

  const handleAnalysisSelection = (analysisTypes: AnalysisTypeDisplayType[]): void => {
    if (isEmpty(analysisTypes)) {
      return
    }
    setAllAvailableAnalysisTypes((prevValues) => {
      const updatedValues = [...prevValues]

      analysisTypes.forEach((newAnalysisType) => {
        const existingEntry = updatedValues.find((entry) => entry.type === newAnalysisType.type)

        if (existingEntry) {
          existingEntry.numberOfSuccessfulRuns += newAnalysisType.numberOfSuccessfulRuns
        } else {
          updatedValues.push({
            type: newAnalysisType.type,
            numberOfSuccessfulRuns: newAnalysisType.numberOfSuccessfulRuns
          })
        }
      })
      return updatedValues
    })
  }

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={handleOnClick}>
        <AnalysisTypePick analysisTypes={allAvailableAnalysisTypes} />
        <ProjectsDisplay onAnalysisSelect={handleAnalysisSelection} />
      </ModalContent>
      <ModalActions>
        <Button
          onClick={handleOnClick}
          color="primary"
          variant="contained"
          disabled={isEmpty(pickedAnalysisType) || isLoadingPatchModel}
          isLoading={isLoadingPatchModel || isLoadingAnalysisTemplateMap}
        >
          {t('createAiModel.confirm')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}
