import { PasswordField } from './fields/PasswordField'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import { styled } from '@mui/system'
import * as React from 'react'
import { Box, Switch, TextField, Typography } from '@mui/material'

export const SettingsRowStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
})

type SettingsRowProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string
  reverse?: boolean
  error?: boolean
  helperText?: string
  qaAttributePrefix?: string
  type?: 'text' | 'switch' | 'password'
}

export const SettingsRow = ({
  label,
  value,
  disabled,
  required,
  reverse,
  onChange,
  onBlur,
  error,
  helperText,
  qaAttributePrefix,
  type = 'text'
}: SettingsRowProps): React.ReactElement => {
  const qaAttributeRow = `${qaAttributePrefix}--row`

  return (
    <SettingsRowStyled flexDirection={reverse ? 'row-reverse' : 'row'} qa-attribute={qaAttributeRow}>
      <Box sx={{ width: '50%', flexGrow: 1, textAlign: reverse ? 'right' : 'left' }}>
        <Typography color={disabled ? 'GrayText' : 'text.primary'}>
          {label}
          {required ? '*' : ''}
        </Typography>
      </Box>
      <Box sx={{ width: '50%', flexGrow: 1 }}>
        <React.Fragment>
          {type === 'switch' && (
            <Switch
              size="small"
              disabled={disabled}
              checked={Boolean(value)}
              onChange={onChange}
              qa-attribute={`${qaAttributePrefix}--${getQaSelectorText(label)}--switch`}
            />
          )}
          {type === 'text' && (
            <TextField
              size="small"
              sx={{ width: '100%' }}
              disabled={disabled}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              qa-attribute={`${qaAttributePrefix}--${getQaSelectorText(label)}--input`}
              variant="standard"
              error={error}
              helperText={helperText}
            />
          )}
          {type === 'password' && (
            <PasswordField
              size="small"
              sx={{ width: '100%' }}
              disabled={disabled}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              qa-attribute={`${qaAttributePrefix}--${getQaSelectorText(label)}--input`}
              variant="standard"
              error={error}
              helperText={helperText}
            />
          )}
        </React.Fragment>
      </Box>
    </SettingsRowStyled>
  )
}
