import { SharingControl } from '../../../../../store/features/apis/slices/space/interfaces'
import { addSnackbarToState } from '../../../../../store/features/uiSlice/uiSlice'
import { SpacePermissions } from '../../share/interfaces'
import { getTeamSpacePermissions } from '../hooks'
import { useAppDispatch } from '../../../../../store/hooks'
import {
  useEditSpaceMutation,
  useSetSpaceUsersPermissionsMutation
} from '../../../../../store/features/apis/slices/space/spaceSlice'
import { Button } from '../../../../button/Button'
import { useModalEvents } from '../../../../../hooks/useModalEvents'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { PermissionType } from '@/components/modal/templates/share/interfaces'

type EditSpaceButtonType = {
  spaceId: string
  spaceName: string
  sharingPermission: SharingControl
  organizationSharingPermission: PermissionType
  hasValidationErrors: boolean
  onClose: () => void
}

export const EditSpaceButton = ({
  spaceId,
  spaceName,
  sharingPermission,
  organizationSharingPermission,
  hasValidationErrors,
  onClose
}: EditSpaceButtonType): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()

  const [editSpace, { isLoading }] = useEditSpaceMutation()
  const [setUsersPermission, { isLoading: isSetUsersPermissionLoading }] = useSetSpaceUsersPermissionsMutation()

  const teamSpaceUserPermissions = getTeamSpacePermissions(organizationSharingPermission)

  const handleEditTeamSpace = React.useCallback(
    async (
      spaceId: string,
      spaceName: string,
      sharingPermission: SharingControl,
      teamSpaceUserPermissions: SpacePermissions
    ) => {
      const payload = {
        name: spaceName,
        spaceSettings: {
          sharingControl: sharingPermission
        }
      }

      try {
        const updatePermission = !isEmpty(teamSpaceUserPermissions?.permissions)

        if (updatePermission) {
          await editSpace({ spaceId, payload, skipCacheInvalidation: true }).unwrap()
          await setUsersPermission({ spaceId, permissions: teamSpaceUserPermissions }).unwrap()
        } else {
          await editSpace({ spaceId, payload }).unwrap()
        }
      } catch (e) {
        dispatch(
          addSnackbarToState({
            severity: 'error',
            message: t(`teamSpace.teamSpaceEditingError`)
          })
        )
      }

      onClose()
    },
    []
  )

  const isEditSpaceButtonDisabled = isLoading || hasValidationErrors || isSetUsersPermissionLoading

  useModalEvents((): void => {
    handleEditTeamSpace(spaceId, spaceName, sharingPermission, teamSpaceUserPermissions)
  }, isEditSpaceButtonDisabled)

  return (
    <Button
      onClick={(): void => {
        handleEditTeamSpace(spaceId, spaceName, sharingPermission, teamSpaceUserPermissions)
      }}
      color="primary"
      variant="contained"
      disabled={isEditSpaceButtonDisabled}
    >
      {t('save')}
    </Button>
  )
}
