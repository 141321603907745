import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton, Stack, Typography } from '@mui/material'
import { isEmpty, isNil } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { deselectItem, getSelectedItems } from '@/store/features/uiSlice/uiSlice'
import { useGetProjectDetailsQuery } from '@/store/features/apis/slices/project/projectSlice'
import { ApiVersion } from '@/store/features/apis/helpers'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useGetSupportedAnalysisTypes } from '@/containers/ai/trainingDataCart/hooks/useGetSupportedAnalysisTypes'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { AiSectionPaths } from '@/constants'

type ProjectManagementType = {
  projectId: string
  setSuccessfulRuns: React.Dispatch<React.SetStateAction<number>>
}

const ProjectManagement = ({ projectId, setSuccessfulRuns }: ProjectManagementType): React.ReactElement => {
  const { t } = useTranslation('aiTraining')
  const dispatch = useAppDispatch()
  const { handleApiHookErrors } = useErrorHandler()
  const successfulRuns = React.useRef<number>()
  const { data: supportedAnalysis, isLoading: isLoadingSupportedAnalysis } = useGetSupportedAnalysisTypes(projectId)
  const [searchParams] = useSearchParams()
  const { aiSelectedAnalysisType } = useAppSelector((store) => store.ui)

  const {
    data: project,
    isLoading: isLoadingProject,
    isError: isErrorProject,
    error: errorProject
  } = useGetProjectDetailsQuery({ apiVersion: ApiVersion.V3, projectId }, { skip: isNil(projectId) })

  React.useEffect(() => {
    if (isErrorProject) {
      handleApiHookErrors(errorProject)
    }
  }, [isErrorProject, errorProject])

  const isOnDataSettingPage = React.useMemo(
    () => searchParams.get('page') === AiSectionPaths.dataSettings.value,
    [searchParams]
  )

  const filteredSupportedAnalysis = React.useMemo(() => {
    if (!isOnDataSettingPage) {
      return
    }
    return supportedAnalysis.filter((analysisWithRuns) => analysisWithRuns.type === aiSelectedAnalysisType)
  }, [supportedAnalysis, aiSelectedAnalysisType, isOnDataSettingPage])

  const isProjectRemoved = isOnDataSettingPage && isEmpty(filteredSupportedAnalysis)
  const analysis = isOnDataSettingPage ? filteredSupportedAnalysis : supportedAnalysis

  React.useEffect(() => {
    if (!isNil(analysis)) {
      const numberOfSuccessfulSupportedRuns = analysis?.reduce(
        (acc: number, data) => acc + data.numberOfSuccessfulRuns,
        0
      )
      if (numberOfSuccessfulSupportedRuns > 0) {
        setSuccessfulRuns((prevValue) => prevValue + numberOfSuccessfulSupportedRuns)
      }
      successfulRuns.current = numberOfSuccessfulSupportedRuns
    }
  }, [analysis])

  React.useEffect(() => {
    return () => {
      if (!isNil(successfulRuns.current) && successfulRuns.current > 0) {
        setSuccessfulRuns((prevValue) => prevValue - successfulRuns.current)
      }
    }
  }, [])

  const handleProjectRemove = React.useCallback(() => {
    dispatch(deselectItem({ id: projectId, type: 'project' }))
  }, [projectId])

  if (isLoadingProject || isLoadingSupportedAnalysis) {
    return <Skeleton variant="rounded" width="100%" height={30} animation="wave" sx={{ flexShrink: 0 }} />
  }

  if (isProjectRemoved) {
    return null
  }

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Stack direction="column" marginRight="auto" sx={{ width: '242px' }}>
        <Typography variant="text" component="p">
          {project?.projectName}
        </Typography>
        <Typography variant="subtitle" textTransform="uppercase" color="grey.600">
          {successfulRuns.current} {t('projectsCart.runs')}
        </Typography>
      </Stack>
      {!isOnDataSettingPage && (
        <SpriteIcon
          spriteId="times"
          width={20}
          style={{ cursor: 'pointer' }}
          color="grey.600"
          onClick={handleProjectRemove}
        />
      )}
    </Stack>
  )
}

type ProjectsManagementType = {
  setSuccessfulRuns: React.Dispatch<React.SetStateAction<number>>
}

export const TrainingDataProjectsManagement = ({ setSuccessfulRuns }: ProjectsManagementType): React.ReactElement => {
  const { t } = useTranslation('aiTraining')
  const selectedProjects = useAppSelector(getSelectedItems).projects

  if (isEmpty(selectedProjects)) {
    return (
      <Stack alignItems="center" paddingY={8}>
        <Typography variant="title" color="grey.400" sx={{ fontStyle: 'italic' }}>
          {t('projectsCart.addTrainingData')}
        </Typography>
        <Typography variant="subtitle" color="grey.400">
          {t('projectsCart.noDataSelected')}
        </Typography>
      </Stack>
    )
  }

  return (
    <React.Fragment>
      {selectedProjects.map((projectId) => (
        <ProjectManagement key={projectId} projectId={projectId} setSuccessfulRuns={setSuccessfulRuns} />
      ))}
    </React.Fragment>
  )
}
