import { first, isEmpty, isNil, last } from 'lodash'
import * as React from 'react'
import { Space } from '@/store/features/apis/slices/space/interfaces'
import { useGetUserSpacesQuery } from '@/store/features/apis/slices/space/spacesSlice'
import { useAppSelector } from '@/store/hooks'
import { useGetFolderDetailsQuery } from '@/store/features/apis/slices/folder/folderSlice'
import { useGetSpaceDetailsQuery } from '@/store/features/apis/slices/space/spaceSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { PathSelector } from '@/containers/spaces/interfaces'
import { useIsSharedWithMePage } from '@/hooks/useIsSharedWithMePage'

export const usePermittedSpaces = (): {
  permittedSpaces: Space[]
  isSpaceSelectableInNavigationTree: boolean
  isLoading: boolean
} => {
  const { handleApiHookErrors } = useErrorHandler()
  const { fullPath } = useAppSelector((state) => state.ui)
  const { data: spaces, isLoading, isError, error } = useGetUserSpacesQuery()

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  const isReadyToUseSpaces = !isLoading && !isEmpty(spaces)
  const permittedSpaces = isReadyToUseSpaces
    ? spaces.filter(({ requesterPermissions }) => requesterPermissions?.canCreateContentInRoot)
    : []
  const isSpaceSelectableInNavigationTree = isReadyToUseSpaces
    ? permittedSpaces.some((targetSpace) => targetSpace.spaceId === last(fullPath))
    : false

  return { permittedSpaces, isSpaceSelectableInNavigationTree, isLoading }
}

export const useGetPathPermission = ({
  path
}: {
  path: PathSelector
}): { hasPermission: boolean; isLoading: boolean; pathName: string } => {
  const isSharedWithMePage = useIsSharedWithMePage()
  const { handleApiHookErrors } = useErrorHandler()

  const isSpaceSelected = path?.length === 1
  const spaceId = first(path)
  const folderId = last(path)

  const {
    data: spaceData,
    isLoading: isLoadingSpaceData,
    isError: isErrorSpaceData,
    error: errorSpaceData
  } = useGetSpaceDetailsQuery({ spaceId }, { skip: !isSpaceSelected || isEmpty(path) })

  const {
    data: folderData,
    isLoading: isLoadingFolderData,
    isError: isErrorFolderData,
    error: errorFolderData
  } = useGetFolderDetailsQuery({ spaceId, folderId }, { skip: isSpaceSelected || isEmpty(path) })

  React.useEffect(() => {
    if (isErrorSpaceData) {
      handleApiHookErrors(errorSpaceData)
    }
  }, [isErrorSpaceData, errorSpaceData])

  React.useEffect(() => {
    if (isErrorFolderData) {
      handleApiHookErrors(errorFolderData)
    }
  }, [isErrorFolderData, errorFolderData])

  const hasPermission = isSpaceSelected
    ? spaceData?.requesterPermissions.canCreateContentInRoot
    : folderData?.requesterPermissions.canCreateContentInFolder

  if (isNil(path) && !isSharedWithMePage) {
    return {
      hasPermission: false,
      isLoading: true,
      pathName: ''
    }
  }

  if (isEmpty(path)) {
    return {
      hasPermission: false,
      isLoading: false,
      pathName: ''
    }
  }

  return {
    hasPermission,
    isLoading: isSpaceSelected ? isLoadingSpaceData : isLoadingFolderData,
    pathName: isSpaceSelected ? spaceData?.name : folderData?.name
  }
}
