import { UserOrganizationStatus } from '../../../../store/features/apis/slices/organization/interfaces'

export enum ScopeType {
  USER = 'USER',
  GROUP = 'GROUP'
}

export enum GroupName {
  SUPPORT = 'SUPPORT',
  PUBLIC = 'PUBLIC',
  ORGANIZATION = 'ORGANIZATION'
}

export enum PermissionType {
  FULL_ACCESS = 'FULL_ACCESS',
  EDIT = 'EDIT',
  COPY = 'COPY',
  VIEW = 'VIEW',
  REMOVE = 'NONE',
  NO_ACCESS = 'NO_ACCESS'
}

export interface Scope {
  scopeType: ScopeType
  email?: string
  username?: string
  groupName?: string
  groupIdExt?: string
  owner?: boolean
}

export interface SpacePermission {
  scope: Scope
  permission: PermissionType
  message?: string
  notifyByEmail?: boolean
  expiresAt?: Date
}

export interface SpacePermissions {
  permissions: SpacePermission[]
}

export interface UserData {
  emailAddress: string
  user: string
  position: string
  primaryInterest: string
  intercom: {
    emailAddressHash: string
  }
  firstname: string
  lastname: string
  username: string
  phone: string
  notifyOnSimulationFinish: boolean
  organizationInfo?: {
    organizationId: string
    organizationStatus: UserOrganizationStatus
  }
  uidExt: number
}

interface ForumUserData {
  avatar_template: string
}

export interface ForumData {
  user: ForumUserData
}

export type SharePermission = {
  key: string
  value: PermissionType
}
