import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from '@mui/material/Skeleton'
import { ModalTemplateProps } from '@/components/modal/interfaces'
import { Model } from '@/store/features/apis/slices/ai/interfaces'
import { ModalActions, ModalContent, ModalTitle } from '@/components/modal/Modal'
import { Button } from '@/components/button/Button'
import { usePatchModelMutation, usePostImageMutation } from '@/store/features/apis/slices/ai/aiSlice'
import { useAppDispatch } from '@/store/hooks'
import { addSnackbarToState } from '@/store/features/uiSlice/uiSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useValidation } from '@/hooks/useValidation'
import { isValidRequiredNumberOfChars, itDoesNotStartWithEmptySpace } from '@/helpers/validationHelpers'

const Editor = React.lazy(() => import('@/components/editor/Editor'))

interface ModelDocumentationTemplateProps extends ModalTemplateProps {
  title: string
  model: Model
}

export const ModelDocumentationTemplate = ({
  title,
  onClose,
  showCloseButton,
  model
}: ModelDocumentationTemplateProps): React.ReactElement => {
  const { t } = useTranslation(['modals', 'adminArea'])
  const dispatch = useAppDispatch()
  const { validateField } = useValidation()
  const [patchModel, { isLoading }] = usePatchModelMutation()
  const editorRef = React.useRef(null)

  const [documentation, setDocumentation] = React.useState<string>(model?.documentation || '')

  React.useEffect(() => {
    if (editorRef.current) {
      editorRef.current?.setMarkdown(documentation)
    }
    setDocumentation(model?.documentation || '')
  }, [model?.documentation])

  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    documentation: { valid: true, message: '' }
  })

  const handleDescriptionChange = React.useCallback((updatedDocumentation: string) => {
    setDocumentation(updatedDocumentation)
    setValidationErrors((prevState) => ({
      ...prevState,
      documentation: validateField(updatedDocumentation, [
        itDoesNotStartWithEmptySpace,
        isValidRequiredNumberOfChars(1)
      ])
    }))
  }, [])

  const handleSaveDocumentationOnClick = React.useCallback(async (): Promise<void> => {
    const isDocumentationValid = validationErrors['documentation']?.valid
    if (!isDocumentationValid) {
      return
    }

    await patchModel({
      modelUuid: model.uuid,
      payload: { documentation }
    })
      .unwrap()
      .then((_) => {
        dispatch(
          addSnackbarToState({
            severity: 'success',
            message: t('modelDocumentation.alerts.success', { name: model.name })
          })
        )

        onClose()
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [patchModel, model, documentation, t])

  const [postImage] = usePostImageMutation()
  const imageUploadHandler = React.useCallback(
    async (image: File): Promise<string> => {
      return postImage({ modelUuid: model?.uuid, image })
        .unwrap()
        .then((response) => response.url)
        .catch((error) => {
          handleApiHookErrors(error)
          return null
        })
    },
    [model?.uuid]
  )

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent>
        <React.Suspense fallback={<Skeleton width="100%" height={120} animation="wave" />}>
          <Editor
            ref={editorRef}
            onChange={handleDescriptionChange}
            value={documentation}
            imageUploadHandler={imageUploadHandler}
          />
        </React.Suspense>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('modelDocumentation.actions.cancel')}
        </Button>
        <Button
          onClick={handleSaveDocumentationOnClick}
          color="primary"
          variant="contained"
          isLoading={isLoading}
          disabled={!validationErrors['documentation']?.valid}
        >
          {t('modelDocumentation.actions.save')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}
