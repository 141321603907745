import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import format from 'date-fns/format'

type FormattedDateProps = {
  date: Date
  disabled?: boolean
  showTime?: boolean
}

export const FormattedDate = ({ date, disabled, showTime }: FormattedDateProps): React.ReactElement => {
  return (
    <Stack direction="column" color={disabled ? 'text.disabled' : 'text.primary'}>
      <Typography variant="text">{format(date, 'MMM d, yyyy')}</Typography>
      {showTime && (
        <Typography variant="text" fontSize="11px" color={disabled ? 'text.disabled' : 'grey.700'}>
          {format(date, 'hh:MM aa')}
        </Typography>
      )}
    </Stack>
  )
}
