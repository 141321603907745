import * as React from 'react'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import Stack from '@mui/material/Stack'
import { MainDisplay } from '@/containers/dashboard/MainDisplay'
import { useGetModelQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { ModelDetails, ModelDetailsSkeleton } from '@/containers/ai/models/ModelDetails'
// TODO: Uncomment the following lines if the decision is to have separate sections for ModelTrainingPipelines and ModelAvailableVersions, for now we are using ModelAllVersions.
// eslint-disable-next-line unused-imports/no-unused-imports
import { ModelAvailableVersions, ModelAvailableVersionsSkeleton } from '@/containers/ai/models/ModelAvailableVersions'
import { ModelUsage } from '@/containers/ai/models/ModelUsage'
import { ModelAccess } from '@/containers/ai/models/ModelAccess'
import { ModelHistory, ModelHistorySkeleton } from '@/containers/ai/models/ModelHistory'
import { AiModelTrainingBreadcrumbs } from '@/containers/ai/breadcrumbs/AiModelTrainingBreadcrumbs'
import { ModelReleasedVersions, ModelReleasedVersionsSkeleton } from '@/containers/ai/models/ModelReleasedVersions'
import { useIsAiAreaEnabled, useRedirectIfPermissionDenied } from '@/permissions/areas'
import { setAiSectionPath } from '@/store/features/uiSlice/uiSlice'
import { AiSectionPaths, PagePath } from '@/constants'
import { useAppDispatch } from '@/store/hooks'
// TODO: Uncomment the following lines if the decision is to have separate sections for ModelTrainingPipelines and ModelAvailableVersions, for now we are using ModelAllVersions.
// eslint-disable-next-line unused-imports/no-unused-imports
import { ModelTrainingPipelines, ModelTrainingPipelinesSkeleton } from '@/containers/ai/models/ModelTrainingPipelines'
import { ModelAllVersions, ModelAllVersionsSkeleton } from '@/containers/ai/models/ModelAllVersions'
import { useCanCreateNewModelVersion } from '@/operations/ai/models/versions/useCanCreateNewModelVersion'
import { useCenteredCircularProgress } from '@/components/progress/CenteredCircularProgress'

const ModelDetailsPage = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { handleNotFound } = useErrorHandler()

  const permissionAccess = useIsAiAreaEnabled()
  const [isLoadingPermissions, hasAccess] = permissionAccess
  useRedirectIfPermissionDenied([permissionAccess])

  const { modelUuid } = useParams<{ modelUuid: string }>()
  const {
    data: model,
    error,
    isLoading: isLoadingModel,
    isFetching: isFetchingModel
  } = useGetModelQuery({ modelUuid: modelUuid }, { skip: isLoadingPermissions || !hasAccess })

  const isLoading = isLoadingPermissions || isLoadingModel
  const canCreateNewVersion = useCanCreateNewModelVersion(model?.uuid)
  const canViewModelDetails = model?.shared_with_organization || canCreateNewVersion || !hasAccess

  useCenteredCircularProgress(isLoading || !hasAccess)

  React.useEffect(() => {
    if (!isLoading) {
      if (!isNil(error) || !canViewModelDetails) {
        handleNotFound(error, PagePath.AI_MODELS)
      }
    }
  }, [isLoading, error])

  React.useEffect(() => {
    if (!isNil(model)) {
      dispatch(setAiSectionPath([AiSectionPaths.aiModelTraining, { label: model.name, value: model.uuid }]))
    }
  }, [model])

  if (isLoading || !hasAccess) {
    return null
  }

  return (
    <MainDisplay>
      <Stack direction="column" gap={8} pt={4}>
        <AiModelTrainingBreadcrumbs />
        <Stack direction="row" spacing={5} pb={6}>
          <Stack direction="column" spacing={5} flexGrow={1}>
            {isLoading ? (
              <>
                <ModelDetailsSkeleton />
                <ModelReleasedVersionsSkeleton />
                {/* <ModelAvailableVersionsSkeleton />
                <ModelTrainingPipelinesSkeleton /> */}
                <ModelAllVersionsSkeleton />
              </>
            ) : (
              <>
                <ModelDetails model={model} isFetching={isFetchingModel} />
                <ModelReleasedVersions modelId={modelUuid} />
                {/* <ModelAvailableVersions modelUuid={modelUuid} />
                <ModelTrainingPipelines modelUuid={modelUuid} /> */}
                <ModelAllVersions modelUuid={modelUuid} />
              </>
            )}
          </Stack>
          <Stack direction="column" spacing={4} width={295} flexGrow={0} flexShrink={0}>
            {isLoading ? (
              <>
                <ModelUsage isLoading />
                <ModelHistorySkeleton />
                <ModelAccess isLoading />
              </>
            ) : (
              <>
                <ModelUsage model={model} />
                <ModelHistory modelId={modelUuid} />
                <ModelAccess model={model} />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </MainDisplay>
  )
}

export default ModelDetailsPage
