import { TrainingDataCart } from './TrainingDataCart'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getSelectedItems, openModal } from '@/store/features/uiSlice/uiSlice'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'
import { REQUIRED_NUMBER_OF_RUNS } from '@/constants/ai'

export const TrainingCartDataSelection = (): React.ReactElement => {
  const { t } = useTranslation('aiTraining')
  const dispatch = useAppDispatch()
  const selectedProjects = useAppSelector(getSelectedItems).projects
  const [successfulRuns, setSuccessfulRuns] = React.useState(0)
  const isAlertShown = successfulRuns < REQUIRED_NUMBER_OF_RUNS && selectedProjects.length > 0

  React.useEffect(() => {
    if (isEmpty(selectedProjects)) {
      setSuccessfulRuns(0)
    }
  }, [selectedProjects])

  const handleNextButtonClick = React.useCallback(() => {
    dispatch(
      openModal({
        template: ModalTemplate.SELECT_AI_TRAINING_ANALYSIS_TYPE,
        showCloseButton: true,
        modalProps: {
          title: t('selectAiTrainingAnalysisType.title', { ns: 'modals' })
        }
      })
    )
  }, [])

  return (
    <TrainingDataCart
      handleButtonClick={handleNextButtonClick}
      successfulRuns={successfulRuns}
      setSuccessfulRuns={setSuccessfulRuns}
      isAlertShown={isAlertShown}
    />
  )
}
