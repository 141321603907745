import { MyProjectsSelection } from './MyProjectsSelection'
import { SharedWithMeProjectsSelection } from './SharedWithMeProjectsSelection'
import { SearchedProjectsSelection } from './SearchedProjectsSelection'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { PagePath } from '@/constants'
import { deselectItem, getSelectedItems, selectItem, selectItems } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

export const ProjectsSelection = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const selectedProjects = useAppSelector(getSelectedItems).projects
  const [searchParams] = useSearchParams()

  const fullPath = useAppSelector((state) => state.ui.fullPath)

  const isInSharedWithMeProjects = fullPath?.includes(PagePath.SHARED_WITH_ME)
  const isInTeamSpaces = fullPath?.includes(PagePath.TEAM_SPACES)
  const isInSearchMode = searchParams.has('q')

  const onProjectCheckboxClick = (projectId: string): void => {
    if (selectedProjects.includes(projectId)) {
      dispatch(deselectItem({ id: projectId, type: 'project' }))
      return
    }
    dispatch(selectItem({ id: projectId, type: 'project' }))
    return
  }

  const onSelectAllProjectsCheckboxClick = (projectIds: string[]): void => {
    if (projectIds.some((projectId) => selectedProjects.includes(projectId))) {
      const filteredProjects = selectedProjects.filter((projectId) => !projectIds.includes(projectId))
      dispatch(selectItems({ items: filteredProjects, type: 'project' }))
    } else {
      dispatch(selectItems({ items: [...projectIds, ...selectedProjects], type: 'project' }))
    }
  }

  if (isInTeamSpaces) {
    return (
      <Stack width="100%" direction="row" justifyContent="center" alignItems="center">
        <Typography>{t('teamSpace.teamSpacesSelected')}</Typography>
      </Stack>
    )
  }

  if (isInSearchMode) {
    return (
      <SearchedProjectsSelection
        onSelect={onProjectCheckboxClick}
        onSelectAll={onSelectAllProjectsCheckboxClick}
        selectedProjects={selectedProjects}
      />
    )
  }

  if (isInSharedWithMeProjects) {
    return (
      <SharedWithMeProjectsSelection
        onSelect={onProjectCheckboxClick}
        onSelectAll={onSelectAllProjectsCheckboxClick}
        selectedProjects={selectedProjects}
      />
    )
  }

  return (
    <MyProjectsSelection
      onSelect={onProjectCheckboxClick}
      onSelectAll={onSelectAllProjectsCheckboxClick}
      selectedProjects={selectedProjects}
    />
  )
}
