import { useAttributes } from './newWorld'
import { useGetSettingsMapQuery } from '../store/features/apis/slices/user/userSlice'

export const useLoadEssentialResources = (): { isLoading: boolean; data: boolean } => {
  const { isError: isErrorAttributes, isLoading: isLoadingAttributes } = useAttributes()
  const { isError: isErrorSettingsMap, isLoading: isLoadingSettingsMap } = useGetSettingsMapQuery()
  const isLoading = isLoadingAttributes || isLoadingSettingsMap
  const isError = isErrorAttributes || isErrorSettingsMap
  return { isLoading, data: !isLoading && !isError }
}
