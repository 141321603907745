import { ApiKey } from './interfaces'
import { DescriptionInput } from './DescriptionInput'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { ActionButton } from '@/components/button/ActionButton'
import { SwitchStyled } from '@/components/elements/Switch'
import { FormattedDate } from '@/components/FormattedDate'
import { usePutUserApiKeyMutation } from '@/store/features/apis/slices/user/userSlice'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'
import { openModal } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch } from '@/store/hooks'
import { TableHeaderCell, TableRowProps } from '@/components/table/enhancedTable/interfaces'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const useGetApiKeysTableHeaderData = (): TableHeaderCell[] => {
  const { t } = useTranslation(['profileArea'])
  return [
    {
      id: 'description',
      label: t('apiKeys.labels.name'),
      numeric: false,
      disablePadding: true,
      sx: { width: '25%' }
    },
    {
      id: 'suffix',
      label: t('apiKeys.labels.apiKey'),
      numeric: false,
      disablePadding: true,
      disableSorting: true,
      sx: { width: '30%' }
    },
    {
      id: 'createdAt',
      label: t('apiKeys.labels.created'),
      numeric: false,
      disablePadding: true,
      sx: { width: '12.5%' }
    },
    {
      id: 'expiresAt',
      label: t('apiKeys.labels.expires'),
      numeric: false,
      disablePadding: true,
      sx: { width: '12.5%' }
    },
    {
      id: 'enabled',
      label: t('apiKeys.labels.enabled'),
      numeric: false,
      disablePadding: true,
      sx: { width: '12.5%' }
    },
    {
      id: 'delete',
      label: '',
      numeric: false,
      disablePadding: true,
      disableSorting: true,
      sx: { width: 'auto', textAlign: 'right' }
    }
  ]
}

export const useGetApiKeysTableBodyData = (apiKeys: ApiKey[]): TableRowProps[] => {
  const { t } = useTranslation(['profileArea', 'modals'])
  const dispatch = useAppDispatch()
  const [putUserApiKey] = usePutUserApiKeyMutation()

  const handleEnabledDisabledOnClick = React.useCallback(
    (apiKeySuffix: string, description: string, enabled: boolean) => {
      putUserApiKey({ apiKeySuffix, description, enabled })
    },
    []
  )

  const handleDeleteOnClick = React.useCallback((apiKeySuffix: string) => {
    dispatch(
      openModal({
        template: ModalTemplate.TRASH_USER_API_KEY,
        showCloseButton: true,
        modalProps: {
          title: t('trash.apiKey.title', { ns: 'modals' }),
          selectedApiKeySuffix: apiKeySuffix
        }
      })
    )
  }, [])

  const tableData = React.useMemo(() => {
    if (!isNil(apiKeys)) {
      const now = new Date()

      return apiKeys.map((apiKey: ApiKey) => {
        const createdAtDate = new Date(apiKey.createdAt)
        const expiresAtDate = new Date(apiKey.expiresAt)
        const isExpired = now > expiresAtDate
        return {
          tableRowId: `${apiKey.suffix}`,
          tableRowCells: [
            {
              id: `description`,
              title: apiKey.description,
              value: apiKey.description,
              component: <DescriptionInput apiKey={apiKey} disabled={isExpired} />
            },
            {
              id: `suffix`,
              sortable: false,
              value: apiKey.suffix,
              component: (
                <Typography color={isExpired ? 'text.disabled' : 'text.primary'}>{`${'•'.repeat(12)}${
                  apiKey.suffix
                }`}</Typography>
              )
            },
            {
              id: `createdAt`,
              value: createdAtDate,
              component: <FormattedDate date={new Date(createdAtDate)} disabled={isExpired} showTime />
            },
            {
              id: `expiresAt`,
              value: expiresAtDate,
              component: <FormattedDate date={new Date(expiresAtDate)} disabled={isExpired} showTime />
            },
            {
              id: `enabled`,
              value: isExpired
                ? t('apiKeys.labels.expired')
                : apiKey.enabled
                ? t('apiKeys.labels.enabled')
                : t('apiKeys.labels.disabled'),
              component: (
                <React.Fragment>
                  {isExpired && (
                    <Typography color={isExpired ? 'text.disabled' : 'text.primary'}>
                      {t('apiKeys.labels.expired')}
                    </Typography>
                  )}
                  {!isExpired && (
                    <SwitchStyled
                      qa-attribute={`enable-key-${apiKey.suffix}`}
                      onChange={(_): void =>
                        handleEnabledDisabledOnClick(apiKey.suffix, apiKey.description, !apiKey.enabled)
                      }
                      color="primary"
                      checked={apiKey.enabled}
                      aria-label="enabled-switch"
                    />
                  )}
                </React.Fragment>
              )
            },
            {
              id: 'delete',
              sortable: false,
              component: (
                <Box display="flex" justifyContent="flex-end">
                  <ActionButton
                    onClick={(): void => handleDeleteOnClick(apiKey.suffix)}
                    variant="text"
                    title={t('apiKeys.buttons.delete')}
                  >
                    <SpriteIcon spriteId="trash-can" color="text.primary" height={13} width={13} />
                  </ActionButton>
                </Box>
              )
            }
          ]
        }
      })
    }
  }, [apiKeys])

  return tableData ?? []
}
