import { getCookie } from './cookies'
import axios, { AxiosResponse } from 'axios'
import { Buffer } from 'buffer'

export interface ResponseDataItem {
  path: string
  message?: string
  errorCode: string
}

export interface ResponseData {
  status: number
  message?: string
  errorCode: string
  items: ResponseDataItem[]
}

export const getBaseUrl = (): string => location.protocol + '//' + location.host

export const getLogoUrl = (): string => getBaseUrl() + '/?logo_manager_logo'

export const utf8ToBase64 = (data: string): string => Buffer.from(data, 'utf8').toString('base64')

export const createRequestHeaders = (): Record<string, string> => {
  const { uid, sid } = getCookie()

  return {
    Authorization: 'Basic ' + utf8ToBase64(`${uid}:${sid}`)
  }
}

export const get = <T>(url: string): Promise<AxiosResponse<T>> => axios.get<T>(url, { headers: createRequestHeaders() })

export const post = <T>(url: string, data: any): Promise<AxiosResponse<T>> =>
  axios.post<T>(url, data, { headers: createRequestHeaders() })
