import { hijackWindowConsole } from './windowConsoleCapture'
import { getUsername } from '../helpers/cookies'
import { isProduction } from '../utils/windowUtils'
import { TrackJS } from 'trackjs'

TrackJS.install({
  token: '7367ab9f605d4077ad4e7e56af6f06ed',
  application: 'content-management',
  userId: getUsername() ?? '',
  enabled: isProduction()
})

hijackWindowConsole()

window.addEventListener('error', (event: ErrorEvent) => {
  const logBody = {
    message: `${event?.message ?? ''}`,
    stack_trace:
      event.error?.stack ?? event.error?.stacktrace ?? event.error?.stack_trace ?? JSON.stringify(event.error)
  }
  // eslint-disable-next-line no-console
  console.error(logBody)
})
