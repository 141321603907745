import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { Permission } from '@/components/modal/templates/teamSpace/SharingPermissions'
import { PermissionType } from '@/components/modal/templates/share/interfaces'

type OrganizationSharePermissionType = {
  permission: PermissionType
  hideDescription?: boolean
}

export const OrganizationSharePermission = ({
  permission,
  hideDescription
}: OrganizationSharePermissionType): React.ReactElement => {
  const { t } = useTranslation('modals')

  switch (permission) {
    case PermissionType.REMOVE:
      return (
        <Permission
          icon={<SpriteIcon width={16} height={16} spriteId="lock" color={'grey.600'} />}
          title={t('teamSpace.organizationSharingPermissions.restricted')}
          description={t('teamSpace.organizationSharingPermissions.restrictedDescription')}
          hideDescription={hideDescription}
        />
      )
    case PermissionType.VIEW:
      return (
        <Permission
          icon={<SpriteIcon width={16} height={16} spriteId="eye" color={'grey.600'} />}
          title={t('teamSpace.organizationSharingPermissions.viewer')}
          description={t('teamSpace.organizationSharingPermissions.viewerDescription')}
          hideDescription={hideDescription}
        />
      )
    case PermissionType.COPY:
      return (
        <Permission
          icon={<SpriteIcon width={16} height={16} spriteId="copy" color={'grey.600'} />}
          title={t('teamSpace.organizationSharingPermissions.canCopy')}
          description={t('teamSpace.organizationSharingPermissions.canCopyDescription')}
          hideDescription={hideDescription}
        />
      )
    case PermissionType.EDIT:
      return (
        <Permission
          icon={<SpriteIcon width={16} height={16} spriteId="pencil" color={'grey.600'} />}
          title={t('teamSpace.organizationSharingPermissions.canEdit')}
          description={t('teamSpace.organizationSharingPermissions.canEditDescription')}
          hideDescription={hideDescription}
        />
      )
    case PermissionType.FULL_ACCESS:
      return (
        <Permission
          icon={<SpriteIcon width={16} height={16} spriteId="user-gear" color={'grey.600'} />}
          title={t('teamSpace.organizationSharingPermissions.canManage')}
          description={t('teamSpace.organizationSharingPermissions.canManageDescription')}
          hideDescription={hideDescription}
        />
      )
  }
}
