import { PersonalSpaceNavigationItem } from './PersonalSpaceNavigationItem'
import { TeamSpacesNavigationItem } from './TeamSpacesNavigationItem'
import { SharedWithMeNavigationItem } from './SharedWithMeNavigationItem'
import * as React from 'react'
import { Box, Stack } from '@mui/material'
import { first } from 'lodash'
import { PagePath } from '@/constants'
import { useGetUserSpacesQuery } from '@/store/features/apis/slices/space/spacesSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { setFullPath } from '@/store/features/uiSlice/uiSlice'

export type NavigationItemType = {
  isOpen?: boolean
  isSelected?: boolean
  handleOnClick: (route?: string) => void
}

export const AiModelSelectionNavigation = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { handleApiHookErrors } = useErrorHandler()
  const { data: spaces, isLoading, isFetching, isError, error } = useGetUserSpacesQuery()
  const [navigationItemSelected, setNavigationItemSelected] = React.useState<PagePath>(PagePath.MY_PROJECTS)
  const fullPath = useAppSelector((state) => state.ui.fullPath)

  const personalSpace = React.useMemo(() => spaces?.find((space) => space.spaceType === 'PERSONAL'), [spaces])
  const teamSpaces = React.useMemo(() => spaces?.filter((space) => space.spaceType === 'TEAM'), [spaces])

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  React.useEffect(() => {
    dispatch(setFullPath([personalSpace.spaceId]))
  }, [personalSpace])

  const handleOnNavigationItemClick = React.useCallback(
    (pagePath: PagePath) => {
      setNavigationItemSelected(pagePath)
      dispatch(setFullPath([pagePath === PagePath.MY_PROJECTS ? personalSpace.spaceId : pagePath]))
    },
    [personalSpace.spaceId]
  )

  const isMyProjectsSelected = fullPath?.length === 1 && first(fullPath) === personalSpace.spaceId
  const isTeamSpacesSelected = fullPath?.length === 1 && first(fullPath) === PagePath.TEAM_SPACES

  if (isLoading || isFetching) {
    return null
  }

  return (
    <Box paddingRight={4} sx={{ width: '266px' }}>
      <Box
        qa-attribute="ai-model-navigation-tree"
        sx={{
          width: '100%',
          overflowX: 'auto'
        }}
      >
        <Stack direction="column" sx={{ width: 'fit-content' }}>
          <PersonalSpaceNavigationItem
            personalSpacePath={[personalSpace.spaceId]}
            handleOnClick={(): void => handleOnNavigationItemClick(PagePath.MY_PROJECTS)}
            isSelected={isMyProjectsSelected}
            isOpen={navigationItemSelected === PagePath.MY_PROJECTS}
          />
          <TeamSpacesNavigationItem
            spaces={teamSpaces}
            handleOnClick={(): void => handleOnNavigationItemClick(PagePath.TEAM_SPACES)}
            isSelected={isTeamSpacesSelected}
            isOpen={navigationItemSelected === PagePath.TEAM_SPACES}
          />
          <SharedWithMeNavigationItem
            handleOnClick={(): void => handleOnNavigationItemClick(PagePath.SHARED_WITH_ME)}
            isSelected={navigationItemSelected === PagePath.SHARED_WITH_ME}
          />
        </Stack>
      </Box>
    </Box>
  )
}
