import { EnhancedTableHead } from './EnhancedTableHead'
import { EnhancedTableRows } from './EnhancedTableRows'
import { EnhancedTableWithExternalStateType, Order } from './interfaces'
import { EnhancedTableInfinitePagination } from './EnhancedTableInfinitePagination'
import { useIsMobile } from '../../../hooks/useIsMobile'
import * as React from 'react'
import { Box, Skeleton, Stack, Table, TableBody, TableContainer, TablePagination } from '@mui/material'
import { isNil } from 'lodash'

export const EnhancedTableWithExternalState = ({
  tableHeaderData,
  tableBodyData,
  totalTableItemsCount,
  isLoading,
  page,
  setPage,
  hasMorePages,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  rowsPerPageOptions = [10, 25],
  rowsPerPage,
  setRowsPerPage,
  selected,
  onSelect,
  onSelectAll,
  rowComponent,
  ...boxProps
}: EnhancedTableWithExternalStateType): React.ReactElement => {
  const isMobile = useIsMobile({ breakpoint: 'md' })

  const handleOnRequestSort = React.useCallback((order: Order, orderBy: string): void => {
    setOrder(order)
    setOrderBy(orderBy)
  }, [])

  const handleOnChangePage = React.useCallback((page: number): void => {
    setPage(page)
  }, [])

  const handleOnRowsPerPageChange = React.useCallback((value: string): void => {
    const rows = parseInt(value, 10)
    setRowsPerPage(rows)
    setPage(1)
  }, [])

  if (isLoading) {
    return (
      <Box {...boxProps}>
        <Stack direction="column" gap={4}>
          {Array.from({ length: rowsPerPage + 1 }, (_, i) => (
            <Skeleton key={i} width="100%" height={32} variant="rounded" animation="wave" />
          ))}
        </Stack>
      </Box>
    )
  }

  return (
    <Box {...boxProps}>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table aria-labelledby="table" size={'medium'}>
          {/**
           * TODO: When design specs are available, implement correct enhanced table styles.
           * Currently, it's not clear how it should look, and it's breaking our mobile tests for simba. */}
          {!isMobile && (
            <EnhancedTableHead
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={onSelectAll}
              onRequestSort={handleOnRequestSort}
              rowCount={tableBodyData.length}
              tableHeaderData={tableHeaderData}
              useRowSelectionCheckbox={!isNil(onSelectAll)}
            />
          )}
          <TableBody>
            <EnhancedTableRows
              tableRowsData={tableBodyData}
              selected={selected}
              useRowSelectionCheckbox={!isNil(onSelect)}
              handleCheckboxOnClick={onSelect}
              rowComponent={rowComponent}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {!isNil(page) && (
        <Box display="flex" justifyContent="center">
          {!isNil(totalTableItemsCount) ? (
            <TablePagination
              component={'div'}
              rowsPerPageOptions={rowsPerPageOptions}
              count={totalTableItemsCount ?? tableBodyData.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(_, page): void => handleOnChangePage(page + 1)}
              onRowsPerPageChange={(event): void => handleOnRowsPerPageChange(event.target.value)}
            />
          ) : (
            <EnhancedTableInfinitePagination
              page={page}
              currentNumberOfItems={tableBodyData.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              hasMorePages={hasMorePages}
              handleOnChangePage={handleOnChangePage}
              handleOnRowsPerPageChange={handleOnRowsPerPageChange}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
