import { UserQuotaRow } from './UserQuotaRow'
import { UserSimulationsRow } from './UserSimulationsRow'
import { getUsername } from '../../helpers/cookies'
import { Button } from '../../components/button/Button'
import { useGetUserQuery, useGetUserV2BalancesQuery } from '../../store/features/apis/slices/user/userSlice'
import * as React from 'react'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import Link from '@mui/material/Link'
import { isInsideCypress } from '@/helpers/cookies'
import { SpriteIcon } from '@/assets/SpriteIcon'

const PRICING_PAGE_LINK = 'https://www.simscale.com/product/pricing/'

export const IndividualQuotaWidget = (): React.ReactElement => {
  const { t } = useTranslation('productPlan')

  const username = getUsername()
  const { data: user } = useGetUserQuery({ username }, { skip: isNil(username) })

  const {
    data: userBalances,
    isLoading: isLoadingUserBalances,
    isFetching: isFetchingUserBalances
  } = useGetUserV2BalancesQuery({ uidExt: user?.uidExt }, { skip: isNil(user?.uidExt) })

  return (
    <Stack direction="column" gap={4} qa-attribute="individual-quota-widget">
      <UserSimulationsRow
        isLoading={isLoadingUserBalances || isFetchingUserBalances}
        title={t('simulationsRemaining')}
        balances={userBalances?.quantitativeSimulations}
      />
      <UserQuotaRow
        isLoading={isLoadingUserBalances || isFetchingUserBalances}
        title={t('individualCpuUsage')}
        balances={userBalances?.cpuSeconds}
      />

      <Link href={PRICING_PAGE_LINK} target={isInsideCypress() ? '_self' : '_blank'} rel="noopener noreferrer">
        <Button variant="outlined" fullWidth={true} qaAttribute={t('upgradeYourPlan')}>
          {t('upgradeYourPlan')}
          <SpriteIcon spriteId="arrow-up-right-from-square" width={12} height={12} style={{ marginLeft: '10px' }} />
        </Button>
      </Link>
    </Stack>
  )
}
