import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { Alert, FormControl, FormControlLabel, Grow, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { AnalysisTypeDisplayType } from '@/components/modal/templates/ai/SelectAiTrainingAnalysisType/SelectAiTrainingAnalysisType'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { AnalysisType, getStringFromAnalysisType } from '@/constants/propForAnalysisType'
import { setAiSelectedAnalysisType } from '@/store/features/uiSlice/uiSlice'
import { REQUIRED_NUMBER_OF_RUNS } from '@/constants/ai'

type AnalysisTypePickProps = {
  analysisTypes: AnalysisTypeDisplayType[]
}

export const AnalysisTypePick = ({ analysisTypes }: AnalysisTypePickProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['modals', 'aiTraining'])
  const pickedAnalysisType = useAppSelector((store) => store.ui.aiSelectedAnalysisType)
  const insufficientRunsOnAllAnalysisTypes = analysisTypes.every(
    (type) => type.numberOfSuccessfulRuns < REQUIRED_NUMBER_OF_RUNS
  )

  React.useEffect(() => {
    dispatch(setAiSelectedAnalysisType(undefined))
  }, [])

  const handleSelectAnalysisType = React.useCallback((value: string) => {
    dispatch(setAiSelectedAnalysisType(value as AnalysisType))
  }, [])

  return (
    <Stack gap={1} pb={2}>
      <Typography variant="summaryCardDescription">
        {t('selectAiTrainingAnalysisType.analysisTypeDescription')}
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="analysis-type-radio-buttons"
          name="analysis-type-radio-buttons-group"
          value={pickedAnalysisType ?? ''}
          onChange={(event): void => handleSelectAnalysisType(event.target.value)}
        >
          {analysisTypes.map((analysisType) => (
            <FormControlLabel
              key={analysisType.type}
              value={analysisType.type}
              control={<Radio />}
              disabled={analysisType.numberOfSuccessfulRuns < REQUIRED_NUMBER_OF_RUNS}
              label={
                <Typography variant="summaryCardDescription">
                  {`${getStringFromAnalysisType(analysisType.type)} (${analysisType.numberOfSuccessfulRuns} ${t(
                    'projectsCart.runs',
                    {
                      ns: 'aiTraining'
                    }
                  )})`}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
      {insufficientRunsOnAllAnalysisTypes && (
        <Grow in style={{ flexShrink: 0 }}>
          <Alert icon={false} severity="warning">
            <Typography variant="text">
              {t('selectAiTrainingAnalysisType.notEnoughRunsForSpecificAnalysisType')}
            </Typography>
          </Alert>
        </Grow>
      )}
    </Stack>
  )
}
