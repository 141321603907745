import { NavigationMenuItemStyled } from './styledComponents'
import { getUrl } from '../../helpers/navigation'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import Link from '@mui/material/Link'
import { Menu } from '@mui/material'

export const HelpLinks = ({
  isOpen,
  anchorEl,
  handleClose
}: {
  isOpen: boolean
  anchorEl: any
  handleClose: () => void
}): React.ReactElement => {
  const { t } = useTranslation('header')

  const handleOnKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLLIElement>, url: string): void => {
    if (event.key === 'Enter') {
      window.location.href = url
    }
  }, [])

  return (
    <Menu
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableAutoFocusItem
      qa-attribute="qa-helpMenu"
      slotProps={{
        root: {
          style: {
            zIndex: 2
          }
        },
        paper: {
          style: { zIndex: 1300 }
        }
      }}
    >
      <NavigationMenuItemStyled onKeyDown={(event): void => handleOnKeyDown(event, '/tutorials/')}>
        <Link href={getUrl('/tutorials/')}>{t('tutorials')}</Link>
      </NavigationMenuItemStyled>
      <NavigationMenuItemStyled onKeyDown={(event): void => handleOnKeyDown(event, '/docs/')}>
        <Link href={getUrl('/docs/')}>{t('documentation')}</Link>
      </NavigationMenuItemStyled>
      <NavigationMenuItemStyled onKeyDown={(event): void => handleOnKeyDown(event, 'https://learning.simscale.com')}>
        <Link href="https://learning.simscale.com">{t('learningCenter')}</Link>
      </NavigationMenuItemStyled>
    </Menu>
  )
}
