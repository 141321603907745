import * as React from 'react'
import { isNil } from 'lodash'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SpaceOrganizationPermission } from '@/components/modal/templates/teamSpace/organizationPermission/SpaceOrganizationPermission'
import { useGetSpaceUsersPermissionsQuery } from '@/store/features/apis/slices/space/spaceSlice'
import { GroupName, PermissionType, ScopeType } from '@/components/modal/templates/share/interfaces'
import { useErrorHandler } from '@/hooks/useErrorHandler'

type ManageOrganizationPermissionsType = {
  selectedTeamSpaceId: string
  organizationSharingPermission: PermissionType
  setOrganizationSharingPermission: React.Dispatch<React.SetStateAction<PermissionType>>
  isProfessionalOrganization: boolean
}

export const ManageOrganizationPermissions = ({
  selectedTeamSpaceId,
  organizationSharingPermission,
  setOrganizationSharingPermission,
  isProfessionalOrganization
}: ManageOrganizationPermissionsType): React.ReactElement => {
  const { t } = useTranslation('modals')
  const { handleApiHookErrors } = useErrorHandler({})

  const {
    data: spaceUsersPermissions,
    isLoading,
    isFetching,
    isError,
    error
  } = useGetSpaceUsersPermissionsQuery({ spaceId: selectedTeamSpaceId }, { skip: isNil(selectedTeamSpaceId) })

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  React.useEffect(() => {
    if (!isNil(spaceUsersPermissions) && !isLoading && !isFetching) {
      const spaceOrganizationPermission = spaceUsersPermissions.permissions.find(
        (permission) =>
          permission.scope.scopeType === ScopeType.GROUP && permission.scope.groupName === GroupName.ORGANIZATION
      )
      setOrganizationSharingPermission(spaceOrganizationPermission?.permission ?? PermissionType.REMOVE)
    }
  }, [spaceUsersPermissions, isLoading, isFetching])

  const handleSharingPermissionChange = React.useCallback((sharingPermission: PermissionType) => {
    setOrganizationSharingPermission(sharingPermission)
  }, [])

  return (
    <Stack direction="column" gap={2}>
      <Typography variant="text">{t('permissions.organizationalAccess')}</Typography>
      <SpaceOrganizationPermission
        sharingPermission={organizationSharingPermission}
        handleSharingPermissionChange={handleSharingPermissionChange}
        isProfessionalOrganization={isProfessionalOrganization}
      />
    </Stack>
  )
}
