import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OperationWithPermission } from '@/operations/interfaces'
import { Model } from '@/store/features/apis/slices/ai/interfaces'
import { useAppDispatch } from '@/store/hooks'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'
import { openModal } from '@/store/features/uiSlice/uiSlice'
import { getCookie } from '@/helpers/cookies'

export const useCreateModelDocumentationOperation = ({ model }: { model: Model }): OperationWithPermission<void> => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()
  const { uid } = getCookie()

  const canCreateModelDocumentation = model?.creator_user_id === uid

  const onClick = React.useCallback(() => {
    dispatch(
      openModal({
        template: ModalTemplate.MODEL_DOCUMENTATION,
        showCloseButton: true,
        modalProps: {
          title: t('modelDocumentation.title', { name: model?.name }),
          model,
          size: 'xl'
        }
      })
    )
  }, [model, t])

  return [onClick, canCreateModelDocumentation]
}
