import { PathSelector } from './interfaces'
import { NavigationItemTitle } from '../navigation/NavigationItemTitle'
import { useAppSelector } from '../../store/hooks'
import { FolderSelectorNavigationTree } from '../../components/modal/templates/moveProject/NavigationTreePathSelector'
import { useGetFolders } from '../../store/features/apis/slices/folder/helpers'
import { pathToRoute } from '../../helpers/router'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import { PagePath } from '../../constants'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { first, isEmpty, isNil, last } from 'lodash'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const PersonalSpace = ({ path }: { path: PathSelector }): React.ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useDashboardNavigate()
  const isSearchMode = useIsSearchMode()
  const { currentPath: selectedPath } = useCurrentPath()
  const selectedSpaceId = first(selectedPath)
  const isSelected = path.includes(selectedSpaceId) || selectedSpaceId === 'personal_space'
  const displayItems = !isSearchMode && isSelected

  const onSelected = (path: PathSelector): void => {
    if (!isNil(path)) {
      navigate(`/${pathToRoute(path)}`)
    }
  }

  return (
    <>
      <NavigationItemTitle
        spriteId="rectangle-history"
        label={t('myProjects')}
        onClick={(): void => navigate(PagePath.MY_PROJECTS)}
        isSelected={isSelected}
        tabIndex={TAB_INDEXES.PERSONAL_SPACE_SECTION}
      />
      {displayItems && <SubFolders path={path} selectedPath={selectedPath} onSelected={onSelected} />}
    </>
  )
}

const SubFolders = ({
  path,
  selectedPath,
  onSelected
}: {
  path: PathSelector
  selectedPath: PathSelector
  onSelected: (path: PathSelector) => void
}): React.ReactElement => {
  const fullPath = useAppSelector((state) => state.ui.fullPath)
  const { data: folders, isLoading } = useGetFolders({
    spaceId: first(path),
    folderId: last(path),
    disableSort: true
  })
  if (isEmpty(folders) || isLoading) {
    return null
  }

  return (
    <>
      {folders.map((folder) => (
        <FolderSelectorNavigationTree
          key={folder.folderId}
          label={folder.name}
          path={[...path, folder.folderId]}
          setSelectedPath={onSelected}
          selectedPath={selectedPath}
          expandedPath={fullPath}
        />
      ))}
    </>
  )
}
