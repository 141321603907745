import theme from '../../theme/defaultTheme'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import * as React from 'react'
import { styled } from '@mui/system'
import { ButtonProps, CircularProgress, Button as MuiButton } from '@mui/material'

type CustomButton = ButtonProps & {
  qaAttribute?: string
  isLoading?: boolean
}

const ButtonStyled = styled(MuiButton)({
  padding: `0 ${theme.spacing(3)}`,
  height: theme.spacing(8),
  minWidth: '88px',
  transition: '.3s color, .3s background, .3s border, .3s box-shadow',
  '&:hover:not(.Mui-disabled)': {
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.1)'
  },
  '&.MuiButton-outlinedSizeLarge, &.MuiButton-containedSizeLarge': {
    height: theme.spacing(10),
    padding: `0 ${theme.spacing(4)}`
  },
  '&:active:not(.Mui-disabled)': {
    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.4) inset'
  },
  '&.Mui-disabled': {
    color: theme.palette.grey['400']
  },
  'hover:&.Mui-disabled': {
    color: theme.palette.grey['400']
  },
  '&.MuiButton-outlined': {
    borderColor: theme.palette.primary['200'],
    color: theme.palette.primary.main
  },
  '&.MuiButton-outlined:hover:not(.Mui-disabled)': {
    backgroundColor: 'transparent'
  },
  '&.MuiButton-outlined.Mui-disabled': {
    borderColor: theme.palette.grey['100'],
    color: theme.palette.grey['400'],
    backgroundColor: 'transparent'
  },
  '&.MuiButton-outlined:active:not(.Mui-disabled)': {
    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1) inset'
  },
  '& .MuiButton-startIcon': {
    marginLeft: '0'
  }
})

export const Button = React.forwardRef(
  (
    { isLoading, children, qaAttribute, ...rest }: CustomButton,
    ref: React.Ref<HTMLButtonElement>
  ): React.ReactElement => {
    return (
      <ButtonStyled
        ref={ref}
        disableElevation
        qa-attribute={`${getQaSelectorText(qaAttribute ?? children)}--button`}
        {...rest}
      >
        {children}
        {isLoading && <CircularProgress size={20} sx={{ position: 'absolute' }} />}
      </ButtonStyled>
    )
  }
)
