import { UserAvatarPicture } from '../../containers/user/UserAvatarPicture'
import { AvatarImageSize } from '../avatar/Avatar'
import { ListItemAvatar, Stack, StackProps, Typography } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/system'
import { isNil } from 'lodash'

export const SelectedUserBoxStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  borderLeft: `${theme.spacing(1)} solid ${theme.palette.grey['400']}`,
  borderTop: `1px solid ${theme.palette.grey['200']}`,
  borderBottom: `1px solid ${theme.palette.grey['200']}`,
  padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(3)} ${theme.spacing(5)}`,
  marginBottom: theme.spacing(6),
  display: 'flex'
}))

interface UserProps extends StackProps {
  username?: string
  firstName?: string
  lastName?: string
  email?: string
  editProfile?: boolean
  avatarImageSize?: AvatarImageSize
}

export const User = ({
  username,
  firstName,
  lastName,
  email,
  editProfile,
  avatarImageSize,
  ...props
}: UserProps): React.ReactElement => {
  const showName = !isNil(firstName) || !isNil(lastName)
  const showEmail = !isNil(email)
  const emailVariant = showName ? 'subtitle' : 'text'
  const emailColor = showName ? 'grey.700' : 'text.primary'

  return (
    <Stack direction="row" gap={3} alignItems="center" {...props}>
      <ListItemAvatar sx={{ minWidth: 'auto' }}>
        <UserAvatarPicture userName={username} editProfile={editProfile} avatarImageSize={avatarImageSize} />
      </ListItemAvatar>
      {(showName || showEmail) && (
        <Stack
          direction="column"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          {showName && (
            <Typography variant="text" color="text.primary" component="span">
              {firstName} {lastName}
            </Typography>
          )}
          {showEmail && (
            <Typography variant={emailVariant} color={emailColor} component="span">
              {email}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  )
}
