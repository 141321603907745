import {
  Model,
  ModelStage,
  ModelVersion,
  PatchModelPayloadType,
  PostTrainModelPayloadType,
  SchemaType,
  TrainingPipeline,
  TrainingRun,
  TrainingRunEventLog,
  TrainingRunMetricHistoryItem
} from './interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { apiTags } from '../../apiTags'
import { AnalysisType } from '@/constants/propForAnalysisType'

const aiSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getModels: builder.query<Model[], void>({
      query: () => `api/v1/ai-model-manager/models/`,
      providesTags: [apiTags.USER_MODELS]
    }),
    getModel: builder.query<Model, { modelUuid: string }>({
      query: ({ modelUuid }) => `api/v1/ai-model-manager/models/${modelUuid}/?usage=1&documentation=1`,
      providesTags: (_result, _error, { modelUuid }) => [
        { type: apiTags.USER_MODEL_DETAILS, id: modelUuid },
        apiTags.USER_MODELS,
        apiTags.USER_MODEL
      ]
    }),
    patchModel: builder.mutation<Model, { modelUuid: string; payload: PatchModelPayloadType }>({
      query: ({ modelUuid, payload }) => ({
        url: `api/v1/ai-model-manager/models/${modelUuid}/`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: [apiTags.USER_MODELS],
      async onQueryStarted({ modelUuid, payload }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            aiSlice.util.updateQueryData('getModels', undefined, (draft) => {
              const model = draft.find((model) => model.uuid === modelUuid)
              if (model) {
                model.shared_with_organization = payload.shared_with_organization
                model.documentation = payload.documentation
              }
            })
          )
          dispatch(
            aiSlice.util.updateQueryData('getModel', { modelUuid }, (model) => {
              if (!model) return
              model.shared_with_organization = payload.shared_with_organization
              model.documentation = payload.documentation
            })
          )
        } catch {}
      }
    }),
    postModel: builder.mutation<{ model_uuid: string }, { name: string; description: string }>({
      query: ({ name, description }) => ({
        url: `api/v1/ai-model-manager/models/`,
        method: 'POST',
        body: {
          name,
          description,
          shared_with_organization: false
        }
      }),
      invalidatesTags: [apiTags.USER_MODELS, apiTags.USER_MODEL]
    }),
    getFilteredModelVersions: builder.query<
      ModelVersion[],
      { modelUuid: string; stages?: ModelStage[]; limit?: number }
    >({
      query: ({ modelUuid, stages }) => ({
        url: `api/v1/ai-model-manager/models/${modelUuid}/versions/filter`,
        params: stages?.length ? { stages: stages.join(',') } : undefined
      }),
      transformResponse: (response: ModelVersion[], _meta, { modelUuid, limit }) =>
        response.map((version) => ({ ...version, model_uuid: modelUuid })).slice(0, limit),
      providesTags: [apiTags.USER_MODEL_VERSIONS]
    }),
    getModelVersions: builder.query<ModelVersion[], { modelUuid: string }>({
      query: ({ modelUuid }) => `api/v1/ai-model-manager/models/${modelUuid}/versions/`,
      transformResponse: (response: ModelVersion[], _meta, { modelUuid }) =>
        response.map((version) => ({ ...version, model_uuid: modelUuid })),
      providesTags: [apiTags.USER_MODEL_VERSIONS]
    }),
    getModelVersion: builder.query<ModelVersion, { modelUuid: string; version: string }>({
      query: ({ modelUuid, version }) => `api/v1/ai-model-manager/models/${modelUuid}/versions/${version}/`,
      providesTags: (_result, _error, { modelUuid, version }) => [
        { type: apiTags.USER_MODEL_VERSION, id: `${modelUuid} ${version}` },
        apiTags.USER_MODEL_VERSIONS
      ]
    }),
    deleteModelVersion: builder.mutation<void, { modelUuid: string; version: string }>({
      query: ({ modelUuid, version }) => ({
        url: `api/v1/ai-model-manager/models/${modelUuid}/versions/${version}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { modelUuid, version }) => [
        { type: apiTags.USER_MODEL_VERSION, id: `${modelUuid} ${version}` },
        apiTags.USER_MODEL_VERSIONS
      ]
    }),
    patchModelVersionStage: builder.mutation<ModelVersion, { modelUuid: string; version: string; stage: ModelStage }>({
      query: ({ modelUuid, version, stage }) => ({
        url: `api/v1/ai-model-manager/models/${modelUuid}/versions/${version}/stage`,
        method: 'PATCH',
        body: { stage }
      }),
      invalidatesTags: (_result, _error, { modelUuid, version }) => [
        { type: apiTags.USER_MODEL_VERSION, id: `${modelUuid} ${version}` },
        apiTags.USER_MODEL_VERSIONS,
        apiTags.USER_MODEL_DETAILS
      ]
    }),
    patchModelVersionDescription: builder.mutation<
      ModelVersion,
      { modelUuid: string; version: string; description: string }
    >({
      query: ({ modelUuid, version, description }) => ({
        url: `api/v1/ai-model-manager/models/${modelUuid}/versions/${version}/description`,
        method: 'PATCH',
        body: { description }
      }),
      invalidatesTags: (_result, _error, { modelUuid, version }) => [
        { type: apiTags.USER_MODEL_VERSION, id: `${modelUuid} ${version}` },
        apiTags.USER_MODEL_VERSIONS,
        apiTags.USER_MODEL_DETAILS
      ]
    }),
    getTrainingPipelines: builder.query<TrainingPipeline[], { modelUuid: string }>({
      query: ({ modelUuid }) => `api/v1/ai-model-manager/models/${modelUuid}/training-pipelines/`,
      providesTags: (_result, _error, { modelUuid }) => [{ type: apiTags.TRAINING_PIPELINES, id: modelUuid }]
    }),
    getTrainingPipeline: builder.query<TrainingPipeline, { modelUuid: string; trainingUuid: string }>({
      query: ({ modelUuid, trainingUuid }) =>
        `api/v1/ai-model-manager/models/${modelUuid}/training-pipelines/${trainingUuid}`,
      providesTags: (_result, _error, { modelUuid, trainingUuid }) => [
        { type: apiTags.TRAINING_PIPELINES, id: modelUuid },
        { type: apiTags.TRAINING_PIPELINE, id: trainingUuid }
      ]
    }),
    getTrainingRun: builder.query<TrainingRun, { trainingRunId: string }>({
      query: ({ trainingRunId }) => `api/v1/ai-model-manager/training-run/${trainingRunId}`,
      providesTags: (_result, _error, { trainingRunId }) => [{ type: apiTags.TRAINING_RUN, id: trainingRunId }]
    }),
    getTrainingRunEventsLog: builder.query<TrainingRunEventLog[], { trainingRunId: string }>({
      query: ({ trainingRunId }) => `api/v1/ai-model-manager/training-run/${trainingRunId}/events_log`,
      providesTags: (_result, _error, { trainingRunId }) => [
        { type: apiTags.TRAINING_RUN_EVENTS_LOG, id: trainingRunId }
      ]
    }),
    getTrainingRunMetrics: builder.query<TrainingRunMetricHistoryItem[], { trainingRunId: string; metric: string }>({
      // Note: metric needs to be double encoded because of the way the backend handles the metric name
      query: ({ trainingRunId, metric }) =>
        `api/v1/ai-model-manager/training-run/${trainingRunId}/metrics/${encodeURIComponent(
          encodeURIComponent(metric)
        )}`,
      providesTags: (_result, _error, { trainingRunId }) => [{ type: apiTags.TRAINING_RUN_METRICS, id: trainingRunId }]
    }),
    getAnalysisTemplateMap: builder.query<AnalysisType, void>({
      query: () => `api/v1/ai-model-manager/analysis-template-map/`
    }),
    getTrainingUserOptionsSchema: builder.query<SchemaType, { modelUuid: string }>({
      query: ({ modelUuid }) => `/api/v1/ai-model-manager/models/${modelUuid}/train/user-options-schema`
    }),
    postTrainingModel: builder.mutation<
      { training_uuid: string },
      { modelUuid: string; payload: PostTrainModelPayloadType }
    >({
      query: ({ modelUuid, payload }) => ({
        url: `/api/v1/ai-model-manager/models/${modelUuid}/train`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: (_result, _error, { modelUuid }) => [{ type: apiTags.TRAINING_PIPELINES, id: modelUuid }]
    }),
    postImage: builder.mutation<{ url: string }, { modelUuid: string; image: File }>({
      query: ({ modelUuid, image }) => {
        const formData = new FormData()
        formData.append('image', image)
        return {
          url: `/api/v1/ai-model-manager/models/${modelUuid}/assets/`,
          method: 'POST',
          body: formData
        }
      }
    })
  })
})

export const {
  useGetModelQuery,
  useGetModelsQuery,
  usePatchModelMutation,
  useGetAnalysisTemplateMapQuery,
  usePostModelMutation,
  usePostTrainingModelMutation,
  useGetFilteredModelVersionsQuery,
  useGetTrainingRunQuery,
  usePatchModelVersionStageMutation,
  usePatchModelVersionDescriptionMutation,
  useDeleteModelVersionMutation,
  useGetTrainingRunEventsLogQuery,
  useGetTrainingRunMetricsQuery,
  useGetTrainingPipelineQuery,
  useGetTrainingPipelinesQuery,
  useGetTrainingUserOptionsSchemaQuery,
  usePostImageMutation
} = aiSlice
