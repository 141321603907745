import { useGetFolderDetailsQuery } from './folderSlice'
import { useGetFoldersFromFolderQuery, useGetFoldersFromSpaceQuery } from './foldersSlice'
import { Folder, FolderResult, GetFoldersProps, GetSpacesProps } from './interfaces'
import { useAppSelector } from '../../../../hooks'
import { Space } from '../space/interfaces'
import { useGetSpaceDetailsQuery } from '../space/spaceSlice'
import { isEmpty, isNil } from 'lodash'

const FOLDER_PAGE_SIZE: number = 20
const validIdRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

export const useGetFolders = (
  { spaceId, folderId, disableSort }: GetFoldersProps,
  options?: { skip?: boolean; origin?: string }
): FolderResult<Folder[]> => {
  const { sortingModeV2, sortingDirection } = useAppSelector((state) => state.ui)

  const dataSpace = useGetFoldersFromSpaceQuery(
    {
      spaceId,
      sortingModeV2: !disableSort ? sortingModeV2 : undefined,
      sortingDirection: !disableSort ? sortingDirection : undefined
    },
    { ...options, skip: options?.skip || isNil(spaceId) || (!isNil(folderId) && spaceId !== folderId) }
  )

  const dataFolder = useGetFoldersFromFolderQuery(
    {
      spaceId,
      folderId,
      sortingModeV2: !disableSort ? sortingModeV2 : undefined,
      sortingDirection: !disableSort ? sortingDirection : undefined
    },
    { ...options, skip: options?.skip || isNil(spaceId) || isNil(folderId) || spaceId === folderId }
  )

  if (!isNil(dataSpace) && dataSpace?.isSuccess) {
    return { ...dataSpace, isLoading: dataSpace.isLoading || dataSpace.isFetching }
  }

  if (!isNil(dataFolder) && dataFolder?.isSuccess) {
    return { ...dataFolder, isLoading: dataFolder.isLoading || dataFolder.isFetching }
  }

  return { data: [], error: null, isLoading: false }
}

export const useGetFolderDetails = (
  { spaceId, folderId }: GetFoldersProps,
  options?: { skip?: boolean; origin?: string }
): FolderResult<Folder> => {
  const skip =
    options?.skip ||
    isNil(folderId) ||
    spaceId === folderId ||
    !validIdRegex.test(spaceId) ||
    !validIdRegex.test(folderId)

  const { data, error, isLoading, isFetching, ...props } = useGetFolderDetailsQuery({ spaceId, folderId }, { skip })

  return { data, error, isLoading: isLoading || isFetching, ...props }
}

export const useGetSpaceDetails = ({ spaceId }: GetSpacesProps, options?: { skip: boolean }): FolderResult<Space> => {
  const skipQuery = options?.skip || isNil(spaceId) || !validIdRegex.test(spaceId)

  const { data, error, isLoading, isFetching, ...props } = useGetSpaceDetailsQuery({ spaceId }, { skip: skipQuery })

  // If the user navigates from a space to another not-space path, we need to trigger a dispatch
  if (skipQuery) {
    return { data: undefined, isLoading: false, error: undefined }
  }

  return { data, error, isLoading: isLoading || isFetching, ...props }
}

export const getPaginatedFolderData = (foldersData: Folder[], page: number): Folder[] => {
  const fromIndex = (page - 1) * FOLDER_PAGE_SIZE
  const toIndex = page * FOLDER_PAGE_SIZE
  return foldersData?.slice(fromIndex, toIndex) ?? []
}

export const getNumberOfPages = (folders: Folder[]): number => {
  if (isEmpty(folders)) {
    return 0
  }
  return Math.ceil(folders.length / FOLDER_PAGE_SIZE)
}
