import { PeriodType } from './interfaces'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import addYears from 'date-fns/addYears'

export const getSharingWithSupportExpirationDate = (periodType: PeriodType): Date => {
  switch (periodType) {
    case PeriodType.oneYearDuration:
      return addYears(new Date(), 1)
    case PeriodType.sixMonthsDuration:
      return addMonths(new Date(), 6)
    case PeriodType.threeMonthsDuration:
      return addMonths(new Date(), 3)
    case PeriodType.oneMonthDuration:
      return addMonths(new Date(), 1)
    case PeriodType.twoWeeksDuration:
      return addWeeks(new Date(), 2)
    case PeriodType.oneWeekDuration:
      return addWeeks(new Date(), 1)
    case PeriodType.unlimited:
    default:
      return null
  }
}

export const isPeriodType = (value: any): value is PeriodType => {
  return value in PeriodType
}
