export const ZINDEXES = {
  FULLSCREEN_VIEWER_LAYER: 6,
  HEADER_LAYER: 5,
  CONTENT_LAYER: 0,
  MOBILE_MENU_LAYER: 1,
  VIEWER_LAYER: 0,
  VIEWER_LOADING_LAYER: 2,
  BANNER: 1,
  SUBHEADER_LAYER: 1,
  BEHIND_EVERYTHING: -1,
  MODAL_LAYER: 1300 // MUI default is 1300
}
