import { FormRow } from './FormRow'
import { MenuItem, Select, SelectProps } from '@mui/material'
import * as React from 'react'
import { isNil, isObject } from 'lodash'
import { getQaSelectorText } from '@/helpers/qaSelectors'
import { SpriteIcon } from '@/assets/SpriteIcon'

export interface ModalInputSelectProps extends SelectProps {
  label?: string | React.ReactElement
  placeholder?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  required?: boolean
  disabled?: boolean
  options: { value: string; label?: string; component?: React.ReactElement }[]
  qaSelectorText?: string
}

const ArrowIcon = (): React.ReactElement => <SpriteIcon width={11} height={11} spriteId="angle-down" />

export const ModalInputSelect = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  required,
  disabled,
  options,
  qaSelectorText = getQaSelectorText(label),
  ...props
}: ModalInputSelectProps): React.ReactElement => {
  let fontSize: string = undefined
  if (isObject(props.sx) && !Array.isArray(props.sx)) {
    fontSize = (props.sx as Record<string, any>)?.fontSize
  }

  return (
    <FormRow label={label} required={required} helperText={helperText} error={error} fontSize={fontSize}>
      <Select
        value={value}
        onChange={onChange}
        variant="standard"
        IconComponent={ArrowIcon}
        placeholder={placeholder}
        qa-attribute={`${qaSelectorText}--select`}
        disabled={disabled}
        error={error}
        {...props}
      >
        {options.map(({ value, label, component }) =>
          isNil(component) ? (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ) : (
            component
          )
        )}
      </Select>
    </FormRow>
  )
}
