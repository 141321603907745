import { OrganizationSharePermission } from './OrganizationSharePermission'
import { OrganizationNameAndIcon } from './OrganizationNameAndIcon'
import * as React from 'react'
import { MenuItem } from '@mui/material'
import { ModalInputSelect } from '@/components/modal/inputComponents/ModalInputSelect'
import { NonProfessionalLicenseAlert } from '@/components/modal/templates/project/PrivacySelectorAlerts'
import { PermissionType } from '@/components/modal/templates/share/interfaces'
import { useOrganizationName } from '@/hooks/newWorld'

type SharingPermissionsType = {
  sharingPermission: PermissionType
  handleSharingPermissionChange: (sharingPermission: PermissionType) => void
  isProfessionalOrganization: boolean
}

export const SpaceOrganizationPermission = ({
  sharingPermission,
  handleSharingPermissionChange,
  isProfessionalOrganization
}: SharingPermissionsType): React.ReactElement => {
  const [isClicked, setIsClicked] = React.useState(false)
  const { data: organizationName } = useOrganizationName()

  const options = React.useMemo(() => {
    return Object.values(PermissionType)
      .reverse()
      .map((permission) => {
        if (permission === PermissionType.NO_ACCESS) {
          return
        }
        return {
          value: permission,
          component: (
            <MenuItem key={permission} value={permission} sx={{ height: '54px' }}>
              <OrganizationSharePermission permission={permission as PermissionType} />
            </MenuItem>
          )
        }
      })
      .filter(Boolean)
  }, [])

  return (
    <React.Fragment>
      <ModalInputSelect
        value={sharingPermission}
        label={<OrganizationNameAndIcon organizationName={organizationName} />}
        onChange={(event): void => handleSharingPermissionChange(event.target.value as PermissionType)}
        options={options}
        renderValue={(permission: PermissionType): React.ReactElement => (
          <OrganizationSharePermission permission={permission} hideDescription />
        )}
        onClick={(): void => setIsClicked(true)}
        sx={{ maxWidth: 210 }}
        qaSelectorText="space-organization-permission"
      />
      {!isProfessionalOrganization && isClicked && <NonProfessionalLicenseAlert nonProfessionalSharingWarning />}
    </React.Fragment>
  )
}
