import { useGetOrganizationId } from '../../../store/features/apis/slices/organization/hooks'
import {
  OrganizationV2User,
  QuotaBudget,
  UserQuotaBudgets
} from '../../../store/features/apis/slices/organization/interfaces'
import {
  useGetOrganizationV2QuotaBudgetsQuery,
  useGetOrganizationV2UsersQuery
} from '../../../store/features/apis/slices/organization/organizationSlice'
import { UserV2 } from '../../../store/features/apis/slices/user/interfaces'
import { useGetUsersV2Query } from '../../../store/features/apis/slices/user/userSlice'
import { isNil } from 'lodash'
import { BudgetType } from '@/store/features/apis/slices/organization/interfaces'

export const useFetchUserData = (): {
  isLoading: boolean
  users: UserV2[]
  organizationUsers: OrganizationV2User[]
  budgets: UserQuotaBudgets[]
} => {
  const organizationId = useGetOrganizationId()

  const users = useGetUsersV2Query({ organizationId }, { skip: isNil(organizationId) })
  const organizationUsers = useGetOrganizationV2UsersQuery({ organizationId }, { skip: isNil(organizationId) })
  const quotaBudgets = useGetOrganizationV2QuotaBudgetsQuery({ organizationId }, { skip: isNil(organizationId) })

  const isLoading = users.isLoading || organizationUsers.isLoading || quotaBudgets.isLoading
  const isFetching = users.isFetching || organizationUsers.isFetching || quotaBudgets.isFetching

  return {
    isLoading: isLoading || isFetching,
    users: users.data ?? [],
    organizationUsers: organizationUsers.data ?? [],
    budgets: quotaBudgets?.data?.budgets ?? []
  }
}

export const getSortingUserQuotaBudget = (quotaBudget: QuotaBudget): number => {
  let totalQuota = 0
  if (isNil(quotaBudget)) {
    return
  }
  if (quotaBudget.budgetType === BudgetType.UNLIMITED) {
    return Infinity
  }
  if (!isNil(quotaBudget.quantity)) {
    totalQuota += Number(quotaBudget.quantity)
  }
  if (!isNil(quotaBudget.quantity)) {
    totalQuota += Number(quotaBudget.quantity)
  }
  return totalQuota
}
