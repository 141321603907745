import * as React from 'react'
import { Avatar, ListItemAvatar, Stack, Typography } from '@mui/material'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { StyledTooltip } from '@/components/tooltip/StyledTooltip'

export const OrganizationNameAndIcon = ({
  organizationName,
  disabled
}: {
  organizationName: string
  disabled?: boolean
}): React.ReactElement => {
  return (
    <Stack direction="row" gap={3} alignItems="center">
      <ListItemAvatar sx={{ minWidth: 'auto' }}>
        <Avatar
          sx={{ backgroundColor: 'primary.100', borderColor: 'primary.300', borderStyle: 'solid', borderWidth: 1 }}
        >
          <SpriteIcon spriteId="building" color={'grey.600'} fontSize={15} />
        </Avatar>
      </ListItemAvatar>
      <StyledTooltip title={organizationName} enterDelay={500}>
        <Stack direction="column" sx={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography variant="text" color={disabled ? 'grey.400' : 'text.primary'}>
            {organizationName}
          </Typography>
        </Stack>
      </StyledTooltip>
    </Stack>
  )
}
