import { getHumanReadableText } from '../helpers'
import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ModelPreviewImage, ModelPreviewImageSkeleton } from '@/components/aiModels/previewImage/ModelPreviewImage'
import { Model } from '@/store/features/apis/slices/ai/interfaces'
import { Button } from '@/components/button/Button'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import { useCanCreateNewModelVersion } from '@/operations/ai/models/versions/useCanCreateNewModelVersion'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { StyledTooltip } from '@/components/tooltip/StyledTooltip'
import { useCreateModelDocumentationOperation } from '@/operations/ai/models/createModelDocumentationOperation'

type ModelDetailsProps = {
  model: Model
  isLoading?: boolean
  isFetching?: boolean
}

export const ModelDetailsSkeleton = (): React.ReactElement => {
  return (
    <Stack direction="row" spacing={5}>
      <Box>
        <ModelPreviewImageSkeleton width={435} height={315} />
      </Box>
      <Box flexGrow={1}>
        <Skeleton variant="rounded" width="100%" height={34.8} animation="wave" />
        <Skeleton variant="rounded" width={150} height={17} animation="wave" sx={{ marginTop: 0.625 }} />
        <Skeleton variant="rounded" width="100%" height={100} animation="wave" sx={{ marginTop: 2.5 }} />

        <Stack direction="row" spacing={2.5} mt={3}>
          <Skeleton variant="rounded" width={120} height={40} animation="wave" />
          <Skeleton variant="rounded" width={180} height={40} animation="wave" />
        </Stack>
      </Box>
    </Stack>
  )
}

export const ModelDetails = ({ model, isLoading, isFetching }: ModelDetailsProps): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])
  const { navigate } = useDashboardNavigate()
  const [createModelDocumentation, canCreateModelDocumentation] = useCreateModelDocumentationOperation({ model })

  const canCreateNewVersion = useCanCreateNewModelVersion(model?.uuid)
  const isDocumentationAvailable = !isNil(model?.documentation) && !isEmpty(model?.documentation)

  const handleNewModelVersionClick = React.useCallback(() => {
    navigate(`/models/${model.uuid}/create-version?page=data-selection`)
    close()
  }, [model?.uuid, navigate])

  const handleCreateDocumentationClick = React.useCallback(() => {
    createModelDocumentation()
  }, [createModelDocumentation])

  if (isLoading || isNil(model)) {
    return <ModelDetailsSkeleton />
  }

  return (
    <Stack direction="row" spacing={5}>
      <Box>
        <ModelPreviewImage url={model.thumbnail} />
      </Box>
      <Box flexGrow={1}>
        <Typography variant="h1" qa-attribute="aiModel--title">
          {model.name}
        </Typography>
        <Typography display="block" variant="textV2" color="grey.700" mt={0.625} qa-attribute="aiModel--type">
          {t(`analysisTypes.${model.analysis_type}`, getHumanReadableText(model.analysis_type))}
        </Typography>
        <Typography display="block" variant="textV2" mt={2.5} qa-attribute="aiModel--description">
          {model.description}
        </Typography>

        <Stack direction="row" spacing={2.5} mt={3}>
          {canCreateNewVersion && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              startIcon={<SpriteIcon spriteId="plus" height={'.8125rem'} width={'.8125rem'} />}
              onClick={handleNewModelVersionClick}
            >
              {t('modelDetails.actions.newVersion')}
            </Button>
          )}

          {canCreateModelDocumentation && (
            <StyledTooltip
              title={
                isFetching ? t('modelDetails.tooltips.updatingDocumentation') : t('modelDetails.tooltips.documentation')
              }
              placement="bottom"
            >
              <Button
                variant="outlined"
                size="large"
                color="primary"
                startIcon={
                  <SpriteIcon
                    spriteId={!isDocumentationAvailable ? 'plus' : 'pencil'}
                    height={'.8125rem'}
                    width={'.8125rem'}
                  />
                }
                onClick={handleCreateDocumentationClick}
                disabled={isFetching}
              >
                {!isDocumentationAvailable
                  ? t('modelDetails.actions.createDocumentation')
                  : t('modelDetails.actions.editDocumentation')}
              </Button>
            </StyledTooltip>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}
