import { useSortSpaces } from './hooks'
import { useMultiSelect } from '../../../hooks/useMultiSelect'
import { ViewMode } from '../../../store/features/uiSlice/interfaces'
import { SpaceCard } from '../../../components/spaces/SpaceCard'
import { getViewingMode } from '../../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../../store/hooks'
import { SpaceRow } from '../../../components/spaces/SpaceRow'
import { Space } from '../../../store/features/apis/slices/space/interfaces'
import * as React from 'react'
import Grid from '@mui/material/Grid'

export const SpaceDataGridComponents = ({ spaces }: { spaces: Space[] }): React.ReactElement => {
  const { isSelectedItem, handleMultiSelection } = useMultiSelect()
  const displayType = useAppSelector(getViewingMode)
  const sortedSpaces = useSortSpaces({ spaces })

  const Component = displayType === ViewMode.GRID ? SpaceCard : SpaceRow

  return (
    <>
      {sortedSpaces?.map((space) => (
        <Grid key={space.spaceId}>
          <Component
            {...space}
            selected={isSelectedItem(space.spaceId, 'space')}
            onClick={(e, spaceId): void => {
              handleMultiSelection(e, spaceId, 'space')
            }}
          />
        </Grid>
      ))}
    </>
  )
}
