import { getQaSelectorText } from '../../helpers/qaSelectors'
import { MenuItem, Typography } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/system'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { isInsideCypress } from '@/helpers/cookies'

const NavigationItemExternalStyled = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
  height: theme.spacing(9),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(3),
  userSelect: 'none',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.grey['100'],
    border: `1px solid ${theme.palette.grey['200']}`,
    textDecoration: 'none'
  },
  ':active,:visited,:focus': {
    textDecoration: 'none'
  },
  border: '1px solid transparent',
  backgroundColor: 'transparent',
  transition: '.3s background, .3s border, .3s color'
}))

type NavigationItemExternalProps = {
  label: string
  icon: React.ReactElement
  href: string
}

export const NavigationItemExternal = ({ icon, label, href }: NavigationItemExternalProps): React.ReactElement => {
  const handleOnClick = React.useCallback(() => {
    window.open(href, `${isInsideCypress() ? '_self' : '_blank'}`, 'noopener, noreferrer')
  }, [href])

  return (
    <NavigationItemExternalStyled
      qa-attribute={`${getQaSelectorText(label)}--navigation-item-external`}
      onClick={handleOnClick}
    >
      <Typography
        sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: 2.5 }}
        variant="textBoldWithEllipsis"
        textTransform="uppercase"
        color="secondary"
      >
        {icon}
        {label}
        <SpriteIcon
          spriteId="arrow-up-right-from-square"
          width={13}
          height={13}
          color="grey.550"
          style={{ marginTop: -2 }}
        />
      </Typography>
    </NavigationItemExternalStyled>
  )
}
