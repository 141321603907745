import { ZINDEXES } from './constants/zindexes'

export default {
  '.svg-inline--fa ': {
    verticalAlign: 'middle'
  },
  'div.mdxeditor-toolbar': {
    justifyContent: 'center',
    backgroundColor: 'white',
    border: 'none',
    borderTop: '1px solid #E5E5E5',
    borderBottom: '1px solid #E5E5E5',
    borderRadius: 0
  },
  'div.mdxeditor-root-contenteditable > div > div': {
    minHeight: '60vh'
  },
  'div.mdxeditor-root-contenteditable > div > div img': {
    display: 'inline-block',
    lineHeight: 3,
    textAlign: 'center'
  },
  'div.mdxeditor-root-contenteditable > div > div img::before': {
    content: '""',
    width: '100%',
    height: '100%',
    backgroundColor: '#EEE',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)'
  },
  'div.mdxeditor-root-contenteditable > div > div img::after': {
    content: '"Missing image:" " " attr(alt)',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'rgb(100, 100, 100)',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 1.2,
    wordBreak: 'auto-phrase',
    width: '100%'
  },
  'div.mdxeditor-popup-container': {
    zIndex: ZINDEXES.MODAL_LAYER + 1
  }
}
