import { AvatarStyled, MenuUserSection, UserMenuStyled, UserNavigationMenuItemStyled } from './styledComponents'
import { getUrl } from '../../helpers/navigation'
import { PRICING_URL } from '../../constants'
import { useGetAvatarImageUrlQuery } from '../../store/features/apis/slices/forum/forumSlice'
import { useAttributes, useOrganizationName } from '../../hooks/newWorld'
import { useIsMobile } from '../../hooks/useIsMobile'
import { CustomChip } from '../../components/chips/CustomChip'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import { useUserNames } from '../../hooks/useUserNames'
import { ReactElement, useCallback } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { isNil } from 'lodash'
import Typography from '@mui/material/Typography'
import Stack from '@mui/system/Stack'
import { Menu } from '@mui/material'
import { getUserPlanChipColor, getUserPlanLabelKey } from '@/hooks/planHooks'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

const UserPlan = (): React.ReactElement => {
  const { t } = useTranslation('header')
  const { data: attributes } = useAttributes()
  const userPlanLabelKey = getUserPlanLabelKey(attributes)

  return (
    <CustomChip
      label={t(userPlanLabelKey).toUpperCase()}
      size="small"
      sx={{
        fontSize: '0.6rem',
        fontWeight: 700
      }}
      variant="filled"
      color={getUserPlanChipColor(attributes)}
    />
  )
}

export const UserMenu = (): ReactElement => {
  const { navigate } = useDashboardNavigate()
  const isMobile = useIsMobile()
  const { username, userShortName, userFullName } = useUserNames()
  const { data: avatarUrl } = useGetAvatarImageUrlQuery({ userName: username, size: 66 })
  const { data: organizationName } = useOrganizationName()
  const { t } = useTranslation('header')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (!isMobile) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl((prevValue) => (isNil(prevValue) ? event.currentTarget : null))
  }

  const handleOnClose = (): void => {
    setAnchorEl(null)
  }

  const openOsanoCookiePreferencesSlideIn = useCallback((): void => {
    window?.Osano?.cm?.showDrawer()
  }, [])

  const openUserPreferences = useCallback((): void => {
    navigate('/profile')
  }, [navigate])

  return (
    <UserMenuStyled
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnClose}
      onKeyDown={handleOnKeyDown}
      qa-attribute="qa-userMenuButton"
      isHovered={isOpen}
      tabIndex={TAB_INDEXES.USER_MENU}
      onClick={handleOnClick}
    >
      <Stack direction="row" gap={1.5} alignItems="center" height="100%">
        <Box>{userShortName}</Box>
        <AvatarStyled src={avatarUrl} alt={userFullName} />
        <SpriteIcon spriteId="angle-right" height={12} width={12} rotatingAngle={isOpen ? 270 : 90} />
      </Stack>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        disableAutoFocusItem
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        qa-attribute="qa-userMenu"
        slotProps={{
          root: {
            style: {
              zIndex: 2
            }
          },
          paper: {
            style: { zIndex: 1300 }
          }
        }}
      >
        <MenuUserSection
          onClick={openUserPreferences}
          disabled
          sx={{
            '&.Mui-disabled': {
              opacity: 'unset'
            }
          }}
        >
          <Stack gap={4} width="100%">
            <Stack alignItems="center" paddingX={4}>
              <AvatarStyled src={avatarUrl} alt={userFullName} sx={{ marginBottom: 1 }} />
              <Typography
                variant="textBold"
                color="text.primary"
                maxWidth={150}
                textAlign="center"
                sx={{
                  wordBreak: 'break-word',
                  whiteSpace: 'normal'
                }}
              >
                {userFullName}
              </Typography>
              {!isNil(organizationName) && (
                <Typography
                  variant="textBold"
                  color="grey.700"
                  fontSize={'0.6875rem'}
                  marginTop={1}
                  textAlign="center"
                  maxWidth={150}
                >
                  {organizationName}
                </Typography>
              )}
            </Stack>
            <Divider textAlign="left">
              <UserPlan />
            </Divider>
          </Stack>
        </MenuUserSection>

        <UserNavigationMenuItemStyled onClick={openUserPreferences} fakeLink>
          <SpriteIcon spriteId="user" width={15} height={12} />
          {t('userPreferences')}
        </UserNavigationMenuItemStyled>
        <UserNavigationMenuItemStyled>
          <Link href={PRICING_URL}>
            <SpriteIcon spriteId="arrow-circle-up" width={15} height={12} />
            {t('upgradePlan')}
          </Link>
        </UserNavigationMenuItemStyled>
        <UserNavigationMenuItemStyled onClick={openOsanoCookiePreferencesSlideIn} fakeLink>
          <SpriteIcon spriteId="cookie-bite" width={15} height={12} />
          {t('cookiePreferences')}
        </UserNavigationMenuItemStyled>
        <Divider />
        <UserNavigationMenuItemStyled>
          <Link href={`${getUrl('/signin/')}?action=logout`}>
            <SpriteIcon spriteId="arrow-up-right-from-square" width={15} height={12} />
            {t('logOut')}
          </Link>
        </UserNavigationMenuItemStyled>
      </Menu>
    </UserMenuStyled>
  )
}
