import * as React from 'react'
import { isEmpty, isNil } from 'lodash'
import { useGetProjectSimulationsQuery } from '@/store/features/apis/slices/project/projectSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useGetSimulationSpecIdsAnalysisTypesQuery } from '@/store/features/apis/slices/spec/specSlice'
import { RunningStatus, SimulationRun } from '@/store/features/apis/slices/project/interfaces'
import { useGetAnalysisTemplateMapQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { AnalysisType } from '@/constants/propForAnalysisType'

type SuccessfulRunSimulationSpecIdsType = {
  specId: string
  numberOfSuccessfulRuns: number
}

export type AiAvailableAnalysisWithNumberOfRunsType = {
  type: AnalysisType
  numberOfSuccessfulRuns: number
}

type SupportedAnalysisTypes = {
  data: AiAvailableAnalysisWithNumberOfRunsType[]
  isLoading: boolean
}

export const useGetSupportedAnalysisTypes = (projectId: string): SupportedAnalysisTypes => {
  const { handleApiHookErrors } = useErrorHandler()
  const [successfulRunSimulationSpecIds, setSuccessfulRunSimulationSpecIds] = React.useState<
    SuccessfulRunSimulationSpecIdsType[]
  >([])
  const [availableSuccessfulRunSimulations, setAvailableSuccessfulRunSimulations] = React.useState<
    AiAvailableAnalysisWithNumberOfRunsType[]
  >([])
  const [aiAvailableAnalysisTypes, setAiAvailableAnalysisTypes] = React.useState<
    AiAvailableAnalysisWithNumberOfRunsType[]
  >([])

  const {
    data: simulations,
    isLoading: isLoadingSimulations,
    isError: isErrorSimulations,
    error: errorSimulations
  } = useGetProjectSimulationsQuery({ projectId }, { skip: isNil(projectId) })

  const {
    data: simulationSpecIdsData,
    isLoading: isLoadingSimulationSpecIdsData,
    isError: isErrorSimulationSpecIdsData,
    error: errorSimulationSpecIdsData
  } = useGetSimulationSpecIdsAnalysisTypesQuery(
    { simulationSpecIds: successfulRunSimulationSpecIds.map((run) => run.specId) },
    { skip: isEmpty(successfulRunSimulationSpecIds) }
  )

  const {
    data: analysisTemplateMap,
    isLoading: isLoadingAnalysisTemplateMap,
    isError: isErrorAnalysisTemplateMap,
    error: errorAnalysisTemplateMap
  } = useGetAnalysisTemplateMapQuery()

  React.useEffect(() => {
    if (isErrorSimulations) {
      handleApiHookErrors(errorSimulations)
    }
  }, [isErrorSimulations, errorSimulations])

  React.useEffect(() => {
    if (isErrorSimulationSpecIdsData) {
      handleApiHookErrors(errorSimulationSpecIdsData)
    }
  }, [isErrorSimulationSpecIdsData, errorSimulationSpecIdsData])

  React.useEffect(() => {
    if (isErrorAnalysisTemplateMap) {
      handleApiHookErrors(errorAnalysisTemplateMap)
    }
  }, [isErrorAnalysisTemplateMap, errorAnalysisTemplateMap])

  const countSuccessfulRuns = (runs: SimulationRun[]): number => {
    if (isNil(runs) || isEmpty(runs)) {
      return 0
    }
    return runs.reduce((count, run) => {
      const currentCount = run.runningStatus === RunningStatus.FINISHED ? 1 : 0
      const subRunsCount = run.subRuns ? countSuccessfulRuns(run.subRuns) : 0
      return count + currentCount + subRunsCount
    }, 0)
  }

  React.useEffect(() => {
    if (isNil(simulations)) {
      return
    }
    simulations.forEach((simulation) => {
      const numberOfSuccessfulRuns = countSuccessfulRuns(simulation.runs)
      if (numberOfSuccessfulRuns > 0) {
        setSuccessfulRunSimulationSpecIds((prevValue) => [
          ...prevValue,
          { specId: simulation.specId, numberOfSuccessfulRuns }
        ])
      }
    })
  }, [simulations])

  React.useEffect(() => {
    if (isNil(simulationSpecIdsData)) {
      return
    }
    const analysisTypes = successfulRunSimulationSpecIds.reduce((acc, simulationData) => {
      const spec = simulationSpecIdsData.find((spec) => spec.id === simulationData.specId)
      const type = spec?.analysis?.type
      const existingEntry = acc.find((entry) => entry.type === type)

      if (existingEntry) {
        existingEntry.numberOfSuccessfulRuns += simulationData.numberOfSuccessfulRuns
      } else {
        acc.push({ type, numberOfSuccessfulRuns: simulationData.numberOfSuccessfulRuns })
      }
      return acc
    }, [])
    setAvailableSuccessfulRunSimulations(analysisTypes)
  }, [successfulRunSimulationSpecIds, simulationSpecIdsData])

  React.useEffect(() => {
    if (!isNil(analysisTemplateMap) && !isEmpty(availableSuccessfulRunSimulations)) {
      const supportedTypes = Object.keys(analysisTemplateMap)
      const availableTypes = availableSuccessfulRunSimulations.filter((analysisType) =>
        supportedTypes.includes(analysisType.type)
      )
      setAiAvailableAnalysisTypes((prevValues) => [...prevValues, ...availableTypes])
    }
  }, [availableSuccessfulRunSimulations, analysisTemplateMap])

  return {
    data: aiAvailableAnalysisTypes,
    isLoading: isLoadingSimulations || isLoadingSimulationSpecIdsData || isLoadingAnalysisTemplateMap
  }
}
