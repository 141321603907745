import { useAppDispatch } from './../store/hooks'
import { useDashboardNavigate } from './useDashboardNavigate'
import { addSnackbarToState } from '../store/features/uiSlice/uiSlice'
import { ResponseData, ResponseDataItem, post } from '../helpers/network'
import { PagePath } from '../constants'
import { isEmpty, isNil } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { TOptions } from 'i18next'

export interface ValidationError {
  valid: boolean
  message: string
}

export interface ValidationErrors {
  [key: string]: ValidationError
}

interface SerializedError {
  name?: string
  message?: string
  stack?: string
  code?: string
}

type UseErrorHandlerReturnType = {
  validationErrors: ValidationErrors
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
  isValidating: boolean
  handleValidationErrors: <T>(responseData: ResponseData, resourceData?: T) => void
  handleServerErrors: <T>(responseData: ResponseData, resourceData?: T) => void
  handleApiHookErrors: <T>(error: FetchBaseQueryError | SerializedError, resourceData?: T) => void
  handleNotFound: (apiHookError?: FetchBaseQueryError | SerializedError, redirectTo?: string) => void
  handleAsyncValidation: (url: string, payload: any, validationField: string) => Promise<ValidationError>
}

export const useErrorHandler = (initialValidationErrorsState?: ValidationErrors): UseErrorHandlerReturnType => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['error', 'validation'])
  const { navigate } = useDashboardNavigate()
  const [isValidating, setIsValidating] = React.useState(false)
  const [validationErrors, setValidationErrors] = React.useState<ValidationErrors>(initialValidationErrorsState)

  const getErrorMessage = (item: ResponseDataItem | ResponseData, resourceData?: TOptions): string => {
    let message = t(item.errorCode ?? 'failedRequest', '', { ...resourceData })
    // TODO: remove the condition below when all error codes are translated
    if (isEmpty(message) && !isEmpty(item.message)) {
      message = item.message.charAt(0).toUpperCase() + item.message.slice(1)
    }
    return message
  }

  const handleValidationErrors = <T>(responseData: ResponseData, resourceData?: T): void => {
    const { items } = responseData

    const noFieldSpecificErrors = []
    items?.forEach((item: ResponseDataItem) => {
      const message = getErrorMessage(item, resourceData)
      if (!isNil(item.path)) {
        const field = item.path
        setValidationErrors((prevState) => ({ ...prevState, [field]: { valid: false, message } }))
      } else {
        noFieldSpecificErrors.push(message)
      }
    })

    if (!isEmpty(noFieldSpecificErrors)) {
      // TODO: show noFieldSpecificErrors in snackbar stacking alerts on top of each other not joining them with line break
      dispatch(
        addSnackbarToState({
          severity: 'error',
          message: noFieldSpecificErrors.join(' \n')
        })
      )
    }
  }

  const handleAsyncValidation = async (
    url: string,
    payload: any,
    validationField: string
  ): Promise<ValidationError> => {
    setIsValidating(true)
    try {
      await post(url, payload)
      return { valid: true, message: '' }
    } catch (error) {
      if (!isNil(error) && error.response.status > 299 && error.response.status < 500) {
        const validationError = { valid: false, message: error.response.data.message }
        setValidationErrors((prevState) => ({
          ...prevState,
          [validationField]: validationError
        }))
        return validationError
      }
      return { valid: true, message: '' }
    } finally {
      setIsValidating(false)
    }
  }

  const handleServerErrors = <T>(responseData: ResponseData, resourceData?: T): void => {
    if (!isEmpty(responseData)) {
      const message = getErrorMessage(responseData, resourceData)
      dispatch(addSnackbarToState({ severity: 'error', message }))
    }
  }

  const handleApiHookErrors = <T>(error: FetchBaseQueryError | SerializedError, resourceData?: T): void => {
    const e = error as FetchBaseQueryError & { data: ResponseData }
    if (e.status === 'FETCH_ERROR') {
      dispatch(addSnackbarToState({ severity: 'error', message: t(`failedNetworkRequest`) }))
    } else if (e.status === 'PARSING_ERROR') {
      dispatch(addSnackbarToState({ severity: 'error', message: t(`failedRequest`) }))
    } else if ((e.status as number) === 404) {
      dispatch(addSnackbarToState({ severity: 'error', message: t(`notFound`) }))
    } else if (!isEmpty(e.data?.items)) {
      handleValidationErrors<T>(e.data, resourceData)
    } else if (!isEmpty(e.data)) {
      handleServerErrors<T>(e.data, resourceData)
    } else {
      dispatch(addSnackbarToState({ severity: 'error', message: t(`failedRequest`) }))
    }
  }

  const handleNotFound = (apiHookError?: FetchBaseQueryError | SerializedError, redirectTo?: string): void => {
    const error = apiHookError as FetchBaseQueryError
    if (!isNil(error) && error.status !== 404) {
      handleApiHookErrors(apiHookError)

      if (!isNil(redirectTo)) {
        navigate(redirectTo)
      } else {
        navigate(-1)
      }
    } else {
      dispatch(
        addSnackbarToState({
          severity: 'error',
          message: t('notFound', { ns: 'error' })
        })
      )
      navigate(redirectTo ?? PagePath.MY_PROJECTS)
    }
  }

  return {
    validationErrors,
    setValidationErrors,
    isValidating,
    handleValidationErrors,
    handleServerErrors,
    handleApiHookErrors,
    handleNotFound,
    handleAsyncValidation
  }
}
