import { SelectLocationButton } from './SelectLocationButton'
import { PrivacySelector } from '../project/PrivacySelector'
import { ProjectDescriptionTooltip, ProjectNameTooltip } from '../project/ProjectTooltips'
import { ModalInputText } from '../../inputComponents/ModalInputText'
import { ModalInputTextArea } from '../../inputComponents/ModalInputTextArea'
import { ModalTemplateProps } from '../../interfaces'
import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { first, isEmpty, isNil, last } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { StyledCircularProgress } from '@/components/progress/CenteredCircularProgress'
import { getUsername } from '@/helpers/cookies'
import {
  ValidationResultProps,
  ValidationRules,
  isNotEmpty,
  isRequired,
  isValidInput,
  isValidMaximumNumberOfChars,
  isValidRequiredNumberOfChars,
  itDoesNotStartWithEmptySpace
} from '@/helpers/validationHelpers'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useIsSharedWithMePage } from '@/hooks/useIsSharedWithMePage'
import { useProjectPreferences } from '@/hooks/useProjectPreferences'
import useStateIfMounted from '@/hooks/useStateIfMounted'
import { useValidation } from '@/hooks/useValidation'
import { useGetProjectV3Details } from '@/store/features/apis/slices/project/helpers'
import { LegacyProjectPermissions } from '@/store/features/apis/slices/project/interfaces'
import { useCopyProjectMutation } from '@/store/features/apis/slices/project/projectSlice'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'
import {
  addSnackbarToState,
  openModal,
  selectSingleItem,
  setCurrentFolderPage,
  setCurrentProjectPage,
  setLatestCopiedProject
} from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { Button } from '@/components/button/Button'
import { FormRow } from '@/components/modal/inputComponents/FormRow'

interface CopyProjectModalProps extends ModalTemplateProps {
  title: string
  selectedProjectIdExt: string
  selectedProjectName: string
}

interface CopyProjectPayload {
  projectName: string
  publicProjectName: string
  ownerName: string
  description: string
  categories: string[]
  publicPermission: LegacyProjectPermissions
  isPrivate: boolean
  userCanCreatePrivateProjects: boolean
  originalProjectName: string
  originalProjectDescription: string
  spaceId: string
  parentFolderId?: string
}

export const CopyProjectModalTemplate = ({
  onClose,
  onCancel,
  title,
  selectedProjectIdExt,
  selectedProjectName
}: CopyProjectModalProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const [copyProject, { isLoading: isLoadingCopyProject }] = useCopyProjectMutation()
  const isSharedWithMePage = useIsSharedWithMePage()
  const userName = getUsername()
  const { selectedPath } = useAppSelector((state) => state.ui)

  const isFormDirty = React.useRef(false)

  const { data: project, isLoading: isProjectDetailsFetching } = useGetProjectV3Details({
    projectId: selectedProjectIdExt
  })
  const { categories, description, publicPermission, projectName, publicProjectName, ownerName } = project

  const { allowPublicProjects, allowPrivateProjects, isLoading: isLoadingProjectPreferences } = useProjectPreferences()

  const [projectNameInputValue, setProjectNameInputValue] = useStateIfMounted<string>(
    `${projectName} - ${t('copyProject.copy')}`
  )
  const [projectDescriptionInputValue, setProjectDescriptionInputValue] = useStateIfMounted<string>(
    !isEmpty(description) ? `${description} - ${t('copyProject.copy')}` : ''
  )
  const [isCopying, setIsCopying] = useStateIfMounted<boolean>(false)
  const [privateProject, setPrivateProject] = useStateIfMounted<boolean>(allowPrivateProjects)
  const NO_PERMISSIONS: LegacyProjectPermissions = {
    readPreview: false,
    read: false,
    write: false,
    billableAction: false,
    getCopy: false
  }

  const [projectPermissions, setProjectPermissions] = useStateIfMounted<LegacyProjectPermissions>(
    allowPrivateProjects ? NO_PERMISSIONS : publicPermission
  )

  const creatingCopyMessage = isSharedWithMePage
    ? t('copyProject.creatingCopyInMyProjects', { projectName: selectedProjectName })
    : t('copyProject.creatingCopy', { projectName: selectedProjectName })

  const createdCopyMessage = isSharedWithMePage
    ? t('copyProject.copyCreatedInMyProjects', { projectName: selectedProjectName })
    : t('copyProject.copyCreated', { projectName: selectedProjectName })

  const { validateField } = useValidation()

  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    projectName: { valid: true, message: '' },
    description: { valid: true, message: '' }
  })

  const validationRules: ValidationRules = {
    projectName: [isRequired, isNotEmpty, isValidRequiredNumberOfChars(privateProject ? 1 : 5), isValidInput],
    description: privateProject
      ? [itDoesNotStartWithEmptySpace, isValidRequiredNumberOfChars(1), isValidMaximumNumberOfChars(2000)]
      : [
          isRequired,
          isNotEmpty,
          itDoesNotStartWithEmptySpace,
          isValidRequiredNumberOfChars(5),
          isValidMaximumNumberOfChars(2000)
        ]
  }

  const validateForm = (): boolean => {
    const localValidationErrors = { ...validationErrors }
    Object.keys(validationRules).forEach((field) => {
      let value
      if (field === 'projectName') {
        value = projectNameInputValue
      } else if (field === 'description') {
        value = projectDescriptionInputValue
      }
      localValidationErrors[field] = validateField(value, validationRules[field])
    })
    setValidationErrors(localValidationErrors)
    const isFormValid = Object.values(localValidationErrors).every(({ valid }) => valid)
    return isFormValid
  }

  React.useEffect(() => {
    setPrivateProject(allowPrivateProjects)
    setProjectPermissions(allowPrivateProjects ? NO_PERMISSIONS : publicPermission)
  }, [allowPrivateProjects])

  React.useEffect(() => {
    if (isFormDirty.current) {
      validateForm()
    }
  }, [privateProject])

  const submitDisabled =
    isLoadingCopyProject ||
    isProjectDetailsFetching ||
    isEmpty(selectedPath) ||
    !validationErrors['projectName']?.valid ||
    !validationErrors['description']?.valid ||
    isCopying

  const handleProjectCopy = (): void => {
    if (!validateForm()) {
      return
    }

    dispatch(
      addSnackbarToState({
        severity: 'info',
        message: creatingCopyMessage
      })
    )

    // eslint-disable-next-line no-console
    console.info(`${userName} trying to copy project ${projectName} to ${first(selectedPath)}`)

    const copyProjectPayload: CopyProjectPayload = {
      projectName: projectNameInputValue,
      publicProjectName,
      ownerName: userName, // new username
      description: projectDescriptionInputValue,
      categories,
      publicPermission: projectPermissions,
      isPrivate: privateProject,
      userCanCreatePrivateProjects: allowPrivateProjects,
      originalProjectName: projectName,
      originalProjectDescription: description,
      spaceId: first(selectedPath),
      parentFolderId: first(selectedPath) === last(selectedPath) ? undefined : last(selectedPath)
    }

    setIsCopying(true)
    copyProject({ ownerName, publicProjectName, payload: copyProjectPayload })
      .unwrap()
      .then((projectIdExt) => {
        dispatch(
          setLatestCopiedProject({
            thumbnailUrl: project.thumbnailUrl,
            projectIdExt
          })
        )
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: createdCopyMessage
          })
        )
        dispatch(selectSingleItem({ id: projectIdExt, type: 'project' }))
        dispatch(setCurrentProjectPage(1))
        dispatch(setCurrentFolderPage(1))
        onClose(`projects/${projectIdExt}`)
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
      .finally(() => setIsCopying(false))
  }

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, component: string) => {
    isFormDirty.current = true
    const { value, checked } = event.target
    switch (component) {
      case 'projectName':
        setProjectNameInputValue(value)
        break
      case 'description':
        setProjectDescriptionInputValue(value)
        break
      case 'privateProject':
        const changePermission = (): void => {
          setPrivateProject(checked)
          setProjectPermissions((prevState) => ({
            ...prevState,
            readPreview: !checked,
            read: !checked,
            getCopy: !checked
          }))
        }
        if (!checked) {
          dispatch(
            openModal({
              template: ModalTemplate.CONFIRM_PUBLIC_PROJECT_PERMISSION,
              showCloseButton: true,
              modalProps: {
                title: t('enablePublicProjectPermission.modalTitle', { ns: 'modals' }),
                onConfirm: () => changePermission()
              }
            })
          )
        } else {
          changePermission()
        }
        break
    }
  }, [])

  const handleValidation = React.useCallback(
    (value: any, component: string) => {
      isFormDirty.current = true
      let validation: ValidationResultProps
      switch (component) {
        case 'projectName':
          validation = validateField(value, validationRules.projectName)
          break
        case 'description':
          validation = validateField(value, validationRules.description)
          break
      }
      if (!isNil(validation)) {
        setValidationErrors((prevState) => ({ ...prevState, [component]: validation }))
      }
    },
    [validationRules]
  )

  return (
    <React.Fragment>
      {isCopying && <StyledCircularProgress />}
      <ModalTitle onClose={onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={handleProjectCopy} submitDisabled={submitDisabled}>
        <Stack direction="column" pt={5} gap={2}>
          <FormRow label={t('location')}>
            <SelectLocationButton isCopying={isCopying} />
          </FormRow>
          <ModalInputText
            onChange={(event): void => {
              handleChange(event, 'projectName')
            }}
            error={!validationErrors['projectName']?.valid}
            helperText={validationErrors['projectName']?.message}
            label={t('project.fields.projectName')}
            placeholder={t('project.placeholders.projectName')}
            required
            value={projectNameInputValue}
            tooltip={<ProjectNameTooltip />}
            disabled={isLoadingCopyProject}
            onBlur={(): void => handleValidation(projectNameInputValue, 'projectName')}
            autoFocus
          />
          <ModalInputTextArea
            label={t('project.fields.projectDescription')}
            placeholder={t('project.placeholders.projectDescription')}
            required={!privateProject}
            value={projectDescriptionInputValue}
            onChange={(event): void => {
              handleChange(event, 'description')
            }}
            error={!validationErrors['description']?.valid}
            helperText={validationErrors['description']?.message}
            tooltip={<ProjectDescriptionTooltip />}
            disabled={isLoadingCopyProject}
            onBlur={(): void => handleValidation(projectDescriptionInputValue, 'description')}
          />
          <PrivacySelector
            isChecked={privateProject}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              handleChange(event, 'privateProject')
            }}
            isPrivateProjectDisabled={!allowPrivateProjects}
            isPublicProjectDisabled={!allowPublicProjects}
            isLoading={isLoadingProjectPreferences}
            isDisabled={isLoadingCopyProject}
          />
        </Stack>
      </ModalContent>
      <ModalActions>
        <Button onClick={onCancel} color="secondary" variant="outlined" disabled={submitDisabled}>
          {t('cancel')}
        </Button>
        <Button onClick={handleProjectCopy} color="primary" variant="contained" disabled={submitDisabled}>
          {t('copyProject.copy')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}
