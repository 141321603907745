interface OrganizationUser {
  username: string
  email: string
  userId: number
}

export interface OrganizationUsers {
  users: OrganizationUser[]
}

export type OrganizationV2 = {
  organizationId: string
  globalGroupId: string
  name: string
  createdAt: string
  lastModifiedAt: string
  isProfessional: boolean
}

export type OrganizationV2User = {
  uidExt: number
  status: UserOrganizationStatus
  reservedSeat?: boolean
  reservedSolver?: UserOrganizationSolver[]
  coreHours?: string
  lastSimulationRun?: string
  joinedAt?: string
  quotaBudgets?: UserQuotaBudgets
}

export interface BalanceResponse {
  quotas: {
    cpuSeconds: BalanceSecondsDetail
    gpuSeconds: BalanceSecondsDetail
  }
}

export interface BalanceSecondsDetail {
  available: number
  total: number
}

export enum BudgetType {
  LIMITED = 'LIMITED',
  ZERO = 'ZERO',
  UNLIMITED = 'UNLIMITED'
}

export interface QuotaBudget {
  budgetType: BudgetType
  quantity?: number
  validTo?: string
}

interface QuotaBudgets {
  cpuSeconds: QuotaBudget
  gpuSeconds: QuotaBudget
}

export interface UserQuotaBudgets extends QuotaBudgets {
  uidExt: number
}

export interface QuotaBudgetsPayload {
  budgets: UserQuotaBudgets[]
}

export interface QuotaBudgetsResponse {
  budgets: UserQuotaBudgets[]
}

export enum UserOrganizationStatus {
  ADMIN = 'Admin',
  MEMBER = 'Member',
  PENDING = 'Pending',
  FREE = 'Free'
}

enum UserOrganizationSolver {
  SIMERICS = 'simerics',
  LBM = 'lbm',
  PWC = 'pwc'
}

export type OrganizationAttributes = {
  isProfessional: boolean
  isTrial: boolean
  isAcademic: boolean
  free: boolean
  isSimscale?: boolean
}

type OrganizationCapabilitiesType = {
  showAIModelManager: boolean
  allowAPIAccessForAI: boolean
}

export type OrganizationSettings = {
  enablePasswordLogin: boolean
  allowPublicProjects: boolean
  defaultUserBudget: string
  capabilities: OrganizationCapabilitiesType
}

export type OrganizationDomain = {
  domain: string
  orgId: string
  status: OrganizationDomainStatus
  createdAt: string
  modifiedAt: string
}

export enum OrganizationDomainStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  DECLINED = 'DECLINED'
}

export type AiAccess = {
  hasAccess: boolean
}

export type OrganizationUserAiAccess = {
  uidExt: string
  hasAccess: boolean
}
