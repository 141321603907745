import { TrainingDataProjectsManagement } from './TrainingDataProjectsManagement'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { AiSectionPaths } from '@/constants'
import { Button } from '@/components/button/Button'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { useAppSelector } from '@/store/hooks'
import { REQUIRED_NUMBER_OF_RUNS } from '@/constants/ai'

type TrainingDataCartType = {
  successfulRuns: number
  setSuccessfulRuns: React.Dispatch<React.SetStateAction<number>>
  handleButtonClick: () => void
  isLoading?: boolean
  isAlertShown?: boolean
}

export const TrainingDataCart = ({
  successfulRuns,
  setSuccessfulRuns,
  handleButtonClick,
  isLoading,
  isAlertShown
}: TrainingDataCartType): React.ReactElement => {
  const { t } = useTranslation('aiTraining')
  const [searchParams] = useSearchParams()
  const aiModelTrainingSettings = useAppSelector((store) => store.ui.aiModelTrainingSettings)

  const isOnDataSettingPage = React.useMemo(
    () => searchParams.get('page') === AiSectionPaths.dataSettings.value,
    [searchParams]
  )

  const isNextButtonDisabled = isOnDataSettingPage
    ? !aiModelTrainingSettings?.isValid
    : successfulRuns < REQUIRED_NUMBER_OF_RUNS

  return (
    <Stack
      sx={{
        width: '300px',
        height: 'fit-content',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'divider',
        borderRadius: 1,
        maxHeight: '70vh'
      }}
      flexShrink={0}
      padding={5}
      gap={5}
    >
      <Stack gap={1}>
        <Typography variant="subtitle" textTransform="uppercase" color="grey.600">
          {isOnDataSettingPage ? t('projectsCart.stepTwoOfTwo') : t('projectsCart.stepOneOfTwo')}
        </Typography>
        <Typography variant="title">{t('projectsCart.trainingData')}</Typography>
      </Stack>
      <Stack gap={5} paddingRight={5} marginRight={-5} sx={{ overflowY: 'scroll' }}>
        <TrainingDataProjectsManagement setSuccessfulRuns={setSuccessfulRuns} />
      </Stack>
      <Stack gap={2} paddingTop={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="title">{t('projectsCart.totalRuns')}</Typography>
          <Typography variant="titleLight">{`${successfulRuns} ${t('projectsCart.runs')}`}</Typography>
        </Stack>
        {isAlertShown && (
          <Stack gap={1} direction="row" alignItems="center">
            <SpriteIcon color="grey.600" spriteId="circle-info" width={20} height={20} />
            <Typography variant="summaryCardDescription" paddingRight={1} component="p" sx={{ overflow: 'hidden' }}>
              {t('projectsCart.notEnoughSuccessfulRuns')}
            </Typography>
          </Stack>
        )}
        <Button
          onClick={handleButtonClick}
          color="primary"
          variant="contained"
          disabled={isNextButtonDisabled || isLoading}
          isLoading={isLoading}
          size="large"
          sx={{ flexShrink: 0 }}
        >
          {isOnDataSettingPage ? t('projectsCart.startTraining') : t('projectsCart.next')}
        </Button>
      </Stack>
    </Stack>
  )
}
