export enum ModelState {
  DRAFT = 'Draft',
  TRAINING = 'Training',
  READY = 'Ready',
  FAILED = 'Failed',
  RELEASED = 'Released'
}

type ModelUsageMetrics = {
  users: string[]
  successful_tries: number
  tries: number
}

export type Model = {
  analysis_type: string
  name: string
  thumbnail?: string
  description: string
  predictor_component: string
  shared_with_organization: boolean
  template_name: string
  uuid: string
  creator_user_id: string
  state: ModelState
  usage_metrics: ModelUsageMetrics
  documentation?: string
}

export enum ModelStage {
  STAGING = 'Staging',
  PRODUCTION = 'Production',
  ARCHIVED = 'Archived',
  NONE = 'None'
}

export type ModelVersion = {
  model_uuid: string
  created_at: string
  name: string
  stage: ModelStage
  training_run_id: string
  version: string
  description?: string
}

export enum DistributionType {
  MY_ORGANIZATION = 'myOrganization',
  ONLY_ME = 'onlyMe'
}

export enum TrainingRunStatus {
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  TRAINING = 'TRAINING',
  DRAFT = 'DRAFT'
}

export type TrainingRunMetric = {
  description: string
  hasHistory: boolean
  name: string
  translationKey: string
  value: number
}

export type TrainingRun = {
  metrics: TrainingRunMetric[]
  name: string
  status: TrainingRunStatus
  training_run_id: string
  training_uuid: string
  progress: number
}

enum TrainingRunEventLogLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

type TrainingRunEventLogParameter = {
  name: string
  type: string
  value: string
}

export type TrainingRunEventLog = {
  level: TrainingRunEventLogLevel
  messageId: string
  parameters: TrainingRunEventLogParameter[]
  parametersObject?: Record<string, string>
  timestamp: string
}

export type TrainingRunMetricHistoryItem = {
  metric: string
  step: number
  value: number
}

export enum TrainingPipelineStatus {
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
  RUNNING = 'Running',
  PENDING = 'Pending'
}

export type TrainingPipeline = {
  created_at: string
  data_extraction_run_id: string
  status: TrainingPipelineStatus
  training_name: string
  training_run_ids: string[]
  training_uuid: string
}

export type PatchModelPayloadType = {
  shared_with_organization?: boolean
  analysis_type?: string
  template_name?: string
  documentation?: string
}

type SchemaPropertyType = {
  default: any
  title: string
  description: string
  type: 'string' | 'integer' | 'boolean' | 'object' | 'number' | 'array'
  enum?: any[]
  readOnly?: boolean
  exclusiveMaximum?: number
  exclusiveMinimum?: number
  properties?: Record<string, SchemaPropertyType>
  required?: string[]
}

export type SchemaType = {
  type: 'object'
  title: string
  properties: Record<string, SchemaPropertyType>
  required?: string[]
}

export type TrainModelUserOptionsType = {
  automatic_input_detection: boolean
  device: string
  force_crash: boolean
  hyperparameter_tuning: boolean
  hyperparameter_tuning_stop_criterion_max_runtime: number
  include_slices: boolean
  include_specific_saved_selections: boolean
  quick_mode: boolean
  nodal_outputs: {
    pressure: boolean
    relative_humidity: boolean
    temperature: boolean
    velocity: boolean
    wall_heat_flux: boolean
  }
  saved_selections: string
  slices: {
    plane_normal: { x: number; y: number; z: number }
    plane_origin: { x: number; y: number; z: number }
  }
  train_outlier_detector: boolean
}

export type PostTrainModelPayloadType = {
  active_deadline_seconds: string
  projects: string[]
  training_name: string
  user_options: TrainModelUserOptionsType
}
