import { NavigationItemTitle, NavigationItemTitleProps } from '../navigation/NavigationItemTitle'
import { CollapseWrapper } from '../../components/elements/Collapse'
import {
  useIsAdmin,
  useIsManageInvitationsEnabled,
  useIsManageUsersAreaEnabled,
  useIsTeamsAdminAreaEnabled,
  useIsUsersAreaEnabled
} from '../../permissions/areas'
import { useOrganizationName } from '../../hooks/newWorld'
import { PagePath } from '../../constants'
import { useMetabaseAnalyticsEnabled } from '../dashboard/analyticsArea/permissions'
import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useCurrentPath } from '@/hooks/useCurrentPath'
import { useIsSearchMode } from '@/hooks/useIsSearchMode'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import { TAB_INDEXES } from '@/constants/tabIndexes'

type MenuItemsType = NavigationItemTitleProps & {
  route: string
}

export const AdminSection = (): React.ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useDashboardNavigate()

  const { currentPathNode } = useCurrentPath()
  const isSearchMode = useIsSearchMode()

  const { data: organizationName, isLoading: isOrganizationNameLoading } = useOrganizationName()

  const [isMetabaseAnalyticsLoading, isMetabaseAnalyticsEnabled] = useMetabaseAnalyticsEnabled()
  const showMetabaseAnalyticsArea = !isMetabaseAnalyticsLoading && isMetabaseAnalyticsEnabled

  const [isTeamsAdminLoading, isTeamsAdminAreaEnabled] = useIsTeamsAdminAreaEnabled()
  const showTeamsAdminArea = !isTeamsAdminLoading && isTeamsAdminAreaEnabled

  const [isManageUsersLoading, isManageUsersAreaEnabled] = useIsManageUsersAreaEnabled()
  const showManageUsersArea = !isManageUsersLoading && isManageUsersAreaEnabled

  const [isUsersLoading, isUsersAreaEnabled] = useIsUsersAreaEnabled()
  const showUsersArea = !isUsersLoading && isUsersAreaEnabled

  const [isManageInvitationsLoading, isManageInvitationsEnabled] = useIsManageInvitationsEnabled()
  const showManageInvitationsArea = !isManageInvitationsLoading && isManageInvitationsEnabled

  const { data: isAdmin, isLoading: isAdminLoading } = useIsAdmin()
  const showAdminArea = !isAdminLoading && isAdmin

  const isLoading = isAdminLoading || isOrganizationNameLoading
  // uncomment and use where it's indicated bellow after https://simscale.atlassian.net/browse/ENG-40868
  // const navigationOrganizationName = React.useMemo(() => {
  //     if (isInOrganization) {
  //       return organizationName
  //     }
  //     if (!isNil(user?.firstname)) {
  //       return t('firstNameOrganization', { firstName: user.firstname })
  //     }
  //     return t('organization')
  // }, [isInOrganization, organizationName, user])

  const menuItems = React.useMemo(() => {
    const items: MenuItemsType[] = []

    if (showTeamsAdminArea) {
      items.push({
        spriteId: 'user-gear',
        label: t('manageSpaces'),
        route: PagePath.MANAGE_SPACES
      })
    }

    if (showUsersArea || showManageUsersArea) {
      items.push({
        spriteId: 'user',
        label: t(showManageUsersArea ? 'manageUsers' : 'users'),
        route: PagePath.MANAGE_USERS
      })
    }

    if (showManageInvitationsArea) {
      items.push({
        spriteId: 'envelope',
        label: t('manageInvitations'),
        route: PagePath.MANAGE_INVITATIONS
      })
    }

    if (showMetabaseAnalyticsArea) {
      items.push({
        spriteId: 'chart-line',
        label: t('analyticsArea'),
        route: PagePath.ANALYTICS_AREA
      })
    }

    if (showAdminArea) {
      items.push({
        spriteId: 'gear',
        label: t('organizationSettings'),
        route: PagePath.ORGANIZATION_SETTINGS
      })
    }

    return items
  }, [
    isLoading,
    showTeamsAdminArea,
    showManageUsersArea,
    showUsersArea,
    showManageInvitationsArea,
    showAdminArea,
    showMetabaseAnalyticsArea,
    t
  ]).filter(Boolean)

  if (isEmpty(menuItems) || isLoading) {
    return null
  }

  return (
    <CollapseWrapper
      collapseText={organizationName} // replace with navigationOrganizationName after https://simscale.atlassian.net/browse/ENG-40868
      defaultCollapseValue={true}
      textTransform="uppercase"
      fontWeight={400}
      textColor="grey.700"
      collapsedIconRotationValue={90}
      qa-attribute="admin-section-wrapper"
    >
      <Box qa-attribute="admin-section">
        {menuItems.map((menuItem, index) => {
          const isSelected = [menuItem.route].includes(currentPathNode) && !isSearchMode
          return (
            <NavigationItemTitle
              key={menuItem.label}
              isSelected={isSelected}
              onClick={(): void => navigate(menuItem.route)}
              {...menuItem}
              tabIndex={index === 0 ? TAB_INDEXES.ADMIN_SECTION : -1}
            />
          )
        })}
      </Box>
    </CollapseWrapper>
  )
}
