import { useGetPathPermission } from './hooks'
import * as React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { openModal, setSelectedPath } from '@/store/features/uiSlice/uiSlice'
import { Button } from '@/components/button/Button'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { usePersonalSpaceId } from '@/store/features/apis/slices/space/hooks'

export const SelectLocationButton = ({ isCopying }: { isCopying: boolean }): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['modals', 'translation'])
  const { fullPath, selectedPath } = useAppSelector((state) => state.ui)
  const personalSpaceId = usePersonalSpaceId()

  const {
    hasPermission,
    isLoading: isLoadingPermission,
    pathName
  } = useGetPathPermission({
    path: isEmpty(selectedPath) ? fullPath : selectedPath
  })

  React.useEffect(() => {
    if (isNil(selectedPath) && !isLoadingPermission) {
      dispatch(setSelectedPath(hasPermission ? fullPath : [personalSpaceId]))
    }
  }, [selectedPath, isLoadingPermission, hasPermission, personalSpaceId, fullPath])

  React.useEffect(() => {
    return () => {
      dispatch(setSelectedPath(undefined))
    }
  }, [])

  const handleOnClick = React.useCallback(() => {
    dispatch(
      openModal({
        template: ModalTemplate.SELECT_LOCATION,
        showCloseButton: true,
        modalProps: {
          title: t('copyProject.selectCopyLocation', { ns: 'modals' })
        }
      })
    )
  }, [dispatch])

  return (
    <Button
      variant="outlined"
      startIcon={!isLoadingPermission && <SpriteIcon spriteId="folder" color="primary" width={14} />}
      onClick={handleOnClick}
      isLoading={isLoadingPermission}
      disabled={isCopying}
    >
      <Typography variant="textWithEllipsis">
        {!isLoadingPermission ? (hasPermission ? pathName : t('myProjects', { ns: 'translation' })) : ''}
      </Typography>
    </Button>
  )
}
