import * as React from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { InfinitePagination } from '@/components/pagination/InfinitePagination'
import { ModalInputSelect } from '@/components/modal/inputComponents/ModalInputSelect'

type EnhancedTableInfinitePaginationType = {
  page: number
  currentNumberOfItems: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  hasMorePages: boolean
  isLoading?: boolean
  handleOnChangePage: (page: number) => void
  handleOnRowsPerPageChange: (value: string) => void
}

export const EnhancedTableInfinitePagination = ({
  page,
  currentNumberOfItems,
  rowsPerPage,
  rowsPerPageOptions,
  hasMorePages,
  isLoading,
  handleOnChangePage,
  handleOnRowsPerPageChange
}: EnhancedTableInfinitePaginationType): React.ReactElement => {
  const { t } = useTranslation('translation')

  const options = React.useMemo(
    () => rowsPerPageOptions.map((value) => ({ label: value.toString(), value: value.toString() })),
    [rowsPerPageOptions]
  )

  return (
    <Stack direction="row" gap={5} flexWrap="nowrap">
      <ModalInputSelect
        value={rowsPerPage.toString()}
        label={`${t('rowsPerPage')}:`}
        onChange={(event): void => handleOnRowsPerPageChange(event.target.value)}
        options={options}
        disabled={isLoading}
        sx={{ fontSize: 12 }}
      />
      <InfinitePagination
        currentPage={page}
        currentNumberOfItems={currentNumberOfItems}
        pageSize={rowsPerPage}
        setPageCallback={handleOnChangePage}
        hideNextPage={!hasMorePages}
        isLoading={isLoading}
      />
    </Stack>
  )
}
