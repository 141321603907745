import { TrainingRunListItemDetails } from '../trainingRun/TrainingRunListItemDetails'
import { TrainingRunDetails } from '../trainingRun/TrainingRunDetails'
import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import styled from '@mui/system/styled'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import format from 'date-fns/format'
import { ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { Button } from '@/components/button/Button'
import { ActionButton } from '@/components/button/ActionButton'
import { ContextMenuType } from '@/components/contextMenu/ContextMenu'
import { useContextMenu } from '@/hooks/useContextMenu'
import { useReleaseModelVersionOperation } from '@/operations/ai/models/versions/releaseModelVersionOperation'
import { formatVersion } from '@/containers/ai/helpers'
import { SpriteIcon } from '@/assets/SpriteIcon'

type ModelVersionsListItemProps = {
  version: ModelVersion
  isOpen?: boolean
  onClick?: (version: ModelVersion) => void
}

type ModelVersionsListItemRowProps = {
  version: ModelVersion
  isOpen?: boolean
  onClick?: (version: ModelVersion) => void
}

export const ModelVersionsListItemSkeleton = (): React.ReactElement => {
  return <Skeleton variant="rounded" width="100%" height={65} animation="wave" />
}

const ModelVersionsListItemCard = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.spacing(1)
}))

const ModelVersionsListItemRow = ({ version, isOpen, onClick }: ModelVersionsListItemRowProps): React.ReactElement => {
  const { show } = useContextMenu()

  const [releaseModelVersion, canReleaseModelVersion] = useReleaseModelVersionOperation(version)

  const handleReleaseVersionClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      releaseModelVersion()
    },
    [version]
  )

  return (
    <Stack
      spacing={5}
      paddingX={4}
      paddingY={2}
      height={65}
      direction="row"
      alignItems="center"
      onClick={(): void => onClick(version)}
      qa-attribute="aiModel--versionsList--item--row"
    >
      <Stack spacing={3} direction="row" alignItems="center" maxWidth={800}>
        <SpriteIcon
          spriteId="angle-right"
          color="grey.600"
          width={20}
          rotationTime={0.3}
          rotatingAngle={isOpen ? 90 : 0}
        />
        <Box minWidth={180}>
          <Typography variant="textV2" display="block">
            {formatVersion(version.version)} - {version.name}
          </Typography>
          <Typography variant="textSmallV2" color="grey.700">
            {format(new Date(version.created_at), 'MMM d, yyyy')}
          </Typography>
        </Box>
        <TrainingRunDetails version={version} trainingRunId={version.training_run_id} />
      </Stack>
      <Stack marginLeft="auto" direction="row" spacing={5} flexGrow={1} justifyContent="end">
        {canReleaseModelVersion && (
          <Button variant="outlined" size="small" onClick={handleReleaseVersionClick}>
            Release
          </Button>
        )}
        <ActionButton
          onClick={(event): void => {
            show({ event, type: ContextMenuType.MODEL_VERSION, version })
          }}
          qa-attribute="table-row-action--button"
        >
          <SpriteIcon spriteId="ellipsis-vertical" color="text.primary" width={16} height={16} />
        </ActionButton>
      </Stack>
    </Stack>
  )
}

export const ModelVersionsListItem = ({ version, ...props }: ModelVersionsListItemProps): React.ReactElement => {
  return (
    <ModelVersionsListItemCard
      sx={{ backgroundColor: props.isOpen ? 'grey.100' : 'transparent' }}
      qa-attribute="aiModel--versionsList--item"
    >
      <ModelVersionsListItemRow version={version} {...props} />
      {props.isOpen && <TrainingRunListItemDetails trainingRunId={version.training_run_id} />}
    </ModelVersionsListItemCard>
  )
}
