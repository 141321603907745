import { SharingAlert } from './SharingAlert'
import { PRICING_URL } from '../../../../constants'
import { isInsideCypress } from '../../../../helpers/cookies'
import { Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { isNil } from 'lodash'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface PrivacySelectorProps {
  isPrivateProjectChecked?: boolean
  isPrivateProjectDisabled: boolean
  isPublicProjectDisabled?: boolean
}

export const NonProfessionalLicenseAlert = ({
  nonProfessionalSharingWarning
}: {
  nonProfessionalSharingWarning?: boolean
}): React.ReactElement => {
  const { t } = useTranslation('modals')

  return (
    <SharingAlert>
      <Typography variant="text" paddingRight={1}>
        {nonProfessionalSharingWarning
          ? t('permissions.nonProfessionalSharingWarning')
          : t('permissions.onlyPublicProjectAlert')}
      </Typography>
      <Link href={PRICING_URL} rel="noopener noreferrer" target={isInsideCypress() ? '_self' : '_blank'}>
        <Typography variant="textBold" paddingRight={1}>
          {t('permissions.upgradeNow')}
        </Typography>
        <SpriteIcon spriteId="arrow-up-right-from-square" width={12} height={12} />
      </Link>
    </SharingAlert>
  )
}

export const PrivacySelectorAlerts = ({
  isPrivateProjectChecked,
  isPrivateProjectDisabled,
  isPublicProjectDisabled
}: PrivacySelectorProps): React.ReactElement => {
  const { t } = useTranslation('modals')

  if (isNil(isPrivateProjectChecked)) {
    return null
  }

  if (isPrivateProjectChecked && isPrivateProjectDisabled) {
    return <NonProfessionalLicenseAlert />
  }

  if (isPublicProjectDisabled) {
    return <SharingAlert>{t('permissions.onlyPrivateProjectAlertNewWorld')}</SharingAlert>
  }

  return null
}
