import { BoxProps, SxProps, Theme } from '@mui/material'
import * as React from 'react'

export enum TableFilterType {
  STRING = 'string',
  DATE = 'date',
  NUMBER = 'number'
}

export interface TableFilter<T = any> {
  type: TableFilterType
  id: string
  value?: T
}

export interface TableHeaderCell<T = any> {
  id: string
  label: string
  numeric?: boolean
  disablePadding?: boolean
  disableSorting?: boolean
  noEllipsis?: boolean
  filter?: T
  sx?: SxProps<Theme>
}

export interface TableRowCellProps {
  id: string
  title?: string
  value?: any
  component?: React.ReactElement
  onCellClick?: (event: React.MouseEvent) => void
  formattedValue?: string | number
  sx?: SxProps<Theme>
}

export interface TableRowProps {
  tableRowId: string
  tableRowCells: TableRowCellProps[]
  actionButton?: (event: React.MouseEvent, selectedItem: string) => void
  onRowClick?: (event: React.MouseEvent) => void
  expandedRowComponent?: React.ReactElement
}

export type Order = 'asc' | 'desc'

export interface EnhancedTableHeadProps {
  numSelected: number
  onRequestSort: (order: Order, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  tableHeaderData: TableHeaderCell[]
  useRowSelectionCheckbox?: boolean
  filters?: TableFilter[]
  setFilters?: React.Dispatch<React.SetStateAction<TableFilter<any>[]>>
}

export interface EnhancedTableProps {
  tableHeaderData: TableHeaderCell[]
  tableBodyData: TableRowProps[]
  useRowSelectionCheckbox?: boolean
  displayPagination?: boolean
  isLoading?: boolean
  filtersConfig?: TableFilter[]
  defaultSortByColumn?: number
  defaultOrder?: Order
  fixedSorting?: {
    column: string
    order: Order
  }
  rowsPerPageOptions?: number[]
  defaultRowsPerPage?: number
  searchString?: string
  onFiltersChange?: (data: TableRowProps[]) => void
  rowComponent?: React.ComponentType<EnhancedTableRowProps>
  tableLayout?: string
}

export type EnhancedTableWithExternalStateType = {
  tableHeaderData: TableHeaderCell[]
  tableBodyData: TableRowProps[]
  totalTableItemsCount?: number
  isLoading?: boolean
  page?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  hasMorePages?: boolean
  order?: Order
  setOrder?: React.Dispatch<React.SetStateAction<Order>>
  orderBy?: string
  setOrderBy?: React.Dispatch<React.SetStateAction<string>>
  rowsPerPageOptions?: number[]
  rowsPerPage?: number
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>
  filters?: TableFilter[]
  setFilters?: React.Dispatch<React.SetStateAction<TableFilter[]>>
  selected?: string[]
  onSelect?: (tableRowId: string) => void
  onSelectAll?: () => void
  rowComponent?: React.ComponentType<EnhancedTableRowProps>
} & BoxProps

export interface EnhancedTableRowsProps {
  tableRowsData: TableRowProps[]
  selected: string[]
  useRowSelectionCheckbox?: boolean
  handleCheckboxOnClick?: (tableRowId: string) => void
  rowComponent?: React.ComponentType<EnhancedTableRowProps>
}

export interface EnhancedTableRowProps {
  tableRowData: TableRowProps
  isSelected?: (tableRowId: string) => boolean
  useRowSelectionCheckbox?: boolean
  handleCheckboxOnClick?: (tableRowId: string) => void
  expandedRowComponent?: React.ReactElement
}
