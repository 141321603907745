export enum AnalysisType {
  /** Incompressible Pacefish is used in Incompressible (LBM) simulations and in PWC Wind Direction components in the Post-Processor. */
  INCOMPRESSIBLE_PACEFISH = 'INCOMPRESSIBLE_PACEFISH',
  WIND_COMFORT = 'WIND_COMFORT',

  // cfd
  INCOMPRESSIBLE = 'INCOMPRESSIBLE',
  COMPRESSIBLE = 'COMPRESSIBLE',
  CONJUGATE_HEAT_TRANSFER = 'CONJUGATE_HEAT_TRANSFER',
  COUPLED_CONJUGATE_HEAT_TRANSFER = 'COUPLED_CONJUGATE_HEAT_TRANSFER',
  CONVECTIVE_HEAT_TRANSFER = 'CONVECTIVE_HEAT_TRANSFER',
  MULTIPHASE = 'MULTIPHASE',
  /** Also known as IBM. */
  EMBEDDED_BOUNDARY = 'EMBEDDED_BOUNDARY',

  // aka multi-purpose
  SIMERICS_ANALYSIS = 'SIMERICS_ANALYSIS',

  // fea
  STATIC_ANALYSIS = 'STATIC_ANALYSIS',
  DYNAMIC_ANALYSIS = 'DYNAMIC_ANALYSIS',
  HARMONIC_ANALYSIS = 'HARMONIC_ANALYSIS',
  FREQUENCY_ANALYSIS = 'FREQUENCY_ANALYSIS',
  HEAT_TRANSFER = 'HEAT_TRANSFER',
  THERMAL_MECHANICAL = 'THERMAL_MECHANICAL',

  // electromagnetics
  ELECTROMAGNETIC_ANALYSIS = 'ELECTROMAGNETIC_ANALYSIS',

  // mesh quality
  MESHING = 'MESHING'
}

const analysisTypeMap: Record<AnalysisType, string> = {
  [AnalysisType.INCOMPRESSIBLE_PACEFISH]: 'Incompressible Fluid Flow (LBM)',
  [AnalysisType.WIND_COMFORT]: 'Pedestrian Wind Comfort',
  [AnalysisType.INCOMPRESSIBLE]: 'Incompressible Fluid Flow',
  [AnalysisType.COMPRESSIBLE]: 'Compressible Fluid Flow',
  [AnalysisType.CONJUGATE_HEAT_TRANSFER]: 'Conjugate Heat Transfer (legacy)',
  [AnalysisType.COUPLED_CONJUGATE_HEAT_TRANSFER]: 'Conjugate Heat Transfer v2.0',
  [AnalysisType.CONVECTIVE_HEAT_TRANSFER]: 'Convective Heat Transfer',
  [AnalysisType.MULTIPHASE]: 'Multiphase Flow',
  [AnalysisType.EMBEDDED_BOUNDARY]: 'Conjugate Heat Transfer (IBM)',
  [AnalysisType.SIMERICS_ANALYSIS]: 'Multi-purpose',
  [AnalysisType.STATIC_ANALYSIS]: 'Static Analysis',
  [AnalysisType.DYNAMIC_ANALYSIS]: 'Dynamic Analysis',
  [AnalysisType.HARMONIC_ANALYSIS]: 'Harmonic Analysis',
  [AnalysisType.FREQUENCY_ANALYSIS]: 'Frequency Analysis',
  [AnalysisType.HEAT_TRANSFER]: 'Heat Transfer Analysis',
  [AnalysisType.THERMAL_MECHANICAL]: 'Thermomechanical Analysis',
  [AnalysisType.ELECTROMAGNETIC_ANALYSIS]: 'Electromagnetics',
  [AnalysisType.MESHING]: 'Meshing'
}

const stringToAnalysisTypeMap: Record<string, AnalysisType> = Object.entries(analysisTypeMap).reduce(
  (acc, [key, value]) => {
    acc[value] = key as AnalysisType
    return acc
  },
  {} as Record<string, AnalysisType>
)

export const getStringFromAnalysisType = (analysisType: AnalysisType): string => {
  return analysisTypeMap[analysisType]
}

// ts-unused-exports:disable-next-line
export const getAnalysisTypeFromString = (analysisString: string): AnalysisType | undefined => {
  return stringToAnalysisTypeMap[analysisString]
}
