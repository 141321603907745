import { TrainingDataCart } from './TrainingDataCart'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/store/hooks'
import { getSelectedItems } from '@/store/features/uiSlice/uiSlice'
import { usePostTrainingModelMutation } from '@/store/features/apis/slices/ai/aiSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'

export const TrainingCartDataSettings = (): React.ReactElement => {
  const { handleApiHookErrors } = useErrorHandler()
  const { modelUuid } = useParams<{ modelUuid: string }>()
  const selectedProjects = useAppSelector(getSelectedItems).projects
  const [successfulRuns, setSuccessfulRuns] = React.useState(0)
  const aiModelTrainingSettings = useAppSelector((store) => store.ui.aiModelTrainingSettings)
  const [postTrainingModel, { isLoading }] = usePostTrainingModelMutation()
  const { navigate } = useDashboardNavigate()

  const handleStartTrainingButtonClick = React.useCallback(() => {
    postTrainingModel({
      modelUuid,
      payload: {
        training_name: aiModelTrainingSettings.formData.versionName,
        projects: selectedProjects,
        active_deadline_seconds: aiModelTrainingSettings.formData.maxRuntime.toString(),
        user_options: aiModelTrainingSettings.formData.userOptions
      }
    })
      .unwrap()
      .then(() => {
        navigate(`/models/${modelUuid}`)
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [aiModelTrainingSettings?.formData, selectedProjects, modelUuid])

  return (
    <TrainingDataCart
      handleButtonClick={handleStartTrainingButtonClick}
      successfulRuns={successfulRuns}
      setSuccessfulRuns={setSuccessfulRuns}
      isLoading={isLoading}
    />
  )
}
