import { GeneralBlockingError } from '../containers/dashboard/GeneralBlockingError'
import { LeftSidebarWrapper } from '../containers/dashboard/leftSidebar/LeftSidebarWrapper'
import { GlobalModal } from '../components/modal/GlobalModal'
import { Toolbar } from '../components/toolbar/Toolbar'
import { GlobalSnackbar } from '../components/snackbar/GlobalSnackbar'
import { useQueryParamTrigger } from '../hooks/useQueryParamTrigger'
import { CenteredCircularProgress } from '../components/progress/CenteredCircularProgress'
import { useValidateUser } from '../hooks/useValidateUser'
import { useEnforceOnboarding } from '../hooks/useEnforceOnboarding'
import { useGeneralBlockingError } from '../hooks/useGeneralBlockingError'
import { Header } from '../containers/header/Header'
import { useIntercom } from '../hooks/useIntercom'
import { useSetupUserflow } from '../userflow/setupUserflow'
import { useLoadEssentialResources } from '../hooks/useLoadEssentialResources'
import { RetryLoadingEssentialResources } from '../containers/dashboard/mainDisplay/RetryLoadingEssentialResources'
import { useRightClick } from '../hooks/useRightClick'
import { useRoutedModals } from '../hooks/useRoutedModals'
import * as React from 'react'
import Grid from '@mui/material/Grid'
import styled from '@mui/system/styled'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorBoundary from '@/components/ErrorBoundary'

const MainWrapper = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: '"toolbar" "body"',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',
  minHeight: '0',
  overflow: 'hidden',
  width: '100%',
  paddingTop: '50px',
  height: '100vh',
  background: theme.palette.common.white
}))

const LazySpriteMap = React.lazy(() => import('@/assets/SpriteMap'))

export const ProtectedLayout = ({ children }: { children?: React.ReactNode }): React.ReactElement => {
  const { isLoggedIn } = useValidateUser()
  const { isLoading: isLoadingEssentialResources, data: essentialResourcesLoaded } = useLoadEssentialResources()

  if (!isLoggedIn || isLoadingEssentialResources) {
    return null
  }

  if (!essentialResourcesLoaded) {
    return <RetryLoadingEssentialResources />
  }

  return <Layout>{children}</Layout>
}

const Layout = ({ children }: { children?: React.ReactNode }): React.ReactElement => {
  const { t } = useTranslation()

  useSetupUserflow()
  useQueryParamTrigger()
  useEnforceOnboarding()
  useIntercom()
  useRightClick()
  useRoutedModals()

  const isBlockingError = useGeneralBlockingError()

  return (
    <ErrorBoundary t={t}>
      <GlobalModal />
      <Suspense>
        <LazySpriteMap />
      </Suspense>
      <CenteredCircularProgress />
      <Header />
      <MainWrapper>
        <ErrorBoundary t={t}>
          <Grid container sx={{ overflow: 'hidden', height: 'calc(100vh - 50px)' }}>
            {isBlockingError ? (
              <Grid item flexGrow={1}>
                <GeneralBlockingError />
              </Grid>
            ) : (
              <React.Fragment>
                <LeftSidebarWrapper />
                <Grid
                  item
                  xs
                  sm
                  md
                  lg
                  xl
                  sx={{
                    maxHeight: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '0'
                  }}
                  className="main-display"
                >
                  <Toolbar />
                  <Grid container sx={{ overflow: 'hidden', height: '100%' }}>
                    {children}
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </ErrorBoundary>
      </MainWrapper>
      <GlobalSnackbar />
    </ErrorBoundary>
  )
}
