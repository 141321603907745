import { SortingDirection, SortingModeV2 } from '../../../uiSlice/interfaces'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export interface ProjectPermissions {
  canPreviewProject: boolean
  canReadProject: boolean
  canCopyProject: boolean
  canWriteProject: boolean
  canExecuteProjectBillableAction: boolean
  canManageProject: boolean
  canMoveProjectToPersonalSpace: boolean
  canListProjectPermissions: boolean
  canEditProjectPermissions: boolean
  canShareProjectWithSpaceMembers: boolean
  canShareProjectWithOrganizationMembers: boolean
  canShareProjectWithAnyone: boolean
  canMakeProjectPublic: boolean
  canDeleteProject: boolean
}

export interface LegacyProjectPermissions {
  readPreview: boolean
  read: boolean
  write: boolean
  billableAction: boolean
  getCopy: boolean
  manage?: boolean
  unlisted?: boolean
}

export interface Project {
  resourceType: 'PROJECT'
  projectIdExt: string
  parentFolderId: string
  spaceId: string
  name: string
  projectName: string
  publicProjectName: string
  ownerName: string
  createdAt: string
  lastModifiedAt: string
  thumbnailUrl: string
  isShared: boolean
  requesterPermissions: ProjectPermissions
  publicPermission: LegacyProjectPermissions
}

export interface FullProject {
  [x: string]: {}
  project: {}
  projectIdExt: string
  spaceId: string
  parentFolderId: string
  projectName: string
  publicProjectName: string
  ownerName: string
  description: string
  numberOfLikes: number
  numberOfViews: number
  numberOfCopies: number
  creationDate: string
  lastModificationDate: string
  measurementSystem: 'SI' | 'US'
  resultIds: string[]
  categories: string[]
  analysisTypes: string[]
  cadTypes: string[]
  tags: string[]
  requesterPermissions: ProjectPermissions
  publicPermission: LegacyProjectPermissions
  numberOfGeometries: number
  numberOfMeshes: number
  numberOfSimulations: number
  numberOfSimulationRuns: number
  thumbnailUrl: string
  isLikedByRequester: boolean
  isShared: boolean
  isSharedWithRequester: boolean
  isSharedWithSupport: boolean
  shareWithSupportExpiresAt: string
  parentProject: FullProject
  deleted: boolean
  noIndex: boolean
  CanonicalProjectOwner: string
  CanonicalPublicProjectName: string
}

export enum ProjectScopes {
  ALL_PROJECTS = 'all_projects',
  PROJECTS_SHARED_WITH_ME = 'projects_shared_with_me',
  MY_PROJECTS = 'my_projects'
}

export type ProjectScope =
  | ProjectScopes.ALL_PROJECTS
  | ProjectScopes.PROJECTS_SHARED_WITH_ME
  | ProjectScopes.MY_PROJECTS

export interface PagingMetadata {
  page: number
  pageSize: number
  totalPages: number
  totalItems: number
}

export interface CreateProjectPayload {
  spaceId: string
  parentFolderId: string
  projectName: string
  description: string
  categories: string[]
  measurementSystem: string
  tags: string[]
  publicPermission: LegacyProjectPermissions
}

export interface GetProjectsReturnType {
  items: Project[]
  pagingMetadata: PagingMetadata
}

export type SearchedProjectsResponse = {
  numberOfProjects: number
  projects: FullProject[]
  totalNumberOfProjectsOwnedByUser: number
  username: string
}

export type SearchedProjectsReturnType = {
  numberOfProjects: number
  projects: Project[]
  totalNumberOfProjectsOwnedByUser: number
  username: string
}

export interface GetProjectsProps {
  spaceId: string
  folderId: string
  sortingModeV2: SortingModeV2
  sortingDirection: SortingDirection
  targetPage: number
  projectsPerPage?: number
}

export interface SearchProjectsProps {
  username: string
  sortingModeV2: SortingModeV2
  sortingDirection: SortingDirection
  targetPage: number
  scope: ProjectScope
  query?: string
  filterby?: string
  spaceId?: string
  offset?: number
  projectsPerPage?: number
}

export interface ProjectResult<T> {
  data: T
  error: FetchBaseQueryError | SerializedError
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
}

export interface ProjectTag {
  name: string
  popular: boolean
  count: number
}

export type SimulationType = {
  name: string
  runs: SimulationRun[]
  simulation: number
  specId: string
}

interface RunContinuationInfo {
  isContinuable: boolean
  continuationExpiresAt: string
  reasonForNotContinuable: string
}

export enum RunningStatus {
  NOT_READY = 'NOT_READY',
  READY = 'READY',
  STARTED = 'STARTED',
  PAUSED = 'PAUSED',
  PREPARING = 'PREPARING',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  FINISHING_UP = 'FINISHING_UP',
  ERROR_FINISHING_UP = 'ERROR_FINISHING_UP',
  CANCELED_FINISHING_UP = 'CANCELED_FINISHING_UP',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
  CANCELING = 'CANCELING',
  CANCELED = 'CANCELED',
  ESTIMATION = 'ESTIMATION'
}

export interface SimulationRun {
  run: number
  name: string
  runningStatus: RunningStatus
  specId: string
  relatedResultUuid: string
  inputMeshResultUuid: string
  createdAt?: string
  finishedAt?: string
  duration?: string
  progress?: number
  runContinuationInfo?: RunContinuationInfo
  subRuns?: SimulationRun[]
  isLocked?: boolean
}
