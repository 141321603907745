import { UserAvatarPicture } from '../../user/UserAvatarPicture'
import { FullProject } from '../../../store/features/apis/slices/project/interfaces'
import useStateIfMounted from '../../../hooks/useStateIfMounted'
import { useGetProjectV1Details } from '../../../store/features/apis/slices/project/helpers'
import { isInsideCypress } from '../../../helpers/cookies'
import * as React from 'react'
import { styled } from '@mui/system'
import { Box, CircularProgress, Link, Stack, Typography } from '@mui/material'
import intlFormatDistance from 'date-fns/intlFormatDistance'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

const UserDataStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
})

const ActionHistoryStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3)
}))

const ActionsStyled = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  gridGap: theme.spacing(3)
}))

interface ActionHistoryDataProps {
  userName: string
  modifiedAt: Date
  textContent: string | React.ReactNode
}

const ActionHistory = ({ userName, modifiedAt, textContent }: ActionHistoryDataProps): React.ReactElement => {
  const timeAgo = intlFormatDistance(modifiedAt, new Date())

  return (
    <ActionHistoryStyled qa-attribute="action-history">
      <UserAvatarPicture userName={userName} qa-attribute="action-history-avatar" />
      <UserDataStyled qa-attribute="action-history-user-data">
        <Typography
          variant="textBoldWithEllipsis"
          color="text.primary"
          sx={{ mb: 1 }}
          qa-attribute="action-history-username"
        >
          {userName}
          <Typography
            variant="text"
            color="text.primary"
            sx={{ whiteSpace: 'initial', ml: 1 }}
            qa-attribute="action-history-text"
          >
            {textContent}
          </Typography>
        </Typography>
        <Typography variant="textLight" color="grey.700" qa-attribute="action-history-time-ago">
          {timeAgo}
        </Typography>
      </UserDataStyled>
    </ActionHistoryStyled>
  )
}

interface ActionsHistoryProps {
  project: FullProject
}

export const ActionsHistory = ({ project }: ActionsHistoryProps): React.ReactElement => {
  const { t } = useTranslation('detailsPanel')
  const [actionsHistoryData, setActionsHistoryData] = useStateIfMounted<ActionHistoryDataProps[]>([])

  const currentProjectSlug = `${project.ownerName}/${project.publicProjectName}`
  const parentProjectSlug = `${project.parentProject?.ownerName}/${project.parentProject?.publicProjectName}`

  const {
    data: parentProject,
    isLoading,
    isSuccess
  } = useGetProjectV1Details(
    { projectSlug: parentProjectSlug },
    {
      skip:
        isNil(project) ||
        (isNil(project.parentProject?.ownerName) && isNil(project.parentProject?.publicProjectName)) ||
        currentProjectSlug === parentProjectSlug
    }
  )

  React.useEffect(() => {
    setActionsHistoryData([])
  }, [project])

  React.useEffect(() => {
    const historyData: ActionHistoryDataProps[] = []
    if (!isLoading && isSuccess) {
      const parentProjectUrl = `/projects/${parentProject.ownerName}/${parentProject.publicProjectName}`
      historyData.push({
        userName: parentProject.ownerName,
        modifiedAt: new Date(`${parentProject.creationDate} UTC`),
        textContent: (
          <Link
            href={parentProjectUrl}
            underline="none"
            target={isInsideCypress() ? '_self' : '_blank'}
            rel="noreferrer"
          >
            {t('createdOriginalProject')}
          </Link>
        )
      })
    }
    historyData.push({
      userName: project.ownerName,
      modifiedAt: new Date(`${project.creationDate} UTC`),
      textContent: historyData.length > 0 ? t('copiedThisProject') : t('createdThisProject')
    })
    setActionsHistoryData(historyData)
  }, [project, parentProject, isLoading, isSuccess])

  return isNil(project) || isLoading ? (
    <Stack alignItems="center" style={{ marginTop: 50 }}>
      <CircularProgress />
    </Stack>
  ) : (
    <ActionsStyled>
      {actionsHistoryData?.map((actionData) => (
        <ActionHistory key={`${actionData.userName}-${actionData.modifiedAt}`} {...actionData} />
      ))}
    </ActionsStyled>
  )
}
