import { useGetPathPermission, usePermittedSpaces } from './hooks'
import { FolderSelectorNavigationTree } from '../moveProject/NavigationTreePathSelector'
import * as React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { Button } from '@/components/button/Button'
import { ModalTemplateProps } from '@/components/modal/interfaces'
import { ModalActions, ModalContent, ModalTitle } from '@/components/modal/Modal'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { setSelectedPath } from '@/store/features/uiSlice/uiSlice'
import { PathSelector } from '@/containers/spaces/interfaces'
import { usePersonalSpaceId } from '@/store/features/apis/slices/space/hooks'

type SelectLocationTemplateType = ModalTemplateProps & {
  title: string
}

export const SelectLocationTemplate = ({
  onClose,
  showCloseButton,
  title
}: SelectLocationTemplateType): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const { fullPath } = useAppSelector((state) => state.ui)
  const [selected, setSelected] = React.useState<PathSelector>(fullPath)
  const personalSpaceId = usePersonalSpaceId()
  const { permittedSpaces } = usePermittedSpaces()
  const { hasPermission, isLoading: isLoadingPermission } = useGetPathPermission({ path: fullPath })

  const handleOnSelectConfirm = React.useCallback(() => {
    dispatch(setSelectedPath(selected))
    onClose()
  }, [selected])

  const handleOnSelect = React.useCallback(
    (path: PathSelector) => {
      setSelected(path)
    },
    [selected]
  )

  React.useEffect(() => {
    if (!isLoadingPermission && !hasPermission) {
      setSelected([personalSpaceId])
    }
  }, [isLoadingPermission, hasPermission, personalSpaceId])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent>
        <Box minHeight={160} className="u-overflowOverlay">
          {!isEmpty(permittedSpaces) ? (
            permittedSpaces.map((space) => (
              <FolderSelectorNavigationTree
                key={space.spaceId}
                isSingleSpace={permittedSpaces.length === 1}
                path={[space.spaceId]}
                label={space.name}
                selectedPath={selected}
                setSelectedPath={handleOnSelect}
                expandedPath={fullPath}
                disabled={false}
              />
            ))
          ) : (
            <Stack direction="column" alignItems="center" justifyContent="center" minHeight={160}>
              <Typography textAlign="center">{t('copyProject.noAvailableLocations')}</Typography>
            </Stack>
          )}
        </Box>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('cancel')}
        </Button>

        <Button
          onClick={handleOnSelectConfirm}
          color="primary"
          variant="contained"
          disabled={isEmpty(permittedSpaces) || isLoadingPermission}
        >
          {t('select')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}
