import { BreadcrumbsItem } from '../../../components/breadcrumb/BreadcrumbsItem'
import { BreadcrumbsRow } from '../../../components/breadcrumb/BreadcrumbsRow'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from '@/store/hooks'
import { AiSectionPathValue } from '@/store/features/uiSlice/interfaces'
import { AiSectionPaths } from '@/constants'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'

export const AiModelTrainingBreadcrumbs = (): React.ReactElement => {
  const { t } = useTranslation('aiTraining')
  const aiSectionPath = useAppSelector((state) => state.ui.aiSectionPath)
  const id = React.useId()
  const { navigate } = useDashboardNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const onElementSelect = React.useCallback(
    (path: AiSectionPathValue) => {
      const { value } = path
      switch (value) {
        case AiSectionPaths.dataSelection.value:
          if (!searchParams.has(AiSectionPaths.dataSelection.value)) {
            setSearchParams({ page: AiSectionPaths.dataSelection.value }, { replace: true })
          }
          return
        case AiSectionPaths.aiModelTraining.value:
          navigate(`/${value}`)
          return
        default:
          navigate(`/models/${value}`)
          return
      }
    },
    [searchParams]
  )

  return (
    <BreadcrumbsRow>
      {aiSectionPath?.map((path, index) => {
        return (
          <BreadcrumbsItem
            key={`${path.label}-${id}`}
            path={path}
            label={index === 1 ? path.label : t(path.label)}
            isLastElement={aiSectionPath.length === index + 1}
            onElementSelect={onElementSelect}
            style={{ cursor: 'pointer' }}
          />
        )
      })}
    </BreadcrumbsRow>
  )
}
