import { isEmpty } from 'lodash'
import { PermissionAccess } from '@/permissions/interfaces'
import { useGetAnalyticsDashboardsQuery } from '@/store/features/apis/slices/analyticsDashboard/analyticsDashboardSlice'
import { useGetSettingsMapQuery } from '@/store/features/apis/slices/user/userSlice'

export const useMetabaseAnalyticsEnabled = (): PermissionAccess => {
  const { data: userSettings, isLoading: isUserSettingsLoading } = useGetSettingsMapQuery()
  const { data: boardIds, isLoading: isBoardIdsLoading } = useGetAnalyticsDashboardsQuery()

  const areBoardsAvailable = !isEmpty(boardIds)

  if (isBoardIdsLoading || !areBoardsAvailable || isUserSettingsLoading || !userSettings) {
    return [true, undefined]
  }

  return [false, areBoardsAvailable && userSettings.enable_metabase_analytics === 'true']
}
