import { useFirstSelectedItemName } from './hooks'
import { MoveItem } from './interfaces'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useDispatch } from 'react-redux'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@/store/features/apis/slices/space/interfaces'
import { Folder } from '@/store/features/apis/slices/folder/interfaces'

export const useMoveToasts = (
  items: MoveItem[],
  isSpace: boolean,
  selectedFolder: Folder,
  selectedSpace: Space
): [() => void, () => void] => {
  const dispatch = useDispatch()
  const firstSelectedItemName = useFirstSelectedItemName(items)
  const { t } = useTranslation(['modals', 'tooltips'])
  const destinationName = isSpace ? selectedSpace?.name : selectedFolder?.name

  const showSuccessfulToast = React.useCallback((): void => {
    if (items.length === 1) {
      dispatch(
        addSnackbarToState({
          severity: 'info',
          message: t('move.successfulMove', {
            itemName: firstSelectedItemName,
            destinationName
          })
        })
      )
    } else {
      dispatch(
        addSnackbarToState({
          severity: 'info',
          message: t('move.successfulMultiselectionMove', {
            itemQuantity: items.length,
            destinationName
          })
        })
      )
    }
  }, [firstSelectedItemName, destinationName, items.length, dispatch])

  const showErrorToast = React.useCallback((): void => {
    dispatch(
      addSnackbarToState({
        severity: 'error',
        message: t('move.failedMove')
      })
    )
  }, [dispatch])

  return [showSuccessfulToast, showErrorToast]
}
