export interface ValidationRules {
  [key: string]: ValidationItemProps[]
}

export const isValidInput: ValidationItemProps = {
  // regex from https://gitlab.com/simscale-gmbh/backend/service-commons/-/blob/master/validation/src/main/java/com/simscale/service/commons/validation/UserInputTextValidator.java
  // same regex used on projectName and description https://gitlab.com/simscale-gmbh/backend/project-metadata/-/blob/master/src/main/lombok/com/simscale/service/project/dto/ProjectInfo.java#L49
  regexp: new RegExp(
    "^[öÖäÄüÜßéáíóúñÑșȘțȚăĂîÎâÂæÆøØåÅçÀàÈèÌìÒòÙù\\s\\h\\v\\ufeff\\w,\\.@:\\+\\-\\!\\.\\'\\`\\'\\*\\(\\)&/]+$",
    'g'
  ),
  message: { key: 'messages.invalidChar' }
}

export const isValidTagInput: ValidationItemProps = {
  regexp: new RegExp(/^[a-zA-Z0-9-\s]+$/, 'g'),
  message: { key: 'messages.invalidTagChar' }
}

export const isRequired: ValidationItemProps = {
  regexp: new RegExp('.+', 'g'),
  message: { key: 'messages.isRequired' }
}

export const isNotEmpty: ValidationItemProps = {
  regexp: new RegExp('^(?!\\s*$).+', 'gm'),
  message: { key: 'messages.isNotEmpty' },
  isNullable: true
}

export const itDoesNotStartWithEmptySpace = {
  regexp: new RegExp('^(?!\\s)', 'g'),
  message: { key: 'messages.itDoesNotStartWithEmptySpace' },
  isNullable: true
}

export const isValidRequiredNumberOfChars = (numberOfChars: number): ValidationItemProps => ({
  regexp: new RegExp(`^[\\w\\W]{${numberOfChars},}$`, 'g'),
  message: { key: 'messages.minChars', options: { numberOfChars } }
})

export const isValidMaximumNumberOfChars = (numberOfChars: number): ValidationItemProps => ({
  regexp: new RegExp(`^(?=.{1,${numberOfChars}}$).*`, 'gs'),
  message: { key: 'messages.maxChars', options: { numberOfChars } }
})

export const isValidEmail: ValidationItemProps = {
  regexp: new RegExp('^\\S+@\\S+\\.\\S+$', 'g'),
  message: { key: 'messages.invalidEmail' }
}

export const isValidNullableInput: ValidationItemProps = {
  ...isValidInput,
  isNullable: true
}

export const isValidDomain: ValidationItemProps = {
  regexp: new RegExp('^[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,6}$', 'i'),
  message: { key: 'messages.invalidDomain' }
}

export const isValidPassword: ValidationItemProps = {
  regexp: new RegExp('^.{10,64}$', 'g'),
  message: { key: 'messages.passwordSize', options: { min: 10, max: 64 } }
}

interface TranslatableMessage {
  key: string
  options?: any
}

export interface ValidationItemProps {
  regexp: RegExp
  message: TranslatableMessage
  isNullable?: boolean
}

export interface ValidationResultProps {
  valid: boolean
  message: string
}
