import { createStackTrace } from './stackTraceUtils'
import {
  WARNING_MESSAGES,
  generateReactRouterErrorMessage,
  generateReactRouterErrorStackTrace,
  getSeverityFromKibanaPayloadToLogPayload,
  getSeverityFromLogPayloadToKibanaPayload,
  isReactRouterErrorArray,
  isValidTraceId,
  makeUniqueId
} from './utils'
import { ConsoleLogMethod, KibanaPayload, KibanaSeverity } from './interfaces'
import { LOGGER_NAME, SERVICE_NAME } from './constants'
import { isEmpty, isNil } from 'lodash'
import { TrackJS } from 'trackjs'
import { getCookie, getUserId } from '@/helpers/cookies'
import { utf8ToBase64 } from '@/helpers/network'

type ProjectApiLogData = Omit<KibanaPayload, 'severity'> & {
  appVersion?: string
  relatedTraceId?: string
  clientUrl?: string
  timestamp?: string
  severity?: ConsoleLogMethod
}

const sendLog = (data: KibanaPayload): void => {
  // ENG-70729 temporary fix to work with /projects/api/log/ endpoint until we find a better solution for the dashboard logging
  const projectApiLogData: ProjectApiLogData[] = [
    {
      ...data,
      appVersion: data.app_version,
      relatedTraceId: data.TRACE,
      clientUrl: data.client_url,
      timestamp: data['@timestamp'],
      severity: getSeverityFromKibanaPayloadToLogPayload(data.severity)
    }
  ]

  const { uid, sid } = getCookie()

  try {
    fetch('/projects/api/log/', {
      method: 'POST',
      body: JSON.stringify(projectApiLogData),
      headers: {
        'content-type': 'application/json',
        'user-agent': data.user_agent,
        authorization: `Basic ${utf8ToBase64(`${uid}:${sid}`)}`
      }
    })
  } catch (error) {}
}

const extensionStackRegex = /-extension:|https:\/\/[a-z]+\.simscale\.com\/[a-zA-Z\d_]+:\d+:\d+/

export const hijackWindowConsole = (): void => {
  const { log, error, warn, debug, info } = window.console
  const hijackedMethods = { log, error, warn, debug, info }
  // store window console's original methods, in case we want to also log to browser console

  const prepareAndSendLogData = (logMethod: ConsoleLogMethod, logEntryData: any): void => {
    let message: string
    if (typeof logEntryData === 'string') {
      message = logEntryData
    } else if (typeof logEntryData === 'object' && logEntryData?.message) {
      message = logEntryData.message
    } else {
      message = JSON.stringify(logEntryData)
    }

    let traceId = makeUniqueId()
    let spanId = makeUniqueId()
    let stack_trace: string
    if (typeof logEntryData === 'object') {
      traceId = isValidTraceId(logEntryData?.TRACE) ? logEntryData?.TRACE : traceId
      traceId = isValidTraceId(logEntryData?.traceId) ? logEntryData?.traceId : traceId
      spanId = isValidTraceId(logEntryData?.SPAN) ? logEntryData?.SPAN : spanId
      spanId = isValidTraceId(logEntryData?.spanId) ? logEntryData?.spanId : spanId
      stack_trace = JSON.stringify(logEntryData?.stack_trace)
    }
    stack_trace = stack_trace ?? createStackTrace()
    message = message ?? stack_trace?.split('\n')[0]

    if (isNil(message) || isEmpty(message)) {
      message = 'No message or stack trace provided. Requires further investigation.'
    }

    const logPayload: KibanaPayload = {
      message,
      SPAN: spanId,
      TRACE: traceId,
      severity: getSeverityFromLogPayloadToKibanaPayload(logMethod),
      logger: LOGGER_NAME,
      service: SERVICE_NAME,
      stack_trace,
      user_agent: navigator.userAgent,
      USER: getUserId() ?? '',
      client_url: window.location.href,
      '@timestamp': new Date().toISOString(),
      app_version: process.env.APP_VERSION
    }

    if (
      logPayload.severity === KibanaSeverity.ERROR &&
      WARNING_MESSAGES.some(
        (warningMessage) => typeof logPayload.message === 'string' && logPayload.message.includes(warningMessage)
      )
    ) {
      logPayload.severity = KibanaSeverity.WARNING
      logPayload.message = `Demoted error to warning: ${logPayload.message}`
    }

    if (logPayload.severity === KibanaSeverity.ERROR && !isNil(stack_trace) && extensionStackRegex.test(stack_trace)) {
      logPayload.severity = KibanaSeverity.WARNING
      logPayload.message = `Demoted error to warning, extension stack trace identified. ${logPayload.message}`
    }

    sendLog(logPayload)
  }

  const createLoggingHijacker = (logMethod: ConsoleLogMethod) => {
    return (...args: any) => {
      if (TrackJS.isInstalled()) {
        TrackJS.console[logMethod](...args)
      }

      for (const logEntry of args) {
        if (Array.isArray(logEntry)) {
          if (isReactRouterErrorArray(logEntry)) {
            prepareAndSendLogData(logMethod, {
              message: generateReactRouterErrorMessage(logEntry),
              stack_trace: generateReactRouterErrorStackTrace(logEntry)
            })
          } else {
            for (const logEntryItem of logEntry) {
              prepareAndSendLogData(logMethod, logEntryItem)
            }
          }
        } else {
          prepareAndSendLogData(logMethod, logEntry)
        }
      }

      hijackedMethods[logMethod](...args)
    }
  }

  // hijack window console methods
  window.console.log = createLoggingHijacker('info')
  for (const logMethod of ['info', 'error', 'debug', 'warn']) {
    window.console[logMethod] = createLoggingHijacker(logMethod as ConsoleLogMethod)
  }
}
