import { first, isEmpty } from 'lodash'
import Skeleton from '@mui/material/Skeleton'
import { Grid, Stack } from '@mui/material'
import AnalyticsAreaDisplay from '@/containers/dashboard/analyticsArea/AnalyticsAreaDisplay'
import { MainDisplay } from '@/containers/dashboard/MainDisplay'
import { AnalyticsAreaDetail } from '@/containers/dashboard/analyticsArea/AnalyticsAreaDetail'
import { useGetAnalyticsDashboardsQuery } from '@/store/features/apis/slices/analyticsDashboard/analyticsDashboardSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'

export const AnalyticsArea = (): React.ReactElement => {
  const { data: dashboards, isLoading: isBoardIdsLoading } = useGetAnalyticsDashboardsQuery()
  const { handleNotFound } = useErrorHandler()

  if (isBoardIdsLoading) {
    return <AnalyticsAreaSkeleton />
  }

  if (isEmpty(dashboards)) {
    handleNotFound()
  }

  if (dashboards.length === 1) {
    return <AnalyticsAreaDetail dashboardId={first(dashboards)?.id} />
  }

  return (
    <MainDisplay>
      <AnalyticsAreaDisplay dashboards={dashboards} />
    </MainDisplay>
  )
}

const AnalyticsAreaSkeleton = (): React.ReactElement => {
  return (
    <Stack direction="column" sx={{ height: '100%', width: '100%', paddingTop: '58px' }}>
      <Stack direction="column" sx={{ m: 4 }}>
        <Grid
          container
          spacing={2}
          sx={{
            height: 'auto',
            width: '100%',
            mx: 'auto',
            mt: 2.5,
            mb: 4
          }}
        >
          <Grid item xs={6} sm={6}>
            <Skeleton variant="rectangular" height={58} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Skeleton variant="rectangular" height={58} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Skeleton variant="rectangular" height={58} />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}
