import * as React from 'react'
import { styled } from '@mui/system'
import { Box, Link, Typography } from '@mui/material'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyledTooltip } from '@/components/tooltip/StyledTooltip'

const iconGeometry = require('../../../assets/svg/geometry-icon.svg')
const iconMesh = require('../../../assets/svg/mesh-icon.svg')
const iconSimulation = require('../../../assets/svg/simulation-icon.svg')
const iconSimulationRun = require('../../../assets/svg/simulation-run-icon.svg')

const DetailsListContainerStyled = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5)
}))

const DetailsListStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: theme.spacing(1)
}))

const DetailsRowStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridGap: theme.spacing(2)
}))

const DetailsCellStyled = styled(Box)({
  width: '50%'
})

const DetailsStatisticsStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
  columnGap: theme.spacing(2)
}))

const DetailsStatisticsRowStyled = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: 'calc((100% - 8px) / 2)'
})

interface DetailsStatisticsImageStyledProps {
  imageUrl: string
}

const DetailsStatisticsImageStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'imageUrl'
})<DetailsStatisticsImageStyledProps>(({ imageUrl }) => ({
  flexShrink: '0',
  width: '16px',
  height: '16px',
  marginTop: '2px',
  backgroundImage: `url(${imageUrl})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
}))

const DetailsStatisticsValueStyled = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1)
}))

export enum ResourceType {
  FOLDER = 'FOLDER',
  PROJECT = 'PROJECT'
}

export interface DetailListDataProps {
  name: string
  value: number | string | StatisticsDataProps | Element
  href?: string
}

interface StatisticsDataProps {
  geometry: number
  mesh: number
  simulation: number
  simulationRun: number
}

interface DetailsListProps {
  resourceType: ResourceType
  detailsListData: DetailListDataProps[]
}

const DETAIL_STATISTICS_IMAGE_MAP = {
  geometry: iconGeometry,
  mesh: iconMesh,
  simulation: iconSimulation,
  simulationRun: iconSimulationRun
}

interface DetailsComponentProps {
  detailsListData: DetailListDataProps[]
}

const FolderDetailsList = ({ detailsListData }: DetailsComponentProps): React.ReactElement => {
  const { t } = useTranslation('detailsPanel')
  return (
    <DetailsListContainerStyled qa-attribute="folder-details-list">
      <Typography variant="title" color="text.primary" paragraph sx={{ mb: 3 }}>
        {t('folderDetails')}
      </Typography>
      <DetailsListStyled>
        {!isEmpty(detailsListData) &&
          detailsListData.map((rowData) => (
            <DetailsRowStyled key={rowData.name}>
              <DetailsCellStyled>
                <Typography variant="text" color="text.primary">
                  {rowData.name}
                </Typography>
              </DetailsCellStyled>
              <DetailsCellStyled>
                <Typography variant="text" color="text.primary">
                  {rowData.value as number}
                </Typography>
              </DetailsCellStyled>
            </DetailsRowStyled>
          ))}
      </DetailsListStyled>
    </DetailsListContainerStyled>
  )
}

const ProjectDetailsList = ({ detailsListData }: DetailsComponentProps): React.ReactElement => {
  const { t } = useTranslation('detailsPanel')
  const getStatisticElements = (values: StatisticsDataProps): React.ReactElement[] => {
    return Object.entries(values).map(([key, value]) => {
      return (
        <StyledTooltip key={key} title={t(value === 1 ? key : key + '+')}>
          <DetailsStatisticsRowStyled key={key}>
            <DetailsStatisticsImageStyled imageUrl={DETAIL_STATISTICS_IMAGE_MAP[key]} />
            <DetailsStatisticsValueStyled>
              <Typography variant="text" color="text.primary">
                {value}
              </Typography>
            </DetailsStatisticsValueStyled>
          </DetailsStatisticsRowStyled>
        </StyledTooltip>
      )
    })
  }

  const getDetailCellValues = (rowData: DetailListDataProps): React.ReactElement => {
    if (!isNil(rowData.href) && typeof rowData.value !== 'object') {
      return (
        <Link underline="none" href={rowData.href}>
          {rowData.value}
        </Link>
      )
    }
    if (typeof rowData.value === 'object') {
      return (
        <DetailsStatisticsStyled>{getStatisticElements(rowData.value as StatisticsDataProps)}</DetailsStatisticsStyled>
      )
    }
    return (
      <Typography variant="text" color="text.primary">
        {rowData.value as number}
      </Typography>
    )
  }

  return (
    <DetailsListContainerStyled qa-attribute="project-details-list">
      <Typography variant="title" color="text.primary" paragraph sx={{ mb: 3 }}>
        {t('projectDetails')}
      </Typography>
      <DetailsListStyled>
        {!isEmpty(detailsListData) &&
          detailsListData.map((rowData: DetailListDataProps) => {
            return (
              <DetailsRowStyled key={rowData.name}>
                <DetailsCellStyled>
                  <Typography variant="text" color="text.primary">
                    {rowData.name}
                  </Typography>
                </DetailsCellStyled>
                <DetailsCellStyled>{getDetailCellValues(rowData)}</DetailsCellStyled>
              </DetailsRowStyled>
            )
          })}
      </DetailsListStyled>
    </DetailsListContainerStyled>
  )
}

export const DetailsList = ({ resourceType, detailsListData }: DetailsListProps): React.ReactElement => {
  switch (resourceType) {
    case ResourceType.FOLDER:
      return <FolderDetailsList detailsListData={detailsListData} />
    case ResourceType.PROJECT:
      return <ProjectDetailsList detailsListData={detailsListData} />
  }
}
