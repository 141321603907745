import { MoveItemModalProps } from './interfaces'
import { CancelButton, ConfirmButton, CreateFolder } from './Buttons'
import { getMoveElementPayload } from './helpers'
import { useParentInformation } from './hooks'
import { useMoveToasts } from './toasts'
import { AvailableSpacesSelector } from './AvailableSpacesSelector'
import { PathSelector } from '../../../../containers/spaces/interfaces'
import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { pathToRoute } from '../../../../helpers/router'
import { first, isNil, last } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { setCurrentFolderPage, setCurrentProjectPage } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useMoveItemMutation } from '@/store/features/apis/slices/genericItem/genericItemSlice'
import { useGetFolderDetails, useGetSpaceDetails } from '@/store/features/apis/slices/folder/helpers'
import { useIsSearchMode } from '@/hooks/useIsSearchMode'
import { useCreateFolderOperation } from '@/operations/folder/createFolderOperation'

export const MoveItemModalTemplate = ({
  onClose,
  showCloseButton,
  title,
  items
}: MoveItemModalProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const isSearchMode = useIsSearchMode()
  const fullPath = useAppSelector((state) => state.ui.fullPath)

  const [moveElement, { isLoading }] = useMoveItemMutation()
  const { t } = useTranslation(['modals', 'tooltips'])

  const { parentSpaceId, parentFolderId } = useParentInformation(items)

  const [createFolder, isCreateFolderAllowed, isCreateFolderLoading] = useCreateFolderOperation()

  const [targetPath, setTargetPath] = React.useState<PathSelector>([parentSpaceId, parentFolderId].filter(Boolean))

  // We can use the current full path to automatically expanded the path until the selected folder only if the user is not in search mode
  const initialPath = React.useRef(!isSearchMode ? fullPath : undefined)

  const isSpace = first(targetPath) === last(targetPath)

  const { data: selectedFolder, isLoading: isFolderLoading } = useGetFolderDetails(
    {
      spaceId: first(targetPath),
      folderId: last(targetPath)
    },
    { skip: isSpace }
  )

  const { data: selectedSpace, isLoading: isSpaceLoading } = useGetSpaceDetails(
    { spaceId: first(targetPath) },
    { skip: !isSpace }
  )

  const [showSuccessfulToast, showErrorToast] = useMoveToasts(items, isSpace, selectedFolder, selectedSpace)

  const onMoveClick = async (): Promise<void> => {
    moveElement({
      spaceId: parentSpaceId,
      folderId: parentFolderId,
      payload: getMoveElementPayload(items, targetPath)
    })
      .unwrap()
      .then(() => {
        dispatch(setCurrentFolderPage(undefined))
        dispatch(setCurrentProjectPage(undefined))
        onClose(pathToRoute(targetPath))
        showSuccessfulToast()
      })
      .catch(() => {
        showErrorToast()
        onClose()
      })
  }

  const isMovingToCurrentLocation =
    last(targetPath) === parentFolderId || (last(targetPath) === parentSpaceId && isNil(parentFolderId))
  const isMoveLoading = isLoading || isFolderLoading || isSpaceLoading
  const isMoveDisabled = isMoveLoading || isNil(targetPath) || isMovingToCurrentLocation

  return (
    <>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>

      <ModalContent onSubmit={onMoveClick} submitDisabled={isMoveDisabled}>
        <AvailableSpacesSelector
          parentId={parentFolderId ?? parentSpaceId}
          setTargetPath={(newPath: PathSelector): void => {
            initialPath.current = undefined
            setTargetPath(newPath)
          }}
          targetPath={targetPath}
          items={items}
          expandedPath={initialPath.current ?? targetPath} // Expand until the selected path, if it's not, open the current forlder path}
        />
      </ModalContent>

      <ModalActions>
        <CreateFolder
          disabled={!isCreateFolderAllowed || isLoading || isCreateFolderLoading}
          onClick={(): void => {
            createFolder({
              pathSelector: targetPath,
              onFolderCreated: (newFolderId: string): void => {
                setTargetPath((prevTargetPath) => [...prevTargetPath, newFolderId])
              }
            })
          }}
        />
        <CancelButton onClick={onClose} disabled={isLoading} label={t('cancel')} />
        <ConfirmButton
          onClick={onMoveClick}
          disabled={isMoveDisabled}
          isLoading={isMoveLoading}
          label={t('move.move')}
        />
      </ModalActions>
    </>
  )
}
