import { AnalyticsBoard } from './interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { getUserId } from '@/helpers/cookies'

const analyticsDashboardSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalyticsDashboards: builder.query<AnalyticsBoard[], void>({
      query: () => {
        const uidExt = getUserId()
        return `api/v1/analytics/user/${uidExt}/boards`
      }
    }),
    getMetabaseSignedUrl: builder.query<string, string>({
      query: (dashboardUuid) => {
        return `/api/v1/analytics/${dashboardUuid}/signed-url`
      },
      transformResponse: (response: { embedUrl: string }) => {
        return response.embedUrl
      }
    })
  })
})

export const { useGetAnalyticsDashboardsQuery, useGetMetabaseSignedUrlQuery } = analyticsDashboardSlice
