import { SharingControl } from '../../../../../store/features/apis/slices/space/interfaces'
import { addSnackbarToState } from '../../../../../store/features/uiSlice/uiSlice'
import { SpacePermissions } from '../../share/interfaces'
import { useAppDispatch } from '../../../../../store/hooks'
import { useOrganizationId } from '../../../../../hooks/newWorld'
import { getTeamSpacePermissions } from '../hooks'
import {
  useCreateSpaceMutation,
  useSetSpaceUsersPermissionsMutation
} from '../../../../../store/features/apis/slices/space/spaceSlice'
import { Button } from '../../../../button/Button'
import { useModalEvents } from '../../../../../hooks/useModalEvents'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { isEmpty } from 'lodash'
import { PermissionType } from '@/components/modal/templates/share/interfaces'

type CreateSpaceButtonType = {
  spaceName: string
  sharingPermission: SharingControl
  organizationSharingPermission: PermissionType
  hasValidationErrors: boolean
  onClose: () => void
}

export const CreateSpaceButton = ({
  spaceName,
  sharingPermission,
  organizationSharingPermission,
  hasValidationErrors,
  onClose
}: CreateSpaceButtonType): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const { data: organizationId, isLoading: isOrganizationIdLoading } = useOrganizationId()

  const [createSpace, { isLoading }] = useCreateSpaceMutation()
  const [setUsersPermission, { isLoading: isSetUsersPermissionLoading }] = useSetSpaceUsersPermissionsMutation()

  const teamSpaceUserPermissions = getTeamSpacePermissions(organizationSharingPermission)

  const handleCreateTeamSpace = React.useCallback(
    async (
      organizationId: string,
      spaceName: string,
      sharingPermission: SharingControl,
      teamSpaceUserPermissions: SpacePermissions
    ) => {
      const payload = {
        name: spaceName,
        spaceSettings: {
          sharingControl: sharingPermission
        }
      }

      try {
        const updatePermission = !isEmpty(teamSpaceUserPermissions.permissions)
        if (updatePermission) {
          const space = await createSpace({ organizationId, payload, skipCacheInvalidation: true }).unwrap()
          await setUsersPermission({ spaceId: space.spaceId, permissions: teamSpaceUserPermissions }).unwrap()
        } else {
          await createSpace({ organizationId, payload }).unwrap()
        }

        onClose()
      } catch (e) {
        dispatch(
          addSnackbarToState({
            severity: 'error',
            message: t(`teamSpace.teamSpaceCreationError`)
          })
        )
      }
    },
    []
  )

  const isCreateSpaceButtonDisabled =
    isLoading || isOrganizationIdLoading || hasValidationErrors || isSetUsersPermissionLoading

  useModalEvents((): void => {
    handleCreateTeamSpace(organizationId, spaceName, sharingPermission, teamSpaceUserPermissions)
  }, isCreateSpaceButtonDisabled)

  return (
    <Button
      onClick={(): void => {
        handleCreateTeamSpace(organizationId, spaceName, sharingPermission, teamSpaceUserPermissions)
      }}
      color="primary"
      variant="contained"
      disabled={isCreateSpaceButtonDisabled}
    >
      {t('add')}
    </Button>
  )
}
