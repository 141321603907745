import { IsAdminSwitch } from './tableComponents/IsAdminSwitch'
import { getSortingUserQuotaBudget, useFetchUserData } from './helpers'
import { UserContextMenu } from './tableComponents/UserContextMenu'
import { AiTrainingPermissionSwitch } from './tableComponents/AiTrainingPermissionSwitch'
import { TableHeaderCell, TableRowCellProps, TableRowProps } from '../../../components/table/enhancedTable/interfaces'
import { useIsAdmin } from '../../../permissions/areas'
import { User } from '../../../components/user/User'
import { UserOrganizationStatus } from '../../../store/features/apis/slices/organization/interfaces'
import { UserV2 } from '../../../store/features/apis/slices/user/interfaces'
import { useGetOrganizationId } from '../../../store/features/apis/slices/organization/hooks'
import { useGetOrganizationUsersAiAccessQuery } from '../../../store/features/apis/slices/organization/organizationSlice'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { getFormattedQuotaBudget } from '@/helpers/coreHours'

export const useGetAdminUserManageUsersTableHeaderData = (
  isCommunityPlan: boolean,
  isAiAreaAllowedToVisit: boolean
): TableHeaderCell[] => {
  const { t } = useTranslation(['adminArea', 'solvers'])

  let tableHeaderData: TableHeaderCell[] = [
    {
      id: 'userName',
      label: t('manageUsers.tableHeader.name'),
      numeric: false,
      disablePadding: true,
      sx: { width: '25%' }
    },
    {
      id: 'status',
      label: t('manageUsers.tableHeader.admin'),
      numeric: false,
      disablePadding: true,
      sx: { width: '25%', textAlign: 'center' }
    },
    isAiAreaAllowedToVisit
      ? {
          id: 'aiTrainingPermission',
          label: t('manageUsers.tableHeader.aiTrainingPermission'),
          numeric: false,
          disablePadding: true,
          sx: { width: '25%', textAlign: 'center' }
        }
      : undefined,
    !isCommunityPlan
      ? {
          id: 'cpuCoreHours',
          label: t('manageUsers.tableHeader.cpuCoreHours'),
          numeric: false,
          disablePadding: true,
          sx: { width: '12%' }
        }
      : undefined,
    !isCommunityPlan
      ? {
          id: 'gpuCoreHours',
          label: t('manageUsers.tableHeader.gpuCoreHours'),
          numeric: false,
          disablePadding: true,
          sx: { width: '12%' }
        }
      : undefined,
    {
      id: 'actionButton',
      label: undefined,
      disablePadding: true,
      disableSorting: true,
      sx: { width: '1%' }
    }
  ].filter(Boolean)

  return tableHeaderData
}

export const useGetAdminUserManageUsersTableBodyData = (
  searchString: string,
  isCommunityPlan: boolean,
  isAiAreaAllowedToVisit: boolean
): {
  total: number
  tableData: TableRowProps[]
  isLoading: boolean
} => {
  const { handleApiHookErrors } = useErrorHandler()
  const { data: isAdmin } = useIsAdmin()
  const organizationId = useGetOrganizationId()
  const { isLoading, users, organizationUsers, budgets } = useFetchUserData()

  const {
    data: userAiAccess,
    isLoading: isLoadingOrganizationUsersAiAccess,
    isError: isErrorOrganizationUsersAiAccess,
    error: errorOrganizationUsersAiAccess
  } = useGetOrganizationUsersAiAccessQuery({ organizationId }, { skip: isNil(organizationId) })

  React.useEffect(() => {
    if (isErrorOrganizationUsersAiAccess) {
      handleApiHookErrors(errorOrganizationUsersAiAccess)
    }
  }, [isErrorOrganizationUsersAiAccess, errorOrganizationUsersAiAccess])

  const userAiAccessHashMap = React.useMemo(
    () =>
      !isNil(userAiAccess)
        ? userAiAccess?.reduce((acc, data) => {
            let { uidExt, hasAccess } = data
            return { ...acc, [uidExt]: hasAccess }
          }, {})
        : {},
    [userAiAccess]
  )

  const filteredUsers = React.useMemo(() => {
    if (isEmpty(searchString)) {
      return users
    }

    return users.filter((user) => {
      const fields = [user.email, user.firstName, user.lastName].filter(Boolean)
      return fields.some((label) => label.toLowerCase().includes(searchString.toLowerCase()))
    })
  }, [users, searchString])

  const usersData = React.useMemo(() => {
    if (isLoading || isNil(organizationUsers)) {
      return []
    }
    return filteredUsers.map((user: UserV2) => {
      const organizationUser = organizationUsers.find(({ uidExt }) => uidExt === user.uidExt)
      const userOrganizationStatus = organizationUser?.status
      const userQuotaBudgets = budgets.find(({ uidExt }) => uidExt === user.uidExt)
      const cpuFormattedUserQuotaBudget = getFormattedQuotaBudget('CPU', userQuotaBudgets.cpuSeconds)
      const GpuFormattedUserQuotaBudget = getFormattedQuotaBudget('GPU', userQuotaBudgets.gpuSeconds)
      const cpuSortingUserQuotaBudget = getSortingUserQuotaBudget(userQuotaBudgets.cpuSeconds)
      const gpuSortingUserQuotaBudget = getSortingUserQuotaBudget(userQuotaBudgets.gpuSeconds)
      const hasAiTrainingPermission = userAiAccessHashMap[user.uidExt]

      let tableRowCellsData: TableRowCellProps[] = [
        {
          id: `${user.email}-email`,
          title: user.email,
          component: (
            <User username={user.username} firstName={user.firstName} lastName={user.lastName} email={user.email} />
          ),
          sx: { width: '25%' }
        },
        {
          id: `${user.uidExt}-admin`,
          title: userOrganizationStatus === UserOrganizationStatus.ADMIN ? '1' : '0', // used by sorting
          component: <IsAdminSwitch user={user} userOrganizationStatus={userOrganizationStatus} isAdmin={isAdmin} />,
          sx: { textAlign: 'center', width: '25%' }
        },
        isAiAreaAllowedToVisit && !isNil(userAiAccessHashMap)
          ? {
              id: `${user.uidExt}-aiTrainingPermission`,
              title: hasAiTrainingPermission,
              component: (
                <AiTrainingPermissionSwitch
                  fullName={`${user.firstName} ${user.lastName}`}
                  uidExt={user.uidExt}
                  organizationId={organizationId}
                  hasPermission={hasAiTrainingPermission}
                />
              ),
              sx: { textAlign: 'center', width: '25%' }
            }
          : undefined,
        !isCommunityPlan
          ? {
              id: `${user.uidExt}-cpuCoreHours`,
              component: <>{cpuFormattedUserQuotaBudget}</>,
              value: cpuSortingUserQuotaBudget,
              sx: { width: '12%' }
            }
          : undefined,
        !isCommunityPlan
          ? {
              id: `${user.uidExt}-gpuCoreHours`,
              component: <>{GpuFormattedUserQuotaBudget}</>,
              value: gpuSortingUserQuotaBudget,
              sx: { width: '12%' }
            }
          : undefined,
        {
          id: `${user.email}-actionButton`,
          component: isAdmin && (
            <UserContextMenu
              user={user}
              userOrganizationStatus={userOrganizationStatus}
              isCommunityPlan={isCommunityPlan}
              qa-attribute="table-row-action--button"
            />
          ),
          sx: { width: '1%' }
        }
      ].filter(Boolean)

      return {
        tableRowId: `${user.uidExt}`,
        tableRowCells: tableRowCellsData
      }
    })
  }, [filteredUsers, organizationUsers, budgets, isAdmin, isCommunityPlan, userAiAccessHashMap, isLoading])

  return {
    total: isLoading || isLoadingOrganizationUsersAiAccess ? 0 : users?.length,
    tableData: usersData,
    isLoading: isLoading || isLoadingOrganizationUsersAiAccess
  }
}
