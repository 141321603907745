import { ContentFolders, Folder, PathIDs } from './interfaces'
import { encodeQueryParams } from '../../../../../utils/helpers'
import { SortingDirection, SortingModeV2 } from '../../../uiSlice/interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { apiTags } from '../../apiTags'
import { getSortingDirectionForRequest } from '../../../uiSlice/helpers'

const foldersSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getFoldersFromSpace: builder.query<
      Folder[],
      { spaceId: string; sortingModeV2?: SortingModeV2; sortingDirection?: SortingDirection }
    >({
      query: ({ spaceId, sortingModeV2 = SortingModeV2.NAME, sortingDirection = SortingDirection.ASC }) => {
        const params = encodeQueryParams({ sort: getSortingDirectionForRequest(sortingDirection) + sortingModeV2 })
        return `api/v1/spaces/${spaceId}/content/folders?${params}`
      },
      transformResponse: (response: ContentFolders) => response.items ?? [],
      providesTags: (_result, _error, { spaceId }) => [{ type: apiTags.FOLDERS_FROM_SPACE, spaceId }]
    }),
    getFoldersFromFolder: builder.query<
      Folder[],
      PathIDs & { sortingModeV2?: SortingModeV2; sortingDirection?: SortingDirection }
    >({
      query: ({ spaceId, folderId, sortingModeV2 = SortingModeV2.NAME, sortingDirection = SortingDirection.ASC }) => {
        const params = encodeQueryParams({ sort: getSortingDirectionForRequest(sortingDirection) + sortingModeV2 })
        return `api/v1/spaces/${spaceId}/folders/${folderId}/content/folders?${params}`
      },
      transformResponse: (response: ContentFolders) => response.items ?? [],
      providesTags: (_result, _error, { spaceId, folderId }) => [
        { type: apiTags.FOLDERS_FROM_FOLDER, spaceId, folderId }
      ]
    })
  })
})

export const { useGetFoldersFromFolderQuery, useGetFoldersFromSpaceQuery } = foldersSlice

export const getFoldersFromFolder = foldersSlice.endpoints.getFoldersFromFolder.initiate
