import { UserListDataType } from './interfaces'
import { sortUsers } from './helpers'
import { TeamSpaceUserListItem } from './TeamSpaceUserListItem'
import { CheckboxList, OrderBy } from '../../components/list/checkboxList/CheckboxList'
import { setCheckboxListItems } from '../../store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Button } from '../../components/button/Button'
import { PermissionType } from '../../components/modal/templates/share/interfaces'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { CircularProgress, Stack } from '@mui/material'

interface TeamSpaceMembersSelectionProps {
  searchString?: string
  disabled?: boolean
  showPermissionsDropdown?: boolean
  appendUserListItemComponent?: (user: UserListDataType) => React.ReactNode
  onConfirmCallback?: () => void
}

export const TeamSpaceMembersSelection = ({
  searchString,
  disabled,
  showPermissionsDropdown,
  appendUserListItemComponent,
  onConfirmCallback
}: TeamSpaceMembersSelectionProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const checkboxListItems = useAppSelector<UserListDataType[]>((state) => state.ui.checkboxListItems)
  const [orderBy, setOrderBy] = React.useState<OrderBy>('asc')

  const isAnyNewMemberSelected = React.useMemo(
    () => checkboxListItems.some((item) => item.checked && !item.isMember),
    [checkboxListItems]
  )

  const handleAddToTeam = React.useCallback(() => {
    onConfirmCallback?.()
    const updatedItems = checkboxListItems.map((user) => {
      if (user.checked && !user.isMember) {
        return { ...user, permission: PermissionType.VIEW, isMember: true }
      }
      return user
    })
    dispatch(setCheckboxListItems(updatedItems))
  }, [checkboxListItems])

  const handleCheckboxClick = React.useCallback(
    (id: string | number): void => {
      const updatedItems = checkboxListItems.map((listUser) => {
        if (listUser.id === id) {
          return { ...listUser, checked: !listUser.checked }
        }
        return listUser
      })
      dispatch(setCheckboxListItems(updatedItems))
    },
    [checkboxListItems]
  )

  const handleOrderByClick = React.useCallback((orderBy: OrderBy): void => {
    setOrderBy(orderBy)
  }, [])

  const filteredMembers = React.useMemo(() => {
    const sortedMembers = sortUsers(checkboxListItems, orderBy)
    if (isEmpty(searchString)) {
      return sortedMembers
    }
    if (!isEmpty(sortedMembers) && !isEmpty(searchString)) {
      return sortedMembers.filter(
        (user) =>
          user.email.toLowerCase().includes(searchString) || user.userFullName?.toLowerCase().includes(searchString)
      )
    }
  }, [searchString, checkboxListItems, orderBy])

  if (isNil(filteredMembers)) {
    return (
      <Stack alignItems="center">
        <CircularProgress size="28px" />
      </Stack>
    )
  }

  const handleCheckAllClick = React.useCallback(
    (allItemsChecked: boolean): void => {
      const updatedItems = checkboxListItems.map((listUser) => {
        if (!listUser.disabled && !listUser.isMember && filteredMembers.find(({ id }) => id === listUser.id)) {
          return { ...listUser, checked: !allItemsChecked }
        }
        return listUser
      })
      dispatch(setCheckboxListItems(updatedItems))
    },
    [checkboxListItems, filteredMembers]
  )

  if (isEmpty(filteredMembers)) {
    return null
  }

  return (
    <Stack direction="column" gap={1} padding={3}>
      <CheckboxList
        headerTitle={t('teamSpace.selectAll')}
        items={filteredMembers}
        orderBy={orderBy}
        handleCheckboxClick={handleCheckboxClick}
        handleCheckAllClick={handleCheckAllClick}
        handleOrderByClick={handleOrderByClick}
        checkboxListItemComponent={(item: UserListDataType): React.ReactElement => {
          return (
            <TeamSpaceUserListItem
              key={item.id}
              userListData={item}
              showPermissionsDropdown={showPermissionsDropdown}
              appendComponent={appendUserListItemComponent}
              disabled={disabled || item.isMember}
              isMember={item?.isMember}
              isInvited={item?.isInvited}
              handleItemClick={handleCheckboxClick}
            />
          )
        }}
        disabled={disabled}
      />
      <Stack direction="row-reverse">
        <Button
          onClick={(): void => handleAddToTeam()}
          color="primary"
          variant="contained"
          disabled={!isAnyNewMemberSelected}
        >
          {t('teamSpace.addToSpace')}
        </Button>
      </Stack>
    </Stack>
  )
}
