import { isNil } from 'lodash'
import * as React from 'react'
import Alert from '@mui/material/Alert'
import { TFunction } from 'react-i18next'
import { makeUniqueId } from '@/logging/utils'
import { isInsideCypressTests } from '@/utils/windowUtils'

const errorTextForComponentError = (error: Error): string => `React Component Crashed
Error:
${error}`

const fallbackTFn = (_: string, { traceId }: { traceId: string }): string =>
  `Something went wrong, please retry the operation later. If the issue persists, please contact support and inform: Trace ID: ${traceId}`

type ErrorBoundaryProps = React.PropsWithChildren<{
  silenceError?: boolean
  silenceReporting?: boolean /**defaults to silenceError */
  getUserFacingErrorMessage?(error: Error, traceId: string): string
  errorTextForComponentError?(error: Error): string
  ErrorMessageContainer?: React.FunctionComponent<React.PropsWithChildren<{}>>
  withoutAlertClass?: boolean
  onError?: () => void
  t?: TFunction
}>
interface ErrorBoundaryState {
  hasError: boolean
  traceId?: string
  error?: Error
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false, traceId: null, error: null }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.props.onError?.()
    if (this.props.silenceError) {
      this.setState({ hasError: true })
      if (this.props.silenceReporting ?? this.props.silenceError) {
        return
      }
    }
    if (isInsideCypressTests()) {
      throw error
    }
    const traceId = makeUniqueId()
    const message = (this.props.errorTextForComponentError ?? errorTextForComponentError)(error)
    // eslint-disable-next-line no-console
    console.error({ message, stack_trace: error.stack, traceId, debug: errorInfo.componentStack })
    this.setState({ traceId, hasError: true, error })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.props.silenceError === true) {
        return null
      }
      const errorContent =
        this.props.getUserFacingErrorMessage?.(this.state.error, this.state.traceId) ??
        (this.props.t ?? fallbackTFn)('unknownFailureContactSupport', { traceId: this.state.traceId })
      const errorElement =
        this.props.withoutAlertClass === true ? (
          errorContent
        ) : (
          <Alert icon={false} severity="warning">
            {errorContent}
          </Alert>
        )
      const { ErrorMessageContainer } = this.props
      return isNil(ErrorMessageContainer) ? errorElement : <ErrorMessageContainer>{errorElement}</ErrorMessageContainer>
    }

    return this.props.children
  }
}

export default ErrorBoundary
