import React from 'react'
import { Grid } from '@mui/material'
import { Card } from '@/components/card/Card'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import { AnalyticsBoard } from '@/store/features/apis/slices/analyticsDashboard/interfaces'

const AnalyticsAreaDisplay = ({ dashboards }: { dashboards: AnalyticsBoard[] }): React.ReactElement => {
  const { navigate } = useDashboardNavigate()

  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: 'auto',
        width: '100%',
        mx: 'auto',
        mt: 2.5,
        mb: 4
      }}
    >
      {dashboards.map((dashboard) => {
        return (
          <Grid item xs={6} sm={6} key={dashboard.id}>
            <Card
              title={dashboard.name}
              onClick={(event): void => {
                event.preventDefault()
                navigate(`analytics-area/${dashboard.id}`)
              }}
            >
              <span>{dashboard.description}</span>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default AnalyticsAreaDisplay
