import { useAppSelector } from '../../../../store/hooks'
import { usePermissionsPayload } from '../../../../hooks/usePermissionsPayload'
import { SpacePermissions } from '../share/interfaces'
import { UserListDataType } from '../../../../containers/list/interfaces'
import * as React from 'react'
import { compact, isNil } from 'lodash'
import { GroupName, PermissionType, ScopeType } from '@/components/modal/templates/share/interfaces'

export const getTeamSpacePermissions = (organizationPermission?: PermissionType): SpacePermissions => {
  const { getPermission } = usePermissionsPayload()
  const checkboxListItems = useAppSelector<UserListDataType[]>((state) => state.ui.checkboxListItems)
  const membersWithPermission = React.useMemo(
    () => checkboxListItems.filter((item) => !isNil(item.permission)),
    [checkboxListItems]
  )

  const teamSpacePermissions = React.useMemo(() => {
    const permissions =
      compact(membersWithPermission.map((item) => getPermission(item.username, item.permission))) ?? []

    if (!isNil(organizationPermission)) {
      permissions.push({
        scope: { scopeType: ScopeType.GROUP, groupName: GroupName.ORGANIZATION },
        permission: organizationPermission
      })
    }
    return permissions
  }, [checkboxListItems, organizationPermission])

  return { permissions: teamSpacePermissions }
}
