import React from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { Stack } from '@mui/system'
import { isEmpty, isNil } from 'lodash'
import { BreadcrumbsItem } from '@/components/breadcrumb/BreadcrumbsItem'
import { BreadcrumbsRow } from '@/components/breadcrumb/BreadcrumbsRow'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import {
  useGetAnalyticsDashboardsQuery,
  useGetMetabaseSignedUrlQuery
} from '@/store/features/apis/slices/analyticsDashboard/analyticsDashboardSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'

interface AnalyticsDetailProps {
  dashboardId?: string // the page can be access directly with navigation (useParams) or from the list of dashboards
}

export const AnalyticsAreaDetail = ({ dashboardId }: AnalyticsDetailProps): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const { handleNotFound } = useErrorHandler() // in case of error, we redirect to the default page

  const dashboardUuidFromParameters = useParams<{ dashboardUuid: string }>()?.dashboardUuid
  const dashboardUuid = isEmpty(dashboardId) ? dashboardUuidFromParameters : dashboardId

  const {
    data: metabaseSignedUrl,
    isLoading: isMetabaseSignedUrlLoading,
    error: metabaseSignedUrlError,
    isError: isMetabaseSignedUrlError
  } = useGetMetabaseSignedUrlQuery(dashboardUuid)

  const {
    data: dashboards,
    isLoading: isDashboardNameLoading,
    error: dashboardError,
    isError: isDashboardError
  } = useGetAnalyticsDashboardsQuery()

  const currentBoard = dashboards?.find((dashboard) => dashboard.id === dashboardUuid)

  const dashboardName = currentBoard?.name

  React.useEffect(() => {
    if (!isNil(metabaseSignedUrlError)) {
      handleNotFound()
    }
  }, [isMetabaseSignedUrlError])

  React.useEffect(() => {
    if (!isNil(dashboardError)) {
      handleNotFound()
    }
  }, [isDashboardError])

  if (isMetabaseSignedUrlLoading || isDashboardNameLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <Stack direction="column" sx={{ height: '100%', width: '100%' }}>
      <Stack direction="column" sx={{ m: 4 }}>
        <BreadcrumbsRow>
          {['Analytics Area', dashboardName]?.map((path, index) => {
            return (
              <BreadcrumbsItem
                key={path}
                label={path}
                isLastElement={index === 1}
                onElementSelect={(): void => {
                  navigate(`/analytics-area`)
                }}
              />
            )
          })}
        </BreadcrumbsRow>
      </Stack>

      <iframe
        src={metabaseSignedUrl}
        style={{ width: '100%', height: '100%', margin: 'auto', border: 'none' }}
        allowFullScreen
      />
    </Stack>
  )
}
