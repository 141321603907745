import { apiTags } from './apiTags'
import { fetchBaseQueryWrapper } from './helpers'
import { createApi } from '@reduxjs/toolkit/query/react'

export const ApplicationApi = createApi({
  baseQuery: fetchBaseQueryWrapper('/'),
  reducerPath: 'APPLICATION_API',
  tagTypes: Object.values(apiTags),
  endpoints: () => ({})
})
